import { Location } from "@angular/common";
var jsonDiff = require('json-diff');
import { NavigationEnd } from '@angular/router';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { EventEmitter, HostListener, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
// import { Angular2Csv } from "angular2-csv/Angular2-csv";
import * as pluginDataLabels from "chartjs-plugin-datalabels";
import * as esri_geo from "esri-leaflet-geocoder";
import * as L from "leaflet";
import { latLng, tileLayer } from "leaflet";
import { Ng2FileInputService } from "ng2-file-input";
import { LabelType } from "ng5-slider";
import { ImageCroppedEvent } from "ngx-image-cropper";
import notie from "notie";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import swal from "sweetalert2";
import { isNull, isUndefined } from "util";
import "../../assets/js/systemhelper.js";
import {
  OAccessUser,
  OAccessUserAccount,
  OAccessUserAccountOwner,
  OAccessUserAccountRole,
  OAccessUserAccountRolePermission,
  OAccessUserCountry,
  OAccessUserDevice,
  OAccessUserLocation,
  OAccountOverview,
  OAppUsersOverview,
  OCardTypeSalesSummary,
  OCoreCommon,
  OCoreHelper,
  OCoreParameter,
  OList,
  ORedeemOverview,
  OResponse,
  ORewardOverview,
  ORewardsSummary,
  ORewardTypeOverview,
  OSalesOverview,
  OSalesSummary,
  OStorageContent,
  OTerminalStatusCount,
  OUserCounts,
  OUserDetails,
} from "./object.service";
declare var $: any;
declare var moment: any;
declare var SystemHelper: any;
import * as cloneDeep from 'lodash/cloneDeep';
import * as lodash from 'lodash';
import "leaflet.tilelayer.colorfilter";
@Injectable({ providedIn: 'root' })
@Injectable()
export class HelperService {
  @HostListener("window:resize", ["$event"])
  public ContainerHeight: number;
  ObjectCreated: BehaviorSubject<boolean> = new BehaviorSubject(false);

  onResize(event) {
    this.ContainerHeight = window.innerHeight - 56;
  }
  defaultImage(event) {
    event.target.src = "assets/img/Dummy.png";

  }
  public ManagerBranchKey: string;
  public ManagerBranchId: number;

  //#region leafletOperations

  lefletoptions: any = {};
  defaultToDarkFilter = [
    'brightness:100%',
    'invert:100%',
  ];
  layers: any[];
  marker: any;
  map: L.Map;

  //#region LeafletGeocoder

  navchange: EventEmitter<number> = new EventEmitter();

  selectedLocation: any = {
    address: {},
  };

  geocoder: any = esri_geo.geosearch();
  revgeocoder: any = esri_geo.reverseGeocode();

  onMapReady(map: L.Map, showSearchControl: boolean) {
    this.map = map;
    if (showSearchControl) {
      this.geocoder.addTo(this.map);

      this.map.on("click", (e: any) => {
        if (!isUndefined(e) && !isNull(e)) {
          this.revgeocoder
            .latlng([e.latlng.lat, e.latlng.lng])
            .run((error, result, response) => {
              this.selectedLocation = result;
              this.navchange.emit(0);
            });
        }
      });
    } else {
      this.map.removeControl(this.geocoder);
    }

    //    this.layers.push(this.searchControl);
  }

  //#endregion

  _InitMap(): void {
    //#region markerIconInit

    const iconRetinaUrl = "assets/marker-icon-2x.png";
    const iconUrl = "assets/marker-icon.png";
    const shadowUrl = "assets/marker-shadow.png";
    const iconDefault = L.icon({
      iconRetinaUrl,
      iconUrl,
      shadowUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowSize: [41, 41],
    });
    L.Marker.prototype.options.icon = iconDefault;

    //#endregion

    //#region Marker

    try {
      this.marker = L.marker(
        L.latLng(
          this._UserAccount.Latitude.valueOf(),
          this._UserAccount.Longitude.valueOf()
        )
      );
    } catch (error) {
      this.marker = L.marker(L.latLng(6.5244, 3.3792));
    }

    //#endregion

    //#region layers

    this.layers = [
      this.marker,
      tileLayer("http://{s}.tile2.opencyclemap.org/transport/{z}/{x}/{y}.png", {
        minZoom: 7,
        maxZoom: 18,
        noWrap: true
      }),
      (L.tileLayer as any).colorFilter(
        "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          maxZoom: 18,
          attribution: "",
          filter: this.defaultToDarkFilter
        }
      )
    ];

    //#endregion

    //#region Options

    this.lefletoptions.zoom = 18;
    try {
      this.lefletoptions.center = latLng(
        this._UserAccount.Latitude.valueOf(),
        this._UserAccount.Longitude.valueOf()
      );
    } catch (error) {
      this.lefletoptions.center = latLng(6.5244, 3.3792);
    }

    //#endregion
  }

  _ReLocate(): void {
    try {
      this.marker.setLatLng([
        this._UserAccount.Latitude,
        this._UserAccount.Longitude,
      ]);
      this.map.panTo(
        new L.LatLng(this._UserAccount.Latitude, this._UserAccount.Longitude)
      );
    } catch (error) {
      this.marker.setLatLng([6.5244, 3.3792]);
      this.map.panTo(new L.LatLng(6.5244, 3.3792));
    }
    this._MapCorrection();
  }

  _MapCorrection(): void {
    setTimeout(() => {
      this.map.invalidateSize(false);
    }, 200);
  }

  //#endregion
  // 
  public UserCountrycode: any;
  public UserCountrykey: any;
  public CountryCode: any;
  public UserCountryId: any;
  // 

  public terminalsavecount: number = 0;
  public FullContainer = false;
  public ShowAsidePanel = true;
  public RandomNumber = null;
  public RandomPassword = null;
  public IsFormProcessing = false;
  public ToggleField = false;
  public AccessPin: string;
  public AccessPassword: string;
  public AccessKey: string;
  public PublicKey: string;
  public LoginTime: string;
  public User: OAccessUser;
  public UserAccount: OAccessUserAccount;
  public UserOwner: OAccessUserAccountOwner;
  public UserCountry: OAccessUserCountry;
  public UserLocation: OAccessUserLocation;
  public UserDevice: OAccessUserDevice;
  public UserRoles: Array<OAccessUserAccountRole>;
  public UserRolePermissions: OAccessUserAccountRolePermission[] = []; //Array<OUserAccountRolePermission>;
  public Select2Options_Multiple: Select2Options;
  public Select2Options_Single: Select2Options;
  public S2_Sort_Option: Select2Options;
  public Center: any = "center";
  public Select2Options_SearchColumn: Select2Options;
  public StatusSuccess = "Success";
  public StatusError = "Error";


  public AppConfig = {

    //changes after

    TerminalTypes: {
      all: 'all',
      active: 'active',
      idle: 'idle',
      dead: 'dead',
      unused: 'unused'
    },



    PayStackKey: "pk_live_4acd36db0e852af843e16e83e59e7dc0f89efe12",
    TabId: 0,
    ShowMenu: true,
    DefaultStartTimeAll: new Date(2017, 0, 1, 0, 0, 0, 0),
    DefaultEndTimeToday: moment().endOf("day"),
    DefaultStartTime: moment().startOf("day"),
    DefaultEndTime: moment().endOf("day"),
    CurrencySymbol: "&#8358;",
    CurrencySymbolCustom: "",
    RangeInvoiceAmountMinimumLimit: 0,
    RangeInvoiceAmountMaximumLimit: 100000,
    RangeInvoiceAmountOptions: {
      floor: 0,
      ceil: 100000,
      draggableRange: true,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return (
              "min purchase:" + this.AppConfig.CurrencySymbol + " " + value
            );
          case LabelType.High:
            return (
              "max purchase:</b>" + this.AppConfig.CurrencySymbol + " " + value
            );
          default:
            return this.AppConfig.CurrencySymbol + " " + value;
        }
      },
    },
    RangeRewardAmountMinimumLimit: 0,
    RangeRewardAmountMaximumLimit: 100000,
    RangeRewardAmountOptions: {
      floor: 0,
      ceil: 100000,
      draggableRange: true,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return "min reward:" + this.AppConfig.CurrencySymbol + " " + value;
          case LabelType.High:
            return (
              "max reward:</b>" + this.AppConfig.CurrencySymbol + " " + value
            );
          default:
            return this.AppConfig.CurrencySymbol + " " + value;
        }
      },
    },
    RangeRedeemAmountMinimumLimit: 0,
    RangeRedeemAmountMaximumLimit: 100000,
    RangeRedeemAmountOptions: {
      floor: 0,
      ceil: 100000,
      draggableRange: true,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return "min redeem:" + this.AppConfig.CurrencySymbol + " " + value;
          case LabelType.High:
            return (
              "max redeem:</b>" + this.AppConfig.CurrencySymbol + " " + value
            );
          default:
            return this.AppConfig.CurrencySymbol + " " + value;
        }
      },
    },
    RangeRewardClaimAmountMinimumLimit: 0,
    RangeRewardClaimAmountMaximumLimit: 100000,
    RangeRewardClaimAmountOptions: {
      floor: 0,
      ceil: 100000,
      draggableRange: true,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return "min claim:" + this.AppConfig.CurrencySymbol + " " + value;
          case LabelType.High:
            return (
              "max claim:</b>" + this.AppConfig.CurrencySymbol + " " + value
            );
          default:
            return this.AppConfig.CurrencySymbol + " " + value;
        }
      },
    },

    ActiveOwnerId: null,
    ActiveOwnerAccountCode: null,
    ActiveOwnerAccountTypeCode: null,
    ActiveOwnerAccountTypeId: null,
    ActiveOwnerKey: null,
    ActiveOwnerDisplayName: null,
    ActiveOwnerIsTucPlusEnabled: null,

    ActiveReferenceAccountTypeCode: null,
    ActiveReferenceAccountTypeId: null,
    ActiveReferenceId: null,
    ActiveReferenceKey: null,
    ActiveReferenceListType: null,
    ActiveReferenceDisplayName: null,




    RewardPercentagePermission: null,
    TerminalPermission: null,
    SubAccountPermission: null,
    StorePermission: null,
    CashiersPermission: null,
    salesanalytics: null,
    Vas: null,
    Invoice: null,
    Loyality: null,

    ActiveOwnerRoleKey: null,

    ActiveAccountKey: null,

    ActiveAccountId: null,
    ActiveMerchantReferenceId: null,
    ActiveMerchantReferenceKey: null,
    ActiveSubAccountReferenceId: null,
    ActiveSubAccountReferenceKey: null,
    ActiveTerminalReferenceId: null,
    ActiveTerminalReferenceKey: null,
    ActiveStoreReferenceId: null,
    ActiveStoreReferenceKey: null,

    ActiveCashierReferenceId: null,
    ActiveCashierReferenceKey: null,

    ActiveBranchReferenceId: null,
    ActiveBranchReferenceKey: null,
    ActiveCustomerReferenceId: null,
    ActiveCustomerReferenceKey: null,
    ActiveManagerReferenceId: null,
    ActiveManagerReferenceKey: null,

    ActiveCampaignReferenceKey: null,
    ActiveCampaignReferenceId: null,










    FlagStatusCode: {
      Green: "accountflag.green",
      Orange: "accountflag.orange",
      Red: "accountflag.red",
      Yellow: "accountflag.yellow",
      Black: "accountflag.black",
      Purple: "accountflag.purple",
    },
    ActiveSubReferenceKey: null,
    ActivePasswordResetRequest: null,
    ShowHeader: true,
    ActivePageName: "",
    Client: null,
    ClientHeader: {
      "Content-Type": "application/json; charset=utf-8",
      hcak: null,
      hcavk: null,
      hctk: null,
      hcudlt: null,
      hcudln: null,
      hcuak: null,
      hcupk: null,
      hcudk: null,
      hcuata: "",
      hcuatp: "",
    },
    Host: "",
    HostConnect: "",
    DefaultPassword: "Welcome@ThankU",
    WebsiteUrl: "",
    AppName: "",
    AppVersion: "",
    TimeZone: "Africa/Lagos",
    TimeFormat: "h:mm a",
    DateDayFormat: "DD-MM",
    DateMonthFormat: "MM-YY",
    DateYearFormat: "YYYY",
    DateFormat: "DD-MM-YYYY",
    DateTimeFormat: "DD-MM-YYYY HH:mm",
    DateTimeFormatForChart: "YYYY-MM-DD HH:mm:ss",
    DateTimeFormatForChartX: "DD MMM",
    //DateTimeFormat: "DD MMM YYYY h:mm a",
    //DateTimeFormatShort: "DD-MM-YYYY h:mm a",
    AppKey: "33f51da41c95415fba82ff8a9f4981a5",
    AppVersionKey: "18da648a59024f2ba93e2e07319b1764",
    DropDownListLimit: 7,
    ListRecordLimit: [10, 25, 30, 50, 90, 100],
    Center: "center",
    Alert_Position: this.Center,
    Alert_Animation: "",
    Alert_AllowAnimation: false,
    Alert_AllowOutsideClick: false,
    Alert_AllowEscapeKey: false,
    Color_Green: "#22b66e",
    Color_Red: "#f14d4d",
    Color_Grey: "#d1dade",
    Color_Blue: "#0168FA",
    TablesConfig: {
      DefaultClass: "table  table-hover mb-0",
      InfoClass: "fa fa-info-circle text-warn",
    },
    HostDomain: {
      App: "app.thankucash.com",
      Console: "console.thankucash.com",
      Merchant: "merchant.thankucash.com",
      Store: "store.thankucash.com",
      Cashier: "cashier.thankucash.com",
    },
    Icon_Cropper_Options: {
      MaintainAspectRatio: "true",
      AspectRatio: "1 / 1",
      MinimumWidth: 128,
      MinimumHeight: 128,
      ResizeToWidth: 128,
      Format: "png",
    },
    Poster_Cropper_Options: {
      MaintainAspectRatio: "true",
      AspectRatio: "19 / 9",
      MinimumWidth: 800,
      MinimumHeight: 450,
      ResizeToWidth: 800,
      Format: "png",
    },





    // DateRangeOptionsCustom: {

    //   locale: { format: "DD-MM-YYYY" },
    //   // scrollX:false,
    //   // scrollY:false,
    //   // scrollbars:false,
    //   alwaysShowCalendars: false,
    //   showCustomRangeLabel: false,
    //   startDate: moment().startOf("day"),
    //   endDate: moment().endOf("day"),
    //   dateLimit: {
    //     month: 1,
    //   },
    //   // minDate: moment().subtract(30, "days"),
    //   // maxDate: moment().add(30, "days"),
    //   // startDate: moment().startOf("month"),
    //   // endDate: moment().endOf("month"),
    //   ranges: {
    //     Today: [moment(), moment()],
    //     Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    //     "This Week": [moment().startOf("week"), moment().endOf("week")],
    //     "Last Week": [
    //       moment().subtract(1, "weeks").startOf("isoWeek"),
    //       moment().subtract(1, "weeks").endOf("isoWeek"),
    //     ],
    //     "This Month": [moment().startOf("month"), moment().endOf("month")],
    //     "Last Month": [
    //       moment().startOf("month").subtract(1, "month"),
    //       moment().startOf("month").subtract(1, "days"),
    //     ],
    //     "This Year": [
    //       moment().startOf("year").startOf("day"),
    //       moment().endOf("year").endOf("day"),
    //     ],
    //     "Last Year": [
    //       moment().subtract(1, "year").startOf("year").startOf("day"),
    //       moment().subtract(1, "year").endOf("year").endOf("day"),
    //     ],
    //     // All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
    //   },
    // },


    DateRangeOptionsNoAll: {
      showCustomRangeLabel: false,
      locale: {
        format: "DD-MM-YYYY",
        scrollInput: false
      },
      alwaysShowCalendars: false,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      autoApply: true,
      dateLimit: {
        month: 1,
      },
      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week"), moment().endOf("week")],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ]
      },
    },


    DateRangeOptionsCustom: {

      locale: {
        format: "DD-MM-YYYY",
        // scrollInput: false,
        // appendToBody: false,
        //  scrollMonth : false
        //  autoApply:true,
      },

      alwaysShowCalendars: false,
      showCustomRangeLabel: false,
      autoApply: true,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      // scrollMonth:true,
      // scrollTime:true,

      //showButtonPanel: false,

      dateLimit: {
        month: 1,
      },
      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week").add("day", 1), moment().endOf("week").add("day", 1)],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        "This Year": [
          moment().startOf("year").startOf("day"),
          moment().endOf("year").endOf("day"),
        ],
        "Last Year": [
          moment().subtract(1, "year").startOf("year").startOf("day"),
          moment().subtract(1, "year").endOf("year").endOf("day"),
        ],
        // All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },



    GrowthRevenueDateRangeOptionsCustom: {
      locale: { format: "DD-MM-YYYY" },
      alwaysShowCalendars: false,
      showCustomRangeLabel: false,
      startDate: moment().subtract(6, "days").startOf('day'),
      endDate: moment().endOf('day'),
      dateLimit: {
        month: 1,
      },
      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        "Last 6 Days": [moment().subtract(6, "days").startOf('day'), moment().endOf('day')],
        // Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week"), moment().endOf("week")],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        "This Year": [
          moment().startOf("year").startOf("day"),
          moment().endOf("year").endOf("day"),
        ],
        "Last Year": [
          moment().subtract(1, "year").startOf("year").startOf("day"),
          moment().subtract(1, "year").endOf("year").endOf("day"),
        ],
        // All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },




    DateRangeOptionsMonth: {
      locale: { format: "DD-MM-YYYY" },
      alwaysShowCalendars: false,
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),
      dateLimit: {
        month: 1,
      },
      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week"), moment().endOf("week")],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        // All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()]
      },
    },

    DateRangeOptions: {
      locale: {
        format: "DD-MM-YYYY",
        dayOfWeekStart: 0,
      },
      alwaysShowCalendars: false,
      startDate: this.DateInUTC(moment().subtract(29, "days")),
      endDate: this.DateInUTC(moment().endOf("day")),
      dateLimit: {
        month: 1,
      },

      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This Week": [moment().startOf("week").add("day", 1), moment().endOf("week").add("day", 1)],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("Week").add("day", 1),
          moment().subtract(1, "weeks").endOf("Week").add("day", 1),
        ],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().startOf("month").subtract(1, "month"),
          moment().startOf("month").subtract(1, "days"),
        ],
        All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },
    // DateRangeOptionsCustom: {
    //   locale: { format: "DD-MM-YYYY" },
    //   alwaysShowCalendars: false,
    //   startDate: moment().startOf("day"),
    //   endDate: moment().endOf("day"),
    //   showCustomRangeLabel: false,

    //   dateLimit: {
    //     month: 1,
    //   },
    //   // minDate: moment().subtract(30, "days"),
    //   // maxDate: moment().add(30, "days"),
    //   // startDate: moment().startOf("month"),
    //   // endDate: moment().endOf("month"),
    //   ranges: {
    //     Today: [moment(), moment()],
    //     Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    //     "This Week": [moment().startOf("week"), moment().endOf("week")],
    //     "Last Week": [
    //       moment().subtract(1, "weeks").startOf("isoWeek"),
    //       moment().subtract(1, "weeks").endOf("isoWeek"),
    //     ],
    //     "Last 7 Days": [moment().subtract(6, "days"), moment()],
    //     "Last 15 Days": [moment().subtract(14, "days"), moment()],
    //     "Last 30 Days": [moment().subtract(29, "days"), moment()],
    //     "This Month": [moment().startOf("month"), moment().endOf("month")],
    //     "Last Month": [
    //       moment().startOf("month").subtract(1, "month"),
    //       moment().startOf("month").subtract(1, "days"),
    //     ],
    //     // All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
    //   },
    // },
    DateRangeMonthOptions: {
      locale: { format: "YYYY-MM-DD" },
      alwaysShowCalendars: false,
      startDate: moment().startOf("month").subtract(6, "month"),
      endDate: moment().endOf("month"),
      dateLimit: {
        month: 12,
      },
      // minDate: moment().subtract(30, "days"),
      // maxDate: moment().add(30, "days"),
      // startDate: moment().startOf("month"),
      // endDate: moment().endOf("month"),
      ranges: {
        "This Week": [moment().startOf("week"), moment().endOf("week")],
        "Last Week": [
          moment().subtract(1, "weeks").startOf("isoWeek"),
          moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        // "Last 2 Weeks": [moment().subtract(14, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "Last 3 Months": [
          moment().startOf("month").subtract(3, "month"),
          moment().endOf("month"),
        ],
        "Last 6 Months": [
          moment().startOf("month").subtract(6, "month"),
          moment().endOf("month"),
        ],
        "This Year": [moment().startOf("year"), moment().endOf("year")],
        "Last Year": [
          moment().startOf("year").subtract(1, "year"),
          moment().endOf("year").subtract(1, "year"),
        ],
        "Last 3 Year": [
          moment().startOf("year").subtract(3, "year"),
          moment().endOf("year"),
        ],
        "Last 6 Year": [
          moment().startOf("year").subtract(6, "year"),
          moment().endOf("year"),
        ],
        All: [new Date(2017, 0, 1, 0, 0, 0, 0), moment()],
      },
    },
    DateRangeFutureOptions: {
      locale: { format: "DD-MM-YYYY" },
      alwaysShowCalendars: false,
      autoUpdateInput: false,
      startDate: moment(),
      endDate: moment().endOf("month"),
      minDate: moment(),
      ranges: {
        "This Week": [moment().startOf("week"), moment().endOf("week")],
        "Next Week": [
          moment().add(1, "weeks").startOf("isoWeek"),
          moment().add(1, "weeks").endOf("isoWeek"),
        ],
        "Next 15 Days": [moment(), moment().add(14, "days")],
        "Next 30 Days": [moment(), moment().add(29, "days")],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Next Month": [
          moment().endOf("month").add(1, "days"),
          moment().startOf("month").add(1, "month"),
        ],
      },
    },
    DatePickerOptions: {
      singleDatePicker: true,
      locale: { format: "DD-MM-YYYY" },
      alwaysShowCalendars: false,
      showDropdowns: true,
      startDate: moment().startOf("day").subtract(1, "days"),
      endDate: moment().startOf("day").subtract(1, "days"),
      minYear: 1901,
      maxYear: parseInt(moment().format("YYYY"), 10),
      maxDate: moment().subtract(1, "days"),
    },
    DatePickerOptionsForToday: {
      singleDatePicker: true,
      locale: { format: "DD-MM-YYYY" },
      alwaysShowCalendars: false,
      showDropdowns: true,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      minYear: 1901,
      maxYear: parseInt(moment().format("YYYY"), 10),
      maxDate: moment(),
    },
    ListToggleOption: {
      SortApply: "sortapply",
      SortOrder: "sortorder",
      CancelFilter: "cancelfilter",
      ApplyFilter: "applyfilter",
      SortReset: "sortreset",
      ToggleFilter: "togglefilter",
      Visiblity: "visiblity",
      Limit: "limit",
      Refresh: "refresh",
      ResetOffset: "resetoffset",
      Reset: "reset",
      Sort: "sort",
      Status: "status",
      Page: "page",
      Search: "search",
      Date: "date",
      Csv: "csv",
      SearchColumnChange: "searchcolumnchange",
      ColumnSearch: "columnsearch",
      MultiSelect: "multiselect",
      DateRange: "daterange",
      Other: "other",
      SalesRange: 'salesrange',
      DateExpiry: "dateexpiry",
    },
    FilterTypeOption: {
      RequestHistory: 'RequestHistory',

      Usage: 'adminUsage',
      Version: 'adminVersion',
      Merchant: "bankMerchant",
      Terminal: "bankTerminal",
      Stores: "bankStores",
      Branch: "bankBranch",
      Manager: "bankManager",
      Campaign: "bankCampaign",
      MerchantSales: "bankMerchantSales",
      FaqCategory: 'faqCategory',
      Faq: 'faq'
    },
    OtherFilters: {
      SubAdmin: {
        Role: "role"
      },
      Merchant: {
        Owner: "owner"
      },
      Terminal: {
        Merchant: "merchant",
        Store: "store",
        Provider: "provider",
        Bank: 'bank'
      },
      Stores: {
        Owner: "owner"
      },
      Branch: {

      },
      Manager: {
        Branch: "branch",
        Owner: "owner"
      },
      Campaign: {

      },

      MerchantSales: {
        Merchant: "merchant",
        Store: "store",
        CardBank: "cardbank",
        TransactionType: "transactiontype",
        CardBrand: "cardbrand",
        Provider: "provider",
        Cashier: "cashier",
        Terminal: "terminal",
        Bank: "bank"
      }
    },
    ValidatorRegex: {
      Password:
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      WebsiteUrl: "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?",
    },
    Storage: {
      OReqH: "hcscoreqh",
      Device: "hcscd",
      Location: "hcsl",
      Account: "hca",
      RegInfo: "reginfo",
      Permissions: "hcap",
      ImageStorage: "hcimgstorage",
      ImageStoragePoster: "hcimgstorageposter",
      ActiveAppUser: "hcactiveappuser",
      ActiveMerchant: "hcactivemerchant",
      ActiveCampaign: "hcactivecampaign",
      ActiveStore: "hcactivestore",
      ActiveBranch: "hcactivebranch",
      ActiveManager: "hcactivemanager",
      ActiveTerminal: "hcactiveterminal",
      ActivePgAccount: "hcactivepgaccount",
      ActivePosAccount: "hcactiveposaccount",
      ActiveAcquirer: "hcactiveacquirer",
      ActiveCustomer: "hcactivecustomer",
    },
    HelperTypes: {
      Os: "hcore.os",
      Gender: "hcore.gender",
      Configuration: "hcore.configuration",
      ConfigurationValue: "hcore.configurationvalue",
      ResponseCode: "hcore.responsecodes",
      AccountType: "hcore.accounttype",
      AccountOperationType: "hcore.accountoperationtype",
      RegistrationSource: "hcore.regsource",
      App: "hcore.app",
      AppVersion: "hcore.appversion",
      Api: "hcore.api",
      Feature: "hcore.feature",
      FeatureOption: "hcore.featureoption",
      FeatureApi: "hcore.featureapi",
      Role: "hcore.role",
      RoleFeature: "hcore.rolefeatures",
      RoleAccess: "hcore.roleaccess",
      TransactionType: "hcore.transactiontype",
      TransactionTypeReward: "reward",
      TransactionTypeRedeem: "redeem",
      CardBrand: "cardbrand",
      CardSubBrand: "cardsubbrand",
      CardType: "cardtype",
      CardBank: "cardbank",
      MerchantCategories: "merchantcategories",
      SliderImage: "sliderimage",
      InvoiceType: "hcore.invoicetype",
    },
    DataType: {
      Text: "text",
      Number: "number",
      Date: "date",
      Decimal: "decimal",
    },
    SystemDataType: {
      Text: "datatype.text",
      Number: "datatype.number",
      Date: "datatype.date",
      Boolean: "datatype.boolean",
    },
    Gender: {
      Male: "gender.male",
      Female: "gender.female",
    },
    AccountBookmark: {
      NonBookmarkId: 407,
      NonBookmark: "accountlevel.nonbookmark",
      BookmarkId: 406,
      Bookmark: "accountlevel.bookmark",
    },
    AccountFlag: {
      GreenId: 400,
      Green: "accountflag.green",
      OrangeId: 401,
      Orange: "accountflag.orange",
      RedId: 402,
      Red: "accountflag.red",
      YellowId: 403,
      Yellow: "accountflag.yellow",
      BlackId: 404,
      Black: "accountflag.black",
      PurpleId: 405,
      Purple: "accountflag.purple",
    },
    AccountType: {
      Controller: "controller",
      ControllerId: 106,
      Admin: "admin",
      AppUser: "appuser",
      AppUserId: 109,
      CardUser: "carduser",
      Merchant: "thankumerchant",
      MerchantId: 108,
      Store: "merchantstore",
      StoreId: 111,
      Manager: "manager",
      Branch: "branch",
      Campaign: "campaign",
      StoreSubAccount: "merchantstoresubaccount",
      Cashier: "merchantcashier",
      PGAccount: "pgaccount",
      PosAccount: "posaccount",
      PosSubAccount: "possubaccount",
      PosTerminal: "posterminal",
      PosTerminalId: 115,
      Acquirer: "acquirer",
      AcquirerId: 116,
      AcquirerSubAccount: "acquirersubaccount",
      MerchantSubAccount: "merchantsubaccount",
      RelationshipManager: "relationshipmanager",
      Partner: "partner",
      Customer: "customer",

    },
    ListType: {
      All: "all",
      Owner: "owner",
      Branch: "branch",
      SubOwner: "subowner",
      Bank: "bank",
      CreatedBy: "createdby",
      CreatedByOwner: "createdbyowner",
      RM: "rm",
      Manager: "manager",
      Controller: "controller",
      Admin: "admin",
      AppUser: "appuser",
      Merchant: "thankumerchant",
      Store: "merchantstore",
      StoreSubAccount: "merchantstoresubaccount",
      Cashier: "merchantcashier",
      Provider: "provider",
      PosSubAccount: "possubaccount",
      PosTerminal: "posterminal",
      Acquirer: "acquirer",
      AcquirerSubAccount: "acquirersubaccount",
      MerchantSubAccount: "merchantsubaccount",
      RelationshipManager: "relationshipmanager",
    },
    AccountOperationType: {
      Online: "accountoperationtype.online",
      Offline: "accountoperationtype.offline",
      OnlineAndOffline: "accountoperationtype.onlineandoffline",
    },
    RegistrationSource: {
      System: "regsource.system",
    },
    // Status: {
    //   Active: "default.active",
    //   Inactive: "default.inactive",
    //   Suspended: "default.suspended",
    //   Blocked: "default.blocked",
    //   Transaction: {
    //     Success: "transaction.success",
    //   },
    //   Campaign: {
    //     Creating: "campaign.creating",
    //     UnderReview: "campaign.underreview",
    //     Approved: "campaign.approved",
    //     Rejected: "campaign.rejected",
    //     Published: "campaign.published",
    //     Paused: "campaign.paused",
    //     LowBalance: "campaign.lowbalance",
    //     Expired: "campaign.expired",
    //     Archived: "campaign.archived",
    //   },
    //   WorkHorse: {
    //     New: "workhorsestatus.new",
    //     Acknowledge: "workhorsestatus.acknowledge",
    //     Rejected: "workhorsestatus.rejected",
    //     Approved: "workhorsestatus.approved",
    //     Inprogress: "workhorsestatus.inprogress",
    //     Finalizing: "workhorsestatus.finalizing",
    //     BetaTesting: "workhorsestatus.betatesting",
    //     ReadyToRelease: "workhosestatus.readytorelease",
    //     Released: "workhorsestatus.released",
    //     Cancelled: "workhorsestatus.cancelled",
    //     Duplicate: "workhorsestatus.duplicate",
    //   },
    // },
    Status: {
      Active: "default.active",
      Inactive: "default.inactive",
      Suspended: "default.suspended",
      Blocked: "default.blocked",
      Transaction: {
        Success: "transaction.success",
      },
      Campaign: {
        Creating: "campaign.creating",
        UnderReview: "campaign.underreview",
        Approved: "campaign.approved",
        Rejected: "campaign.rejected",
        Published: "campaign.published",
        Paused: "campaign.paused",
        LowBalance: "campaign.lowbalance",
        Expired: "campaign.expired",
        Archived: "campaign.archived",
      },
      WorkHorse: {
        New: "workhorsestatus.new",
        Acknowledge: "workhorsestatus.acknowledge",
        Rejected: "workhorsestatus.rejected",
        Approved: "workhorsestatus.approved",
        Inprogress: "workhorsestatus.inprogress",
        Finalizing: "workhorsestatus.finalizing",
        BetaTesting: "workhorsestatus.betatesting",
        ReadyToRelease: "workhosestatus.readytorelease",
        Released: "workhorsestatus.released",
        Cancelled: "workhorsestatus.cancelled",
        Duplicate: "workhorsestatus.duplicate",
      },
    },

    WorkHorseType: {
      SystemUpdates: "workhorsetype.systemupdates",
      NewFeatureRequest: "workhorsetype.newfeaturerequest",
      UpdateFeatureRequest: "workhorsetype.updatefeaturerequest",
      SystemErrorRequest: "workhorsetype.systemerrorrequest",
      FeatureDocumentation: "workhorsetype.documentation.feature",
      ComponentDocument: "workhorsetype.documentation.component",
    },
    WorkHorseSubType: {
      workhorseitem: "workhorseitem",
      workhorsesubitem: "workhorsesubitem",
      workhorseactivity: "workhorseactivity",
    },
    WorkHorsePriority: {
      Low: "workhorsepriority.low",
      Medium: "workhorsepriority.medium",
      High: "workhorsepriority.high",
      RedAlert: "workhorseprioriy.redalert",
    },
    TransactionMode: {
      Credit: "transaction.mode.credit",
      Debit: "transaction.mode.debit",
    },
    TransactionSource: {
      Merchant: "transaction.source.merchant",
      Settlement: "transaction.source.settlement",
      GiftCard: "transaction.source.giftcards",
    },
    StatusList: {
      defaultitem: 0,
      default: [
        {
          id: 0,
          text: "All Status",
          code: " ",
        },
        {
          id: 1,
          text: "Inactive",
          code: "default.inactive",
        },
        {
          id: 2,
          text: "Active",
          code: "default.active",
        },
        {
          id: 3,
          text: "Blocked",
          code: "default.blocked",
        },
        {
          id: 4,
          text: "Suspended",
          code: "default.suspended",
        },
      ],
      campaign: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 293,
          text: "Creating",
          code: "campaign.creating",
        },
        {
          id: 297,
          text: "Published",
          code: "campaign.published",
        },
        {
          id: 297,
          text: "Paused",
          code: "campaign.Paused",
        },
        {
          id: 297,
          text: "Approved",
          code: "campaign.Approved",
        },
        {
          id: 297,
          text: "Expired",
          code: "campaign.Expired",
        },
        // {Creating: "campaign.creating",}
        // UnderReview: "campaign.underreview",
        // Approved: "campaign.approved",
        // Rejected: "campaign.rejected",
        // Published: "campaign.published",
        // Paused: "campaign.paused",
        // LowBalance: "campaign.lowbalance",
        // Expired: "campaign.expired",
        // Archived: "campaign.archived",

      ],
      Bulkcustomer: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 1,
          text: "Pending",
          code: "1",
        },
        {
          id: 2,
          text: "Processing",
          code: "2",
        },
        {
          id: 3,
          text: "Success",
          code: "3",
        },
        {
          id: 4,
          text: "Error",
          code: "4",
        },
      ],

      Bulkmerchant: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 1,
          text: "Pending",
          code: "1",
        },
        {
          id: 2,
          text: "Processing",
          code: "2",
        },
        {
          id: 3,
          text: "Success",
          code: "3",
        },
        {
          id: 4,
          text: "Error",
          code: "4",
        },
      ],
      Bulk: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 1,
          text: "Pending",
          code: "pending",
        },
        {
          id: 2,
          text: "Success",
          code: "Success",
        },
        {
          id: 4,
          text: "Error",
          code: "Error",
        },
      ],
      BulkFiles: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 1,
          text: "Pending",
        },
        {
          id: 2,
          text: "Processing",
        },
        {
          id: 3,
          text: "Complete",
        },
        {
          id: 4,
          text: "Error",
        },
      ],
      transactiondefaultitem: 28,
      DefaultBulk: 2,
      transaction: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 27,
          text: "Processing",
          code: "transaction.processing",
        },
        {
          id: 28,
          text: "Success",
          code: "transaction.success",
        },
        {
          id: 29,
          text: "Pending",
          code: "transaction.pending",
        },
        {
          id: 30,
          text: "Failed",
          code: "transaction.failed",
        },
        {
          id: 31,
          text: "Cancelled",
          code: "transaction.cancelled",
        },
        {
          id: 32,
          text: "Error",
          code: "transaction.error",
        },
      ],
      invoice: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 38,
          text: "Pending",
          code: "invoice.pending",
        },
        {
          id: 39,
          text: "Paid",
          code: "invoice.paid",
        },
        {
          id: 40,
          text: "Cancelled",
          code: "invoice.cancelled",
        },
      ],
      workhorsestatus: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 415,
          text: "New",
          code: "workhorsestatus.new",
        },
        {
          id: 416,
          text: "Acknowledged",
          code: "workhorsestatus.acknowledged",
        },
        {
          id: 417,
          text: "Rejected",
          code: "workhorsestatus.rejected",
        },
        {
          id: 418,
          text: "Approved",
          code: "workhorsestatus.approved",
        },
        {
          id: 419,
          text: "In progress",
          code: "workhorsestatus.inprogress",
        },
        {
          id: 420,
          text: "Finalizing",
          code: "workhorsestatus.finalizing",
        },
        {
          id: 421,
          text: "Beta Testing",
          code: "workhorsestatus.betatesting",
        },
        {
          id: 422,
          text: "Ready To Release",
          code: "workhorsestatus.readytorelease",
        },
        {
          id: 423,
          text: "Released",
          code: "workhorsestatus.released",
        },
        {
          id: 424,
          text: "Cancelled",
          code: "workhorsestatus.cancelled",
        },
        {
          id: 437,
          text: "Duplicate",
          code: "workhorsestatus.duplicate",
        },
      ],
      productcodestatus: [
        {
          id: 0,
          text: "All Status",
          code: "",
        },
        {
          id: 461,
          text: "Unused",
          code: "productcodestatus.unused",
        },
        {
          id: 463,
          text: "Used",
          code: "productcodestatus.used",
        },
        {
          id: 462,
          text: "Blocked",
          code: "productcodestatus.blocked",
        },
        {
          id: 464,
          text: "Expired",
          code: "productcodestatus.expired",
        },
      ],
    },
    NetworkLocation: {
      V1: {
        System: "api/v1/system/",
        ThankU: "api/v1/thanku/",
        acquirer: "api/v1/acquirer/",
      },
      V2: {
        System: "api/v2/system/",
        ThankU: "api/v2/thankucash/",
        TUCTransCore: "api/v2/tuctranscore/",
        TUCAccCore: "api/v2/tucacccore/",
        TUCRmManager: "api/v2/rmmanager/",
        ThankUCashLead: "api/v2/tucashlead/",
        TUCCampaign: "api/v2/tuccampaign/",
        TUCAnalytics: "api/v2/tucanalytics/",
        TUCGiftPoints: "api/v2/tucgiftpoints/",
        CAProduct: "api/v2/caproduct/",
        HCWorkHorse: "api/v2/workhorse/",
      },
      V3: {
        AccountCon: "api/v3/con/accounts/",
        Account: "api/v3/partner/accounts/",
        MAccount: "api/v3/merchant/accounts/",
        CAccount: "api/v3/con/accounts/",
        Branch: "api/v3/partner/branch/",
        SubAccounts: "api/v3/partner/subaccounts/",
        Analytics: "api/v3/partner/analytics/",
        OnBoard: "api/v3/partner/onboarding/",
        SystemMange: "api/v3/system/manage/",
        Subscription: "api/v3/partner/subscription/",

        Cop: "api/v3/tuc/cop/",
        Accounts: {
          Manage: "api/v3/acc/manage/"
        }
      },

      Acquirer: {
        V3: {
          Account: "api/v3/acquirer/accounts/",
          Branch: "api/v3/acquirer/branch/",
          SubAccounts: "api/v3/acquirer/subaccounts/",
          Analytics: "api/v3/acquirer/analytics/",
          OnBoard: "api/v3/acquirer/onboarding/",
          SystemMange: "api/v3/system/manage/",
          Accounts: {
            Manage: "api/v3/acc/manage/"
          }
        },
      },

      Partner: {
        V3: {
          // dev_kishor start
          Country: "api/v3/tuc/country/",
          State: "api/v3/tuc/state/",
          City: "api/v3/tuc/city/",
          // dev_kishor end
        }
      }
    },

    Api: {
      Login: "login",
      Logout: "logout",
      ThankUCash: {
        Analytics: {
          getaccountrewardsummary: "getaccountrewardsummary",
          getaccountrewardhistory: "getaccountrewardhistory",
          getaccountsaleshistory: "getaccountsaleshistory",
          getaccountsalessummary: "getaccountsalessummary",
        },
        GetTopupHistory: "gettopuphistory",

        getcustomers: "getcustomers",
        OnBoardCustomer: "onboardcustomer",
        GetAppUserVisits: "getappuservisits",
        GetAppUsers: "getappusers",
        GetAppUsersLite: "getappuserslite",
        GetMerchantAppUsers: "getmerchantappusers",
        GetMerchant: "getmerchant",
        GetMerchants: "getmerchants",
        GetTerminal: "getterminal",
        GetStore: "getstore",
        GetRelationshipManagers: "getrelationshipmanagers",
        GetAcquirerMerchants: "getacquirermerchants",
        GetOnBoardedCustomers: "getonboardedcustomers",

        GetonBoardedCustomerfiles: "getonboardedcustomerfiles",
        GetStores: "getstores",
        GetStoresLite: "getstoreslite",
        GetStoreBankCollection: "getstorebankcollection",
        GetCashiers: "getcashiers",
        GetAcquirers: "getacquirers",
        GetPgAccounts: "getpgaccounts",
        GetPosAccounts: "getposaccounts",
        GetPosTerminals: "posterminals",
        GetTerminals: "getterminals",

        getrewardprductlist: "getrewardprductlist",
        GetSaleTransactions: "getsaletransactions",
        GetTransactions: "gettransactions",
        GetTUCRewardTransctions: "gettucrewardtransactions",
        GetRewardTransctions: "getrewardtransactions",
        GetRewardClaimTransctions: "getrewardclaimtransactions",
        GetCampaignTransactions: "getcampaigntransactions",

        GetTUCPlusRewardTransctions: "gettucplusrewardtransactions",
        GetTUCPlusRewardTransferTransctions:
          "gettucplusrewardtransfertransactions",
        GetRedeemTransactions: "getredeemtransactions",
        GetGiftTransactions: "getgifttransactions",
        GetChangeTransactions: "getchangetransactions",
        GetPaymentTransactions: "getpaymenttransactions",
        GetComissionTransactions: "getcommissiontransactions",
        GetSalesTransactions: "getsalestransactions",

        GetInvoices: "getsettlementinvoices",
        SaveCampaign: "savecampaign",
        UpdateCampaign: "updatecampaign",
        DeleteCampaign: "deletecampaign",
        GetCampaign: "getcampaign",
        GetCampaigns: "getcampaigns",
        getonboardedmerchants: "getonboardedmerchants"
      },
      Core: {


        // dev_kishor start
        getcountries: "getcountries",
        getstates: "getstates",
        savecity: "savecity",
        getcity: "getcity",
        getcities: "getcities",
        getcityareas: "getcityareas",
        savecityarea: "savecityarea",
        updatecity: "updatecity",
        deletecity: "deletecity",
        updatecityarea: "updatecityarea",
        deletecityarea: "deletecityarea",
        getcityarea: "getcityarea",
        // dev_kishor end


        getcustomer: "getcustomer",

        onboardmerchants: "onboardmerchants",
        OnBoardCustomers: "onboardcustomers",
        getcitybyregionarea: "getcitybyregionarea",
        getcityareabycity: "getcityareabycity",
        getregionsbycountry: "getregionsbycountry",
        getsubadmins: "getsubadmins",
        getsubadmindetails: "getsubadmindetails",
        addsubadmin: "addsubadmin",
        updatesubadmin: "updatesubadmin",
        deletesubadmin: "deletesubadmin",
        Register: "register",
        SaveCoreCommon: "savecorecommon",
        SaveCoreHelper: "savecorehelper",
        SaveCoreParameter: "savecoreparameter",
        SaveUserAccount: "saveuseraccount",
        SaveUserParameter: "saveuserparameter",

        UpdateCoreCommon: "updatecorecommon",
        UpdateCoreHelper: "updatecorehelper",
        UpdateCoreParameter: "updatecoreparameter",
        UpdateUserAccount: "updateuseraccount",
        UpdateUserAccountPassword: "updateuserpassword",
        UpdateUserAccountAccessPin: "updateuseraccesspin",
        UpdateUserParameter: "updateuserparameter",

        DeleteCoreCommon: "deletecorecommon",
        DeleteCoreHelper: "deletecorehelper",
        DeleteCoreParameter: "deletecoreparameter",
        DeleteUserAccount: "deleteuseraccount",
        DeleteUserParameter: "deleteuserparameter",

        UpdateUserSession: "updateusersession",
        UpdateUserDevice: "updateuserdevice",

        GetCoreHelper: "getcorehelper",
        GetCoreHelpers: "getcorehelpers",
        GetCoreHelpersLite: "getcorehelperslite",
        GetCoreCommon: "getcorecommon",
        GetCoreCommons: "getcorecommons",
        GetCoreCommonsLite: "getcorecommonslite",
        GetCoreParameter: "getcoreparameter",
        GetCoreParameters: "getcoreparameters",
        GetCoreLog: "getcorelog",
        GetCoreLogs: "getcorelogs",
        GetCoreUsage: "getcoreusage",
        GetCoreUsages: "getcoreusages",
        GetStorage: "getstorage",
        GetStorages: "getstorages",
        GetCoreVerification: "getcoreverification",
        GetCoreVerifications: "getcoreverifications",
        GetUserAccount: "getuseraccount",
        GetUserAccounts: "getuseraccounts",
        GetRelationshipManagers: "getrelationshipmanagers",
        GetUserAccountsLite: "getuseraccountslite",
        GetUserAccountOwner: "getuseraccountowner",
        GetUserAccountOwners: "getuseraccountowners",
        GetTransaction: "gettransaction",
        GetTransactions: "gettransactions",
        GetUserActivity: "getuseractivity",
        GetUserActivities: "getuseractivities",
        GetUserDevice: "getuserdevice",
        GetUserDevices: "getuserdevices",
        GetUserSession: "getusersession",
        GetUserSessions: "getusersessions",
        GetUserParameter: "getuserparameter",
        GetUserParameters: "getuserparameters",
        GetUserLocation: "getuserlocation",
        GetUserLocations: "getuserlocations",
        GetUserInvoice: "getuserinvoice",
        GetUserInvoices: "getuserinvoices",
        GetRMStores: "getrmstores",
        UpdateStore: "updatestore",
        GetRMTerminals: "getrmterminals",
        GetManagers: "getmanagers",
        SaveManager: "savemanager",
        GetManger: "getmanager",
        UpdateManager: "updatemanager",
        SaveBranch: "savebranch",
        GetBranch: "getbranch",
        UpdateBranch: "updatebranch",
        GetBranches: "getbranches",
        SaveTerminal: "saveterminal",
        SaveTerminals: "saveterminals",
        GetSubAccounts: "getsubaccounts",
        SaveSubAccount: "savesubaccount",
        GetSubAccount: "getsubaccount",
        UpdateSubAccount: "updatesubaccount",
        SaveStore: "savestore",
        SaveMerchant: "savemerchant",
        ResetUserAccountPin: "resetuseraccesspin",
      },
      WorkHorse: {
        saveworkhorse: "saveworkhorse",
        updateworkhorse: "updateworkhorse",
        getworkhorse: "getworkhorse",
        getworkhorseitems: "getworkhorseitems",
        getworkhorsesubitems: "getworkhorsesubitems",
        getworkhorseactivities: "getworkhorseactivities",
      },
      Product: {
        updateproductrewardpercentage: "updateproductrewardpercentage",
        getproductbalance: "getproductbalance",
        creditproductaccount: "creditproductaccount",
        saveproduct: "saveproduct",
        createquickgiftcard: "createquickgiftcard",
        getproductcodes: "getproductcodes",
        getproducts: "getproducts",
        creategiftcard: "creategiftcard",
        creategiftcardcode: "creategiftcardcode",
      },
    },
    Pages: {
      // ThankUCash: {
      //   Dashboard: {
      //     PTSP: "/provider/dashboard",
      //     Merchant: "/m/dashboard",
      //     MerchantSales: "/m/salesdashboard",
      //     MerchantRewards: "/m/rewardsdashboard",
      //     Acquirer: "/acquirer/dashboard",
      //     Rm: "/rm/dashboard",
      //     Store: "/store/dashboard",
      //     Console: "/system/dashboard",
      //   },
      //   PTSP: {
      //     Terminals: "/provider/terminals",
      //     Merchants: "/provider/merchants",
      //     RewardHistory: "/provider/rewardhistory",
      //     RedeemHistory: "/provider/redeemhistory",
      //     CommissionHistory: "/provider/commissionhistory",
      //     RequestHistory: "/provider/requesthistory",
      //     Profile: "/provider/profile",
      //   },
      //   PanelConsole: {
      //     FAQs: "/acquirer/faqs",
      //     Profile: "/system/profile",
      //     LiveTerminals: "/system/liveterminals",
      //     Merchants: "/system/accounts/merchants",
      //     Stores: "/system/accounts/stores",
      //     Terminals: "/system/accounts/terminals",
      //     Acquirers: "/system/accounts/acquirers",
      //     Customers: "/system/accounts/customers",
      //     SuspendedCustomers: "/system/accounts/suspendedcustomers",
      //     BlockedCustomers: "/system/accounts/blockedcustomers",
      //     PgAccounts: "/system/accounts/gatewayproviders",
      //     PosAccounts: "/system/accounts/terminalprovider",
      //     Cashier: "/system/account/cashier",
      //     Transactions: "/system/transactions/all",
      //     Reward: "/system/transactions/rewardhistory",
      //     RewardClaim: "/system/transactions/rewardclaimhistory",
      //     Redeem: "/system/transactions/redeemhistory",
      //     Sale: "/system/sales/salehistory",
      //     Downloads: "/system/downloads",
      //     AppUser: {
      //       Dashboard: "/system/account/customer/dashboard",
      //       Reward: "/system/account/customer/transactions/rewardhistory",
      //       RewardClaim:
      //         "/system/account/customer/transactions/rewardclaimhistory",
      //       Redeem: "/system/account/customer/transactions/redeemhistory",
      //       Sale: "/system/account/customer/sales/salehistory",
      //       ManageAccount: "/system/account/customer/manage",
      //     },
      //     Merchant: {
      //       Dashboard: "/system/account/merchant/dashboard",
      //       Dashboards: {
      //         TodaysOverview: "/system/account/merchant/todaysoverview",
      //         Sales: "/system/account/merchant/salesdashboard",
      //         Rewards: "/system/account/merchant/rewardsdashboard",
      //       },
      //       // ManageAccount: "/system/account/merchant/manage",
      //       ManageAccount: {
      //         Overview: "/system/account/merchantmanager/overview",
      //         BusinessProfile:
      //           "/system/account/merchantmanager/businessprofile",
      //         Account: "/system/account/merchantmanager/account",
      //         Terminals: "/system/account/merchantmanager/terminals",
      //         Stores: "/system/account/merchantmanager/stores",
      //         SubAccounts: "/system/account/merchantmanager/managers",
      //         Cashiers: "/system/account/merchantmanager/cashiers",
      //         Configurations: "/system/account/merchantmanager/configurations",
      //         Products: "/system/account/merchantmanager/products",
      //       },
      //       SubAccounts: {
      //         Customers: "/system/account/merchant/customers",
      //         Stores: "/system/account/merchant/stores",
      //         Terminals: "/system/account/merchant/terminals",
      //         Managers: "/system/account/merchant/managers",
      //         Cashiers: "/system/account/merchant/cashiers",
      //       },
      //       GiftPoints: "/system/account/merchant/transactions/giftpoints",
      //       Product: {
      //         GiftCards: {
      //           QuickGiftCards:
      //             "/system/account/merchant/product/guickgiftcards",
      //           AddBulkGiftCards:
      //             "/system/account/merchant/product/creategiftcard",
      //           Wallet: "/system/account/merchant/product/giftcards/wallet",
      //           Manage: "/system/account/merchant/product/giftcards/manage",
      //           Codes:
      //             "/system/account/merchant/product/giftcards/saleshistory",
      //           GiftCards: "/system/account/merchant/product/giftcards",
      //         },
      //       },
      //       Reward: "/system/account/merchant/transactions/rewardhistory",
      //       RewardClaim:
      //         "/system/account/merchant/transactions/rewardclaimhistory",
      //       Redeem: "/system/account/merchant/transactions/redeemhistory",
      //       Sale: "/system/account/merchant/sales/salehistory",
      //     },
      //     AcquirerPanel: {
      //       AcquirerDetails: "acquirers/acquirerdetails",
      //       SubAdminDetails: "/console/acquirer/subadmindetails",
      //       Subadmins: "/console/acquirer/subadmins",
      //       Configurations: "/console/acquirer/configuration",

      //       Dashboard: "/console/acquirer/dashboard",
      //       AcquirerAccount: "/console/acquirer/acquireraccount",
      //       Merchants: "/console/acquirer/merchants",
      //       Terminals: "/console/acquirer/terminals",
      //       Stores: "/console/acquirer/stores",
      //       SalesHistory: "/console/acquirer/saleshistory",
      //       MerchantOnboarding: "/console//acquirer/merchantonboarding",

      //       Activity: "/console/acquirer/activity",
      //       Accounts: {
      //         Branch: "/console/acquirer/branchs",
      //         Rms: "/console/acquirer/rms",
      //         Subadmins: "/console/acquirer/subadmins",
      //         Managers: "/console/acquirer/managers",
      //       },
      //       Campaigns: {
      //         Dashboard: "/console/acquirer/campaign/dashboard",
      //         Campaign: "/console/acquirer/campaign",
      //         Campaigns: "/console/acquirer/campaigns",
      //         AddCampaign: "/console/acquirer/addcampaigns",
      //         Sales: "/console/acquirer/campaign/sales/saleshistory",
      //       },
      //       AddAccount: {
      //         AddRm: "/console/acquirer/addrm",
      //         AddBranch: "/console/acquirer/addbranch",
      //       },
      //       AccountDetails: {
      //         BranchDetails: "/console/acquirer/branchdetails",
      //         Rm: "/console/acquirer/rm",
      //         SubAdminDetails: "/console/acquirer/subadmindetails",
      //         Branch: "/console/acquirer/branchs",
      //       },
      //       Branches: {
      //         BranchUpdate: "/console/acquirer/branchdetails/branchupdate",
      //         Manager: "/console/acquirer/branchdetails/manager",
      //         EditBranch: "/console/acquirer/branchdetails/editbranch",
      //         BranchTransactions: "/console/acquirer/branchdetails/sales",
      //         BranchTerminals: "/console/acquirer/branchdetails/terminals",
      //         Stores: "/console/acquirer/branchdetails/stores",
      //         AddBranch: "/console/acquirer/addbranch",


      //       },
      //       Store: {
      //         Dashboard: "/console/acquirer/store/dashboard",
      //         SalesHistory: "/console/acquirer/store/saleshistory",
      //         Terminals: "/console/acquirer/store/terminals",
      //       },
      //       Merchant: {
      //         Dashboard: "/console/acquirer/merchant/dashboard",
      //         // SalesHistory: "/acquirer/merchant/saleshistory",
      //         Stores: "/console/acquirer/merchant/stores",
      //         Terminals: "/console/acquirer/merchant/terminals",
      //         SalesHistory: "/console/acquirer/merchant/sales/saleshistory",
      //       },
      //       Terminal: {
      //         Dashboard: "/console/acquirer/terminal/dashboard",
      //         // RewardHistory: "/acquirer/terminal/rewardhistory",
      //         // RewardClaimHistory: "/acquirer/terminal/rewardclaimhistory",
      //         // RedeemHistory: "/acquirer/terminal/redeemhistory",
      //         SalesHistory: "/console/acquirer/terminal/sales/saleshistory",
      //       },
      //       Manager: {
      //         Dashboard: "/console/acquirer/manager/dashboard",
      //         // RewardHistory: "/acquirer/terminal/rewardhistory",
      //         // RewardClaimHistory: "/acquirer/terminal/rewardclaimhistory",
      //         // RedeemHistory: "/acquirer/terminal/redeemhistory",
      //         Terminals: "/console/acquirer/manager/terminals",
      //         Stores: "/console/acquirer/branchdetails/stores",
      //         SalesHistory: "/console/acquirer/branchdetails/sales",
      //       },
      //       ManagerDetail: {
      //         Overview: "/console/acquirer/manager/dashboard",
      //         Terminals: "/console/acquirer/manager/terminals",
      //         Stores: "/console/acquirer/manager/stores",
      //         Teams: "/console/acquirer/manager/teams",
      //         Targets: "/console/acquirer/manager/targets",
      //       },

      //     },
      //     Store: {
      //       Dashboard: "/system/account/store/dashboard",
      //       ManageAccount: "/system/account/store/manage",
      //       Reward: "/system/account/store/transactions/rewardhistory",
      //       RewardClaim:
      //         "/system/account/store/transactions/rewardclaimhistory",
      //       Redeem: "/system/account/store/transactions/redeemhistory",
      //       Sale: "/system/account/store/sales/salehistory",
      //       SubAccounts: {
      //         Customers: "/system/account/store/customers",
      //         Terminals: "/system/account/store/terminals",
      //       },
      //     },
      //     Acquirer: {
      //       Dashboard: "/system/account/acquirer/dashboard",
      //       ManageAccount: "/system/account/acquirer/manage",
      //       Reward: "/system/account/acquirer/transactions/rewardhistory",
      //       RewardClaim:
      //         "/system/account/acquirer/transactions/rewardclaimhistory",
      //       Redeem: "/system/account/acquirer/transactions/redeemhistory",
      //       Sale: "/system/account/acquirer/sales/salehistory",
      //       SubAccounts: {
      //         Terminals: "/system/account/acquirer/terminals",
      //       },
      //     },
      //     PgAccount: {
      //       Dashboard: "/system/account/gatewayprovider/dashboard",
      //       ManageAccount: "/system/account/gatewayprovider/manage",
      //       Reward:
      //         "/system/account/gatewayprovider/transactions/rewardhistory",
      //       RewardClaim:
      //         "/system/account/gatewayprovider/transactions/rewardclaimhistory",
      //       Redeem:
      //         "/system/account/gatewayprovider/transactions/redeemhistory",
      //       Sale: "/system/account/gatewayprovider/sales/salehistory",
      //     },
      //     PosAccount: {
      //       Dashboard: "/system/account/terminalprovider/dashboard",
      //       ManageAccount: "/system/account/terminalprovider/manage",
      //       Reward:
      //         "/system/account/terminalprovider/transactions/rewardhistory",
      //       RewardClaim:
      //         "/system/account/terminalprovider/transactions/rewardclaimhistory",
      //       Redeem:
      //         "/system/account/terminalprovider/transactions/redeemhistory",
      //       Sale: "/system/account/terminalprovider/sales/salehistory",

      //       SubAccounts: {
      //         Terminals: "/system/account/terminalprovider/terminals",
      //       },
      //     },
      //     Terminal: {
      //       Dashboard: "/system/account/terminal/dashboard",
      //       ManageAccount: "/system/account/terminal/manage",
      //       Reward: "/system/account/terminal/transactions/rewardhistory",
      //       RewardClaim:
      //         "/system/account/terminal/transactions/rewardclaimhistory",
      //       Redeem: "/system/account/terminal/transactions/redeemhistory",
      //       Sale: "/system/account/terminal/sales/salehistory",
      //       SubAccounts: {
      //         Customers: "/system/account/terminal/customers",
      //       },
      //     },
      //     UserOnboarding: {
      //       Merchant: "/system/onboarding/merchant",
      //       PendingMerchant: "/system/onboarding/pendingmerchantonboarding",
      //       PendingMerchantManager: "/system/onboarding/pendingmerchantmanager",
      //       Customer: "/system/onboarding/customer",
      //       Acquirer: "/system/onboarding/acquirer",
      //       PgAccount: "/system/onboarding/gatewayprovider",
      //       PosAccount: "/system/onboarding/terminalprovider",
      //     },

      //     Invoices: {
      //       All: "/system/invoices",
      //       Details: "/system/invoice",
      //     },
      //     Analytics: {
      //       Home: "/system/analytics/home",
      //       TodaysOverview: "/system/analytics/todaysoverview",
      //       SystemOverview: "/system/analytics/systemoverview",
      //       GrowthMap: "/system/analytics/growthmap",
      //       CustomerAnalytics: "/system/analytics/customeranalytics",
      //       Rewards: {
      //         DailyRewards: {
      //           Merchants: "/system/analytics/dailyrewards/merchants",
      //           Stores: "/system/analytics/dailyrewards/stores",
      //           Terminals: "/system/analytics/dailyrewards/terminals",
      //         },
      //         RewardsSummary: {
      //           Merchants: "/system/analytics/rewardssummary/merchants",
      //           Stores: "/system/analytics/rewardssummary/stores",
      //           Terminals: "/system/analytics/rewardssummary/terminals",
      //         },
      //       },
      //       Sales: {
      //         // DailySales: "/system/analytics/dailysales",

      //         DailySales: {
      //           Merchants: "/system/analytics/dailysales/merchants",
      //           Stores: "/system/analytics/dailysales/stores",
      //           Terminals: "/system/analytics/dailysales/terminals",
      //         },
      //         SalesSummary: {
      //           Merchants: "/system/analytics/salessummary/merchants",
      //           Stores: "/system/analytics/salessummary/stores",
      //           Terminals: "/system/analytics/salessummary/terminals",
      //         },
      //       },
      //     },
      //     Administration: {
      //       AppRequesthistory: "/acquirer/app/requesthistory",
      //       AppSlider: "/system/control/appslider",
      //       AdministrationHome: "/system/control/administration",
      //       ApiRequestHistory: "/system/control/apirequesthistory",
      //       Categories: "/system/control/categories",
      //       Roles: "/system/control/roles",
      //       AdminFeatures: "/system/control/features",
      //       ResponseCodes: "/system/control/responsecodes",
      //       SystemLog: "/system/control/systemlog",
      //       Apps: "/system/control/apps",
      //       Configurations: "/system/control/configurations",
      //       ConfigurationManager: "/system/control/configurationmanager",
      //       Verifications: "/system/control/verifications",
      //       UserSessions: "/system/control/usersessions",
      //       AdminUsers: "/system/control/adminusers",
      //       AddAdminUsers: "/system/control/addadminuser",
      //       AdminUser: {
      //         Dashboard: "/system/control/adminuser/dashboard",
      //         Sessions: "/system/control/adminuser/loginhistory",
      //       },
      //       WorkHorse: {
      //         SystemUpdates: "/system/control/workhorse/systemupdates",
      //         UpdateFeatureRequests:
      //           "/system/control/workhorse/updatefeaturerequests",
      //       },
      //     },

      //     // Terminal: {
      //     //   Dashboard: "/system/terminal/dashboard",
      //     //   RewardHistory: "/system/terminal/rewardhistory",
      //     //   RewardClaimHistory: "/system/terminal/rewardclaimhistory",
      //     //   RedeemHistory: "/system/terminal/redeemhistory",
      //     //   SalesHistory: "/system/terminal/saleshistory",
      //     // },
      //     // Live: "/acquirer/live",
      //     // TerminalLive: "/acquirer/terminallive",
      //     // Customers: "/acquirer/customers",
      //     // Customer: "/acquirer/customer/dashboard",
      //     // SubAccounts: "/acquirer/subaccounts",
      //     // ReferredMerchants: "/acquirer/referredmerchants",
      //     // AddTerminal: "/acquirer/addterminal",
      //     // TerminalsSale: "/acquirer/terminalssale",
      //     // Store: {
      //     //   Dashboard: "/acquirer/store/dashboard",
      //     //   SalesHistory: "/acquirer/store/saleshistory",
      //     //   Terminals: "/acquirer/store/terminals"
      //     // },
      //     // Merchant: {
      //     //   Dashboard: "/acquirer/merchant/dashboard",
      //     //   SalesHistory: "/acquirer/merchant/saleshistory",
      //     //   Stores: "/acquirer/merchant/stores",
      //     //   Terminals: "/acquirer/merchant/terminals"
      //     // },
      //     // Terminal: {
      //     //   Dashboard: "/acquirer/terminal/dashboard",
      //     //   SalesHistory: "/acquirer/terminal/saleshistory"
      //     // },
      //     // CustomerUpload: "/acquirer/customerupload",
      //     // CustomerOnboarding: "/acquirer/customeronboarding",
      //     // MerchantOnboarding: "/acquirer/merchantonboarding",
      //     // MerchantUpload: "/acquirer/merchantupload",
      //     // MerchantManager: "/acquirer/merchantmanager",
      //     // CustomerRewardHistory: "/acquirer/customer/rewardhistory",
      //     // CustomerRedeemHistory: "/acquirer/customer/redeemhistory",
      //     // Stores: "/acquirer/stores",
      //     // AppUser: {
      //     //   Dashboard: "/acquirer/customer/dashboard",
      //     //   UpdateStore: "/acquirer/customer/updatestore",
      //     //   RewardHistory: "/acquirer/customer/rewardhistory",
      //     //   TUCPlusRewardHistory: "/acquirer/customer/tucplusrewardhistory",
      //     //   TUCPlusRewardClaimHistory:
      //     //     "/acquirer/customer/tucplusrewardclaimhistory",
      //     //   RedeemHistory: "/acquirer/customer/redeemhistory"
      //     // },
      //     // Terminals: "/acquirer/terminals",
      //     // TerminalRewardHistory: "/acquirer/terminal/rewardhistory",
      //     // TerminalRedeemHistory: "/acquirer/terminal/redeemhistory",
      //     // Pssp: "/acquirer/gateways",
      //     // SalesHistory: "/acquirer/saleshistory",
      //     // // PaymentsHistory: "/acquirer/paymentshistory",
      //     // TUCPlusRewardHistory: "/acquirer/tucplusrewardhistory",
      //     // TUCPlusRewardClaimHistory: "/acquirer/tucplusrewardclaimhistory",
      //     // RewardHistory: "/acquirer/rewardhistory",
      //     // RedeemHistory: "/acquirer/redeemhistory",
      //     // DailyCommissionHistory: "/acquirer/dailycommissionhistory",
      //     // StoreSale: "/acquirer/storesale",
      //     // Profile: "/acquirer/profile",
      //     // RewardInvoices: "/acquirer/rewardinvoices",
      //     // RedeemInvoices: "/acquirer/redeeminvoices"
      //   },
      //   Partner: {
      //     BulkMerchantUpload: "/acquirer/merchantupload",
      //     BulkCustomerUpload: "/acquirer/bulkcustomerupload",
      //     BulkCustomers: "/acquirer/customers",
      //     Customers: "/acquirer/allcustomers",



      //     BulkMerchants: "/acquirer/bulkmerchants",


      //   },
      //   PanelAcquirer: {
      //     Apps: "/acquirer/apps",
      //     App: "/acquirer/app",

      //     SalesHistory: "/acquirer/saleshistory",
      //     Activity: "/acquirer/activity",
      //     SalesReport: "/acquirer/salesreport",
      //     RMReport: "/acquirer/rmreports",
      //     SubAccounts: "/acquirer/subaccounts",
      //     Targets: "/acquirer/targets",
      //     Terminals: "/acquirer/terminals",
      //     Campaigns: {
      //       Dashboard: "/acquirer/campaign/dashboard",
      //       Campaign: "/acquirer/campaign",
      //       Campaigns: "/acquirer/campaigns",
      //       AddCampaign: "/acquirer/addcampaign",
      //       Sales: "/acquirer/campaign/sales/saleshistory",
      //     },

      //     Accounts: {
      //       Branch: "/acquirer/branch",
      //       Rms: "/acquirer/rms",
      //       Subadmins: "/acquirer/subadmins",
      //     },
      //     AddAccount: {
      //       AddRm: "/acquirer/addrm",
      //       AddBranch: "/acquirer/addbranch",
      //     },
      //     AccountDetails: {
      //       BranchDetails: "/acquirer/branchdetails",
      //       Rm: "/acquirer/rm",
      //       SubAdminDetails: "/acquirer/subadmindetails",
      //       Branch: "/acquirer/branchs",
      //     },
      //     Branches: {
      //       BranchUpdate: "/acquirer/branchdetails/branchupdate",
      //       Manager: "/acquirer/branchdetails/manager",
      //       EditBranch: "/acquirer/branchdetails/editbranch",
      //       BranchTransactions: "/acquirer/branchdetails/sales",
      //       BranchTerminals: "/acquirer/branchdetails/terminals"
      //     },

      //     Analytics: {
      //       Sales: "/acquirer/salesanalytics",
      //     },
      //     Live: {
      //       Terminals: "/acquirer/live/terminals",
      //       Merchants: "/acquirer/live/merchants",
      //     },
      //     Manager: {
      //       Dashboard: "/acquirer/manager/dashboard",
      //       // RewardHistory: "/acquirer/terminal/rewardhistory",
      //       // RewardClaimHistory: "/acquirer/terminal/rewardclaimhistory",
      //       // RedeemHistory: "/acquirer/terminal/redeemhistory",
      //       Terminals: "/acquirer/manager/terminals",
      //       Stores: "/acquirer/branchdetails/stores",
      //       SalesHistory: "/acquirer/branchdetails/sales",
      //     },
      //     ManagerAlternative: {
      //       Dashboard: "/acquirer/manageralternative/dashboard",
      //       // RewardHistory: "/acquirer/terminal/rewardhistory",
      //       // RewardClaimHistory: "/acquirer/terminal/rewardclaimhistory",
      //       // RedeemHistory: "/acquirer/terminal/redeemhistory",
      //       Terminals: "/acquirer/manageralternative/terminals",
      //       Stores: "/acquirer/branchdetails/stores",
      //       SalesHistory: "/acquirer/branchdetails/sales",
      //     },
      //     ManagerDetail: {
      //       Overview: "/acquirer/managera/dashboard",
      //       Terminals: "/acquirer/manager/terminals",
      //       Stores: "/acquirer/manager/stores",
      //       Teams: "/acquirer/manager/teams",
      //       Targets: "/acquirer/manager/targets",
      //     },
      //     ManagerDetailAlternative: {
      //       Overview: "/acquirer/manageralternative/dashboard",
      //       Terminals: "/acquirer/manageralternative/terminals",
      //       Stores: "/acquirer/manageralternative/stores",
      //       Teams: "/acquirer/manageralternative/teams",
      //       Targets: "/acquirer/manageralternative/targets",
      //     },
      //     Terminal: {
      //       Dashboard: "/acquirer/terminal/dashboard",
      //       // RewardHistory: "/acquirer/terminal/rewardhistory",
      //       // RewardClaimHistory: "/acquirer/terminal/rewardclaimhistory",
      //       // RedeemHistory: "/acquirer/terminal/redeemhistory",
      //       SalesHistory: "/acquirer/terminal/sales/saleshistory",
      //     },
      //     // LiveTerminals: "/acquirer/liveterminals",
      //     // Live: "/acquirer/live",
      //     Customers: "/acquirer/customers",
      //     Customer: "/acquirer/customer/dashboard",
      //     ReferredMerchants: "/acquirer/referredmerchants",
      //     AddTerminal: "/acquirer/addterminal",
      //     TerminalsSale: "/acquirer/terminalssale",
      //     Store: {
      //       Dashboard: "/acquirer/store/dashboard",
      //       SalesHistory: "/acquirer/store/saleshistory",
      //       Terminals: "/acquirer/store/terminals",
      //     },
      //     Merchant: {
      //       Dashboard: "/acquirer/merchant/dashboard",
      //       // SalesHistory: "/acquirer/merchant/saleshistory",
      //       Stores: "/acquirer/merchant/stores",
      //       Terminals: "/acquirer/merchant/terminals",
      //       SalesHistory: "/acquirer/merchant/sales/saleshistory",
      //     },

      //     Merchants: "/acquirer/merchants",
      //     CustomerUpload: "/acquirer/customerupload",
      //     CustomerOnboarding: "/acquirer/customeronboarding",
      //     MerchantOnboarding: "/acquirer/merchantonboarding",
      //     MerchantUpload: "/acquirer/merchantupload",
      //     MerchantManager: "/acquirer/merchantmanager",
      //     CustomerRewardHistory: "/acquirer/customer/rewardhistory",
      //     CustomerRedeemHistory: "/acquirer/customer/redeemhistory",
      //     Stores: "/acquirer/stores",
      //     Temp: "/acquirer/temp",
      //     AppUser: {
      //       Dashboard: "/acquirer/customer/dashboard",
      //       UpdateStore: "/acquirer/customer/updatestore",
      //       RewardHistory: "/acquirer/customer/rewardhistory",
      //       TUCPlusRewardHistory: "/acquirer/customer/tucplusrewardhistory",
      //       TUCPlusRewardClaimHistory:
      //         "/acquirer/customer/tucplusrewardclaimhistory",
      //       RedeemHistory: "/acquirer/customer/redeemhistory",
      //     },

      //     TerminalRewardHistory: "/acquirer/terminal/rewardhistory",
      //     TerminalRedeemHistory: "/acquirer/terminal/redeemhistory",
      //     Pssp: "/acquirer/gateways",

      //     // PaymentsHistory: "/acquirer/paymentshistory",

      //     TUCPlusRewardHistory: "/acquirer/tucplusrewardhistory",
      //     TUCPlusRewardClaimHistory: "/acquirer/tucplusrewardclaimhistory",
      //     RewardHistory: "/acquirer/rewardhistory",
      //     RedeemHistory: "/acquirer/redeemhistory",

      //     CommissionHistory: "/acquirer/commissionhistory",
      //     DailyCommissionHistory: "/acquirer/dailycommissionhistory",
      //     StoreSale: "/acquirer/storesale",
      //     Profile: "/acquirer/profile",

      //     RewardInvoices: "/acquirer/rewardinvoices",
      //     RedeemInvoices: "/acquirer/redeeminvoices",
      //   },
      //   PanelRm: {
      //     Live: {
      //       Terminals: "/rm/live/terminals",
      //     },
      //     Accounts: {
      //       Terminals: "/rm/terminals",
      //       Stores: "/rm/stores",
      //     },
      //   },
      //   PanelMerchant: {


      //     FAQs: "/acquirer/faqs",
      //     FAQCategories: "/acquirer/faqcategories",
      //     Invoices: "/m/invoices",
      //     Invoice: "/m/invoice",
      //     Cashiers: "/m/cashiers",
      //     Cashier: "/m/cashier",
      //     Managers: "/m/managers",
      //     Stores: "/m/stores",
      //     Terminals: "/m/terminals",
      //     Customers: "/m/customers",
      //     GiftPoints: "/m/giftpoints",
      //     GiftCards: "/m/giftcards",
      //     Products: "/m/products",
      //     Downloads: "/m/downloads",
      //     Profile: "/m/profile",
      //     Analytics: {
      //       StoresTucSummary: "/m/analytics/storestucsummary",
      //       Live: "/m/live",
      //       TerminalLive: "/m/analytics/terminalstracker",
      //       DailySales: "/m/analytics/dailysales",
      //       StoreBankCollection: "/m/analytics/bankcollections",
      //     },
      //     Transactions: {
      //       Sales: "/m/transactions/saleshistory",
      //       Rewards: "/m/transactions/rewards",
      //       RewardClaims: "/m/transactions/rewardclaims",
      //       Redeem: "/m/transactions/redeem",
      //     },
      //     Store: {
      //       Dashboard: "/m/store/dashboard",
      //       Terminals: "/m/store/terminals",
      //       RewardHistory: "/m/store/transactions/rewardhistory",
      //       RewardClaimHistory: "/m/store/transactions/rewardclaimhistory",
      //       RedeemHistory: "/m/store/transactions/redeemhistory",
      //       SalesHistory: "/m/store/sales/salehistory",
      //       // UpdateStore: "/merchant/store/updatestore",
      //       // RewardHistory: "/merchant/store/rewardhistory",
      //       // TUCPlusRewardHistory: "/merchant/store/tucplusrewardhistory",
      //       // TUCPlusRewardClaimHistory:"/merchant/store/tucplusrewardclaimhistory",
      //       // RedeemHistory: "/merchant/store/redeemhistory"
      //     },
      //     Terminal: {
      //       Dashboard: "/m/terminal/dashboard",
      //       RewardHistory: "/m/terminal/transactions/rewardhistory",
      //       RewardClaimHistory: "/m/terminal/transactions/rewardclaimhistory",
      //       RedeemHistory: "/m/terminal/transactions/redeemhistory",
      //       SalesHistory: "/m/terminal/sales/salehistory",
      //     },
      //     Customer: {
      //       Dashboard: "/m/customer/dashboard",
      //       RewardHistory: "/m/customer/transactions/rewardhistory",
      //       RewardClaimHistory: "/m/customer/transactions/rewardclaimhistory",
      //       RedeemHistory: "/m/customer/transactions/redeemhistory",
      //       SalesHistory: "/m/customer/sales/salehistory",
      //     },
      //     // Customer: "/merchant/customer/dashboard",
      //     SubAccounts: "/merchant/subaccounts",
      //     RewardHistory: "/merchant/rewardhistory",
      //     RewardClaimHistory: "/merchant/rewardclaimhistory",
      //     RedeemHistory: "/merchant/redeemhistory",
      //     SalesHistory: "/merchant/saleshistory",
      //     AppUser: {
      //       Dashboard: "/merchant/customer/dashboard",
      //       RewardHistory: "/merchant/customer/rewardhistory",
      //       RewardClaimHistory: "/merchant/customer/rewardclaimhistory",
      //       RedeemHistory: "/merchant/customer/redeemhistory",
      //       SalesHistory: "/merchant/customer/saleshistory",
      //       // Dashboard: "/merchant/customer/dashboard",
      //       // UpdateStore: "/merchant/customer/updatestore",
      //       // RewardHistory: "/merchant/customer/rewardhistory",
      //       // TUCPlusRewardHistory: "/merchant/customer/tucplusrewardhistory",
      //       // TUCPlusRewardClaimHistory:
      //       //   "/merchant/customer/tucplusrewardclaimhistory",
      //       // RedeemHistory: "/merchant/customer/redeemhistory"
      //     },
      //     Pssp: "/merchant/gateways",
      //     CommissionHistory: "/merchant/commissionhistory",
      //     StoreSale: "/merchant/storesale",
      //     // Profile: "/merchant/profile",
      //     RewardInvoices: "/merchant/rewardinvoices",
      //     RedeemInvoices: "/merchant/redeeminvoices",
      //   },
      //   PanelMerchantRewards: {
      //     Invoices: "/mr/invoices",
      //     Invoice: "/mr/invoice",
      //     Cashiers: "/mr/cashiers",
      //     Cashier: "/mr/cashier",
      //     Managers: "/mr/managers",

      //     Transactions: {
      //       Sales: "/mr/transactions/saleshistory",
      //     },
      //     Stores: "/mr/stores",
      //     Store: {
      //       Dashboard: "/merchant/store/dashboard",
      //       Terminals: "/merchant/store/terminals",
      //       RewardHistory: "/merchant/store/rewardhistory",
      //       RewardClaimHistory: "/merchant/store/rewardclaimhistory",
      //       RedeemHistory: "/merchant/store/redeemhistory",
      //       SalesHistory: "/merchant/store/saleshistory",
      //       // UpdateStore: "/merchant/store/updatestore",
      //       // RewardHistory: "/merchant/store/rewardhistory",
      //       // TUCPlusRewardHistory: "/merchant/store/tucplusrewardhistory",
      //       // TUCPlusRewardClaimHistory:"/merchant/store/tucplusrewardclaimhistory",
      //       // RedeemHistory: "/merchant/store/redeemhistory"
      //     },

      //     Terminals: "/m/terminals",
      //     Terminal: {
      //       Dashboard: "/merchant/terminal/dashboard",
      //       RewardHistory: "/merchant/terminal/rewardhistory",
      //       RewardClaimHistory: "/merchant/terminal/rewardclaimhistory",
      //       RedeemHistory: "/merchant/terminal/redeemhistory",
      //       SalesHistory: "/merchant/terminal/saleshistory",
      //     },

      //     Live: "/merchant/live",
      //     TerminalLive: "/merchant/terminallive",
      //     Customers: "/merchant/customers",
      //     Customer: "/merchant/customer/dashboard",
      //     SubAccounts: "/merchant/subaccounts",

      //     RewardHistory: "/merchant/rewardhistory",
      //     RewardClaimHistory: "/merchant/rewardclaimhistory",
      //     RedeemHistory: "/merchant/redeemhistory",

      //     SalesHistory: "/merchant/saleshistory",
      //     StoreBankCollection: "/merchant/storebankcollection",

      //     AppUser: {
      //       Dashboard: "/merchant/customer/dashboard",
      //       RewardHistory: "/merchant/customer/rewardhistory",
      //       RewardClaimHistory: "/merchant/customer/rewardclaimhistory",
      //       RedeemHistory: "/merchant/customer/redeemhistory",
      //       SalesHistory: "/merchant/customer/saleshistory",

      //       // Dashboard: "/merchant/customer/dashboard",
      //       // UpdateStore: "/merchant/customer/updatestore",
      //       // RewardHistory: "/merchant/customer/rewardhistory",
      //       // TUCPlusRewardHistory: "/merchant/customer/tucplusrewardhistory",
      //       // TUCPlusRewardClaimHistory:
      //       //   "/merchant/customer/tucplusrewardclaimhistory",
      //       // RedeemHistory: "/merchant/customer/redeemhistory"
      //     },

      //     Pssp: "/merchant/gateways",

      //     CommissionHistory: "/merchant/commissionhistory",
      //     StoreSale: "/merchant/storesale",
      //     Profile: "/merchant/profile",

      //     RewardInvoices: "/merchant/rewardinvoices",
      //     RedeemInvoices: "/merchant/redeeminvoices",
      //   },
      //   Customers: "/system/customers",
      //   AppUsers: "/system/appusers",
      //   AppUser: {
      //     Dashboard: "/system/appuser/dashboard",
      //   },
      //   AddMerchant: "/system/addmerchant",
      //   Merchants: "/system/merchants",
      //   Merchant: {
      //     Dashboard: "/system/merchant/dashboard",
      //   },
      //   Stores: "/system/stores",
      //   AddStore: "/system/addstore",
      //   Store: {
      //     Dashboard: "/system/store/dashboard",
      //     UpdateStore: "/system/store/updatestore",
      //   },
      //   AddCashier: "/system/addcashier",
      //   Cashiers: "/system/cashiers",
      //   Cashier: {
      //     Dashboard: "/system/cashier/dashboard",
      //   },
      //   AddAcquirer: "/system/addacquirer",
      //   Acquirers: "/system/acquirers",
      //   Acquirer: {
      //     Dashboard: "/system/acquirer/dashboard",
      //   },
      //   AddPgAccount: "/system/addpgaccount",
      //   PgAccounts: "/system/pgaccounts",
      //   PgAccount: {
      //     Dashboard: "/system/pgaccount/dashboard",
      //   },
      //   AddPosAccount: "/system/addposaccount",
      //   PosAccounts: "/system/posaccounts",
      //   PosAccount: {
      //     Dashboard: "/provider/terminals",
      //   },
      //   AddPosTerminal: "/system/addposterminal",
      //   PosTerminals: "/system/posterminals",
      //   PosTerminal: {
      //     Dashboard: "/system/posterminal/dashboard",
      //   },
      //   Transactions: {
      //     Reward: "/system/transactions/reward",
      //     Redeem: "/system/transactions/redeem",
      //     Change: "/system/transactions/change",
      //     Gift: "/system/transactions/gift",
      //     Payments: "/system/transactions/payment",
      //   },
      // },

      ThankUCash: {
        Dashboard: {
          PTSP: "/provider/dashboard",
          Merchant: "/m/dashboard",
          MerchantSales: "/m/salesdashboard",
          MerchantRewards: "/m/rewardsdashboard",
          // Acquirer: "/partner/dashboard",
          Acquirer: "/partner/dashboard",
          Rm: "/rm/dashboard",
          Store: "/store/dashboard",
          Console: "/system/dashboard",
        },
        PTSP: {
          Terminals: "/provider/terminals",
          Merchants: "/provider/merchants",
          RewardHistory: "/provider/rewardhistory",
          RedeemHistory: "/provider/redeemhistory",
          CommissionHistory: "/provider/commissionhistory",
          RequestHistory: "/provider/requesthistory",
          Profile: "/provider/profile",
        },
        PanelConsole: {
          Profile: "/system/profile",
          LiveTerminals: "/system/liveterminals",
          Merchants: "/system/accounts/merchants",
          Stores: "/system/accounts/stores",
          Terminals: "/system/accounts/terminals",
          Acquirers: "/system/accounts/acquirers",
          Customers: "/system/accounts/customers",
          SuspendedCustomers: "/system/accounts/suspendedcustomers",
          BlockedCustomers: "/system/accounts/blockedcustomers",
          PgAccounts: "/system/accounts/gatewayproviders",
          PosAccounts: "/system/accounts/terminalprovider",
          Cashier: "/system/account/cashier",
          Transactions: "/system/transactions/all",
          Reward: "/system/transactions/rewardhistory",
          RewardClaim: "/system/transactions/rewardclaimhistory",
          Redeem: "/system/transactions/redeemhistory",
          Sale: "/system/sales/salehistory",
          Downloads: "/system/downloads",
          AppUser: {
            Dashboard: "/system/account/customer/dashboard",
            Reward: "/system/account/customer/transactions/rewardhistory",
            RewardClaim:
              "/system/account/customer/transactions/rewardclaimhistory",
            Redeem: "/system/account/customer/transactions/redeemhistory",
            Sale: "/system/account/customer/sales/salehistory",
            ManageAccount: "/system/account/customer/manage",
          },
          Merchant: {
            Dashboard: "/system/account/merchant/dashboard",
            Dashboards: {
              TodaysOverview: "/system/account/merchant/todaysoverview",
              Sales: "/system/account/merchant/salesdashboard",
              Rewards: "/system/account/merchant/rewardsdashboard",
            },
            // ManageAccount: "/system/account/merchant/manage",
            ManageAccount: {
              Overview: "/system/account/merchantmanager/overview",
              BusinessProfile:
                "/system/account/merchantmanager/businessprofile",
              Account: "/system/account/merchantmanager/account",
              Terminals: "/system/account/merchantmanager/terminals",
              Stores: "/system/account/merchantmanager/stores",
              SubAccounts: "/system/account/merchantmanager/managers",
              Cashiers: "/system/account/merchantmanager/cashiers",
              Configurations: "/system/account/merchantmanager/configurations",
              Products: "/system/account/merchantmanager/products",
            },
            SubAccounts: {
              Customers: "/system/account/merchant/customers",
              Stores: "/system/account/merchant/stores",
              Terminals: "/system/account/merchant/terminals",
              Managers: "/system/account/merchant/managers",
              Cashiers: "/system/account/merchant/cashiers",
            },
            GiftPoints: "/system/account/merchant/transactions/giftpoints",
            Product: {
              GiftCards: {
                QuickGiftCards:
                  "/system/account/merchant/product/guickgiftcards",
                AddBulkGiftCards:
                  "/system/account/merchant/product/creategiftcard",
                Wallet: "/system/account/merchant/product/giftcards/wallet",
                Manage: "/system/account/merchant/product/giftcards/manage",
                Codes:
                  "/system/account/merchant/product/giftcards/saleshistory",
                GiftCards: "/system/account/merchant/product/giftcards",
              },
            },
            Reward: "/system/account/merchant/transactions/rewardhistory",
            RewardClaim:
              "/system/account/merchant/transactions/rewardclaimhistory",
            Redeem: "/system/account/merchant/transactions/redeemhistory",
            Sale: "/system/account/merchant/sales/salehistory",
          },
          Store: {
            Dashboard: "/system/account/store/dashboard",
            ManageAccount: "/system/account/store/manage",
            Reward: "/system/account/store/transactions/rewardhistory",
            RewardClaim:
              "/system/account/store/transactions/rewardclaimhistory",
            Redeem: "/system/account/store/transactions/redeemhistory",
            Sale: "/system/account/store/sales/salehistory",
            SubAccounts: {
              Customers: "/system/account/store/customers",
              Terminals: "/system/account/store/terminals",
            },
          },
          Acquirer: {
            Dashboard: "/system/account/partner/dashboard",
            ManageAccount: "/system/account/acquirer/manage",
            Reward: "/system/account/acquirer/transactions/rewardhistory",
            RewardClaim:
              "/system/account/acquirer/transactions/rewardclaimhistory",
            Redeem: "/system/account/acquirer/transactions/redeemhistory",
            Sale: "/system/account/acquirer/sales/salehistory",
            SubAccounts: {
              Terminals: "/system/account/acquirer/terminals",
            },
          },
          PgAccount: {
            Dashboard: "/system/account/gatewayprovider/dashboard",
            ManageAccount: "/system/account/gatewayprovider/manage",
            Reward:
              "/system/account/gatewayprovider/transactions/rewardhistory",
            RewardClaim:
              "/system/account/gatewayprovider/transactions/rewardclaimhistory",
            Redeem:
              "/system/account/gatewayprovider/transactions/redeemhistory",
            Sale: "/system/account/gatewayprovider/sales/salehistory",
          },
          PosAccount: {
            Dashboard: "/system/account/terminalprovider/dashboard",
            ManageAccount: "/system/account/terminalprovider/manage",
            Reward:
              "/system/account/terminalprovider/transactions/rewardhistory",
            RewardClaim:
              "/system/account/terminalprovider/transactions/rewardclaimhistory",
            Redeem:
              "/system/account/terminalprovider/transactions/redeemhistory",
            Sale: "/system/account/terminalprovider/sales/salehistory",

            SubAccounts: {
              Terminals: "/system/account/terminalprovider/terminals",
            },
          },
          Terminal: {
            Dashboard: "/system/account/terminal/dashboard",
            ManageAccount: "/system/account/terminal/manage",
            Reward: "/system/account/terminal/transactions/rewardhistory",
            RewardClaim:
              "/system/account/terminal/transactions/rewardclaimhistory",
            Redeem: "/system/account/terminal/transactions/redeemhistory",
            Sale: "/system/account/terminal/sales/salehistory",
            SubAccounts: {
              Customers: "/system/account/terminal/customers",
            },
          },
          UserOnboarding: {
            Merchant: "/system/onboarding/merchant",
            PendingMerchant: "/system/onboarding/pendingmerchantonboarding",
            PendingMerchantManager: "/system/onboarding/pendingmerchantmanager",
            Customer: "/system/onboarding/customer",
            Acquirer: "/system/onboarding/acquirer",
            PgAccount: "/system/onboarding/gatewayprovider",
            PosAccount: "/system/onboarding/terminalprovider",
          },

          Invoices: {
            All: "/system/invoices",
            Details: "/system/invoice",
          },
          Analytics: {
            Home: "/system/analytics/home",
            TodaysOverview: "/system/analytics/todaysoverview",
            SystemOverview: "/system/analytics/systemoverview",
            GrowthMap: "/system/analytics/growthmap",
            CustomerAnalytics: "/system/analytics/customeranalytics",
            Rewards: {
              DailyRewards: {
                Merchants: "/system/analytics/dailyrewards/merchants",
                Stores: "/system/analytics/dailyrewards/stores",
                Terminals: "/system/analytics/dailyrewards/terminals",
              },
              RewardsSummary: {
                Merchants: "/system/analytics/rewardssummary/merchants",
                Stores: "/system/analytics/rewardssummary/stores",
                Terminals: "/system/analytics/rewardssummary/terminals",
              },
            },
            Sales: {
              // DailySales: "/system/analytics/dailysales",

              DailySales: {
                Merchants: "/system/analytics/dailysales/merchants",
                Stores: "/system/analytics/dailysales/stores",
                Terminals: "/system/analytics/dailysales/terminals",
              },
              SalesSummary: {
                Merchants: "/system/analytics/salessummary/merchants",
                Stores: "/system/analytics/salessummary/stores",
                Terminals: "/system/analytics/salessummary/terminals",
              },
            },
          },
          Administration: {
            AppSlider: "/system/control/appslider",
            AdministrationHome: "/system/control/administration",
            ApiRequestHistory: "/system/control/apirequesthistory",
            Categories: "/system/control/categories",
            Roles: "/system/control/roles",
            AdminFeatures: "/system/control/features",
            ResponseCodes: "/system/control/responsecodes",
            SystemLog: "/system/control/systemlog",
            Apps: "/system/control/apps",
            Configurations: "/system/control/configurations",
            ConfigurationManager: "/system/control/configurationmanager",
            Verifications: "/system/control/verifications",
            UserSessions: "/system/control/usersessions",
            AdminUsers: "/system/control/adminusers",
            AddAdminUsers: "/system/control/addadminuser",
            AdminUser: {
              Dashboard: "/system/control/adminuser/dashboard",
              Sessions: "/system/control/adminuser/loginhistory",
            },
            WorkHorse: {
              SystemUpdates: "/system/control/workhorse/systemupdates",
              UpdateFeatureRequests:
                "/system/control/workhorse/updatefeaturerequests",
            },
          },

          // Terminal: {
          //   Dashboard: "/system/terminal/dashboard",
          //   RewardHistory: "/system/terminal/rewardhistory",
          //   RewardClaimHistory: "/system/terminal/rewardclaimhistory",
          //   RedeemHistory: "/system/terminal/redeemhistory",
          //   SalesHistory: "/system/terminal/saleshistory",
          // },
          // Live: "/acquirer/live",
          // TerminalLive: "/acquirer/terminallive",
          // Customers: "/acquirer/customers",
          // Customer: "/acquirer/customer/dashboard",
          // SubAccounts: "/acquirer/subaccounts",
          // ReferredMerchants: "/acquirer/referredmerchants",
          // AddTerminal: "/acquirer/addterminal",
          // TerminalsSale: "/acquirer/terminalssale",
          // Store: {
          //   Dashboard: "/acquirer/store/dashboard",
          //   SalesHistory: "/acquirer/store/saleshistory",
          //   Terminals: "/acquirer/store/terminals"
          // },
          // Merchant: {
          //   Dashboard: "/acquirer/merchant/dashboard",
          //   SalesHistory: "/acquirer/merchant/saleshistory",
          //   Stores: "/acquirer/merchant/stores",
          //   Terminals: "/acquirer/merchant/terminals"
          // },
          // Terminal: {
          //   Dashboard: "/acquirer/terminal/dashboard",
          //   SalesHistory: "/acquirer/terminal/saleshistory"
          // },
          // CustomerUpload: "/acquirer/customerupload",
          // CustomerOnboarding: "/acquirer/customeronboarding",
          // MerchantOnboarding: "/acquirer/merchantonboarding",
          // MerchantUpload: "/acquirer/merchantupload",
          // MerchantManager: "/acquirer/merchantmanager",
          // CustomerRewardHistory: "/acquirer/customer/rewardhistory",
          // CustomerRedeemHistory: "/acquirer/customer/redeemhistory",
          // Stores: "/acquirer/stores",
          // AppUser: {
          //   Dashboard: "/acquirer/customer/dashboard",
          //   UpdateStore: "/acquirer/customer/updatestore",
          //   RewardHistory: "/acquirer/customer/rewardhistory",
          //   TUCPlusRewardHistory: "/acquirer/customer/tucplusrewardhistory",
          //   TUCPlusRewardClaimHistory:
          //     "/acquirer/customer/tucplusrewardclaimhistory",
          //   RedeemHistory: "/acquirer/customer/redeemhistory"
          // },
          // Terminals: "/acquirer/terminals",
          // TerminalRewardHistory: "/acquirer/terminal/rewardhistory",
          // TerminalRedeemHistory: "/acquirer/terminal/redeemhistory",
          // Pssp: "/acquirer/gateways",
          // SalesHistory: "/acquirer/saleshistory",
          // // PaymentsHistory: "/acquirer/paymentshistory",
          // TUCPlusRewardHistory: "/acquirer/tucplusrewardhistory",
          // TUCPlusRewardClaimHistory: "/acquirer/tucplusrewardclaimhistory",
          // RewardHistory: "/acquirer/rewardhistory",
          // RedeemHistory: "/acquirer/redeemhistory",
          // DailyCommissionHistory: "/acquirer/dailycommissionhistory",
          // StoreSale: "/acquirer/storesale",
          // Profile: "/acquirer/profile",
          // RewardInvoices: "/acquirer/rewardinvoices",
          // RedeemInvoices: "/acquirer/redeeminvoices"
        },
        Partner: {
          BulkMerchantUpload: "/partner/merchantupload",
          BulkMerchants: "/partner/bulkmerchants",
          BulkCustomers: "/partner/customers",
          Customers: "/partner/allcustomers",
          BulkCustomerUpload: "/partner/bulkcustomerupload",

        },
        PanelAcquirer: {
          SalesHistory: "/partner/saleshistory",
          Activity: "/partner/activity",
          SalesReport: "/partner/salesreport",
          RMReport: "/partner/rmreports",
          SubAccounts: "/partner/subaccounts",
          Terminals: "/partner/terminals",
          Campaigns: {
            Dashboard: "/partner/campaign/dashboard",
            Campaign: "/partner/campaign",
            Campaigns: "/partner/campaigns",
            AddCampaign: "/partner/addcampaign",
            Sales: "/partner/campaign/sales/saleshistory",
          },
          Accounts: {
            Branch: "/partner/branch",
            Rms: "/partner/rms",
            Subadmins: "/partner/subadmins",
          },
          AddAccount: {
            AddRm: "/partner/addrm",
            AddBranch: "/partner/addbranch",
          },
          AccountDetails: {
            BranchDetails: "/partner/branchdetails",
            Rm: "/partner/rm",
            SubAdminDetails: "/partner/subadmindetails",
            Branch: "/partner/branchs",
          },
          Branches: {
            BranchUpdate: "/partner/branchdetails/branchupdate",
            Manager: "/partner/branchdetails/manager",
            EditBranch: "/partner/branchdetails/editbranch",
            BranchTransactions: "/partner/branchdetails/sales",
            BranchTerminals: "/partner/branchdetails/terminals"
          },
          Analytics: {
            Sales: "/partner/salesanalytics",
          },
          Live: {
            Terminals: "/partner/live/terminals",
            Merchants: "/partner/live/merchants",
          },
          Manager: {
            Dashboard: "/partner/manager/dashboard",
            // RewardHistory: "/acquirer/terminal/rewardhistory",
            // RewardClaimHistory: "/acquirer/terminal/rewardclaimhistory",
            // RedeemHistory: "/acquirer/terminal/redeemhistory",
            Terminals: "/partner/manager/terminals",
            Stores: "/partner/branchdetails/stores",
            SalesHistory: "/partner/branchdetails/sales",
          },
          ManagerDetail: {
            Overview: "/partner/manager/dashboard",
            Terminals: "/partner/manager/terminals",
            Stores: "/partner/manager/stores",
            Teams: "/partner/manager/teams",
          },
          Terminal: {
            Dashboard: "/partner/terminal/dashboard",
            // RewardHistory: "/acquirer/terminal/rewardhistory",
            // RewardClaimHistory: "/acquirer/terminal/rewardclaimhistory",
            // RedeemHistory: "/acquirer/terminal/redeemhistory",
            SalesHistory: "/partner/terminal/sales/saleshistory",
          },
          // LiveTerminals: "/acquirer/liveterminals",
          // Live: "/acquirer/live",
          Customers: "/partner/customers",
          Customer: "/partner/customer/sales/saleshistory",
          ReferredMerchants: "/partner/referredmerchants",
          AddTerminal: "/partner/addterminal",
          TerminalsSale: "/partner/terminalssale",
          Store: {
            Dashboard: "/partner/store/dashboard",
            SalesHistory: "/partner/store/saleshistory",
            Terminals: "/partner/store/terminals",
          },
          Merchant: {
            Dashboard: "/partner/merchant/dashboard",
            TopUp: "/partner/merchant/topup",

            // SalesHistory: "/acquirer/merchant/saleshistory",
            Stores: "/partner/merchant/stores",
            Terminals: "/partner/merchant/terminals",
            SalesHistory: "/partner/merchant/sales/saleshistory",
          },

          Merchants: "/partner/merchants",
          CustomerUpload: "/partner/customerupload",
          CustomerOnboarding: "/partner/customeronboarding",
          MerchantOnboarding: "/partner/merchantonboarding",
          MerchantUpload: "/partner/merchantupload",
          MerchantManager: "/partner/merchantmanager",
          CustomerRewardHistory: "/partner/customer/rewardhistory",
          CustomerRedeemHistory: "/partner/customer/redeemhistory",
          Stores: "/partner/stores",
          Temp: "/partner/temp",
          AppUser: {
            Dashboard: "/partner/customer/dashboard",
            UpdateStore: "/partner/customer/updatestore",
            RewardHistory: "/partner/customer/rewardhistory",
            TUCPlusRewardHistory: "/partner/customer/tucplusrewardhistory",
            TUCPlusRewardClaimHistory:
              "/partner/customer/tucplusrewardclaimhistory",
            RedeemHistory: "/partner/customer/redeemhistory",
          },
          TerminalRewardHistory: "/partner/terminal/rewardhistory",
          TerminalRedeemHistory: "/partner/terminal/redeemhistory",
          Pssp: "/partner/gateways",
          // PaymentsHistory: "/acquirer/paymentshistory",
          TUCPlusRewardHistory: "/partner/tucplusrewardhistory",
          TUCPlusRewardClaimHistory: "/partner/tucplusrewardclaimhistory",
          RewardHistory: "/partner/rewardhistory",
          RedeemHistory: "/partner/redeemhistory",

          CommissionHistory: "/partner/commissionhistory",
          DailyCommissionHistory: "/partner/dailycommissionhistory",
          StoreSale: "/partner/storesale",
          Profile: "/partner/profile",

          RewardInvoices: "/partner/rewardinvoices",
          RedeemInvoices: "/partner/redeeminvoices",
        },

        PanelRm: {
          Live: {
            Terminals: "/rm/live/terminals",
          },
          Accounts: {
            Terminals: "/rm/terminals",
            Stores: "/rm/stores",
          },
        },
        PanelMerchant: {
          Invoices: "/m/invoices",
          Invoice: "/m/invoice",
          Cashiers: "/m/cashiers",
          Cashier: "/m/cashier",
          Managers: "/m/managers",
          Stores: "/m/stores",
          Terminals: "/m/terminals",
          Customers: "/m/customers",
          GiftPoints: "/m/giftpoints",
          GiftCards: "/m/giftcards",
          Products: "/m/products",
          Downloads: "/m/downloads",
          Profile: "/m/profile",
          Analytics: {
            StoresTucSummary: "/m/analytics/storestucsummary",
            Live: "/m/live",
            TerminalLive: "/m/analytics/terminalstracker",
            DailySales: "/m/analytics/dailysales",
            StoreBankCollection: "/m/analytics/bankcollections",
          },
          Transactions: {
            Sales: "/m/transactions/saleshistory",
            Rewards: "/m/transactions/rewards",
            RewardClaims: "/m/transactions/rewardclaims",
            Redeem: "/m/transactions/redeem",
          },
          Store: {
            Dashboard: "/m/store/dashboard",
            Terminals: "/m/store/terminals",
            RewardHistory: "/m/store/transactions/rewardhistory",
            RewardClaimHistory: "/m/store/transactions/rewardclaimhistory",
            RedeemHistory: "/m/store/transactions/redeemhistory",
            SalesHistory: "/m/store/sales/salehistory",
            // UpdateStore: "/merchant/store/updatestore",
            // RewardHistory: "/merchant/store/rewardhistory",
            // TUCPlusRewardHistory: "/merchant/store/tucplusrewardhistory",
            // TUCPlusRewardClaimHistory:"/merchant/store/tucplusrewardclaimhistory",
            // RedeemHistory: "/merchant/store/redeemhistory"
          },
          Terminal: {
            Dashboard: "/m/terminal/dashboard",
            RewardHistory: "/m/terminal/transactions/rewardhistory",
            RewardClaimHistory: "/m/terminal/transactions/rewardclaimhistory",
            RedeemHistory: "/m/terminal/transactions/redeemhistory",
            SalesHistory: "/m/terminal/sales/salehistory",
          },
          Customer: {
            Dashboard: "/m/customer/dashboard",
            RewardHistory: "/m/customer/transactions/rewardhistory",
            RewardClaimHistory: "/m/customer/transactions/rewardclaimhistory",
            RedeemHistory: "/m/customer/transactions/redeemhistory",
            SalesHistory: "/m/customer/sales/salehistory",
          },
          // Customer: "/merchant/customer/dashboard",
          SubAccounts: "/merchant/subaccounts",
          RewardHistory: "/merchant/rewardhistory",
          RewardClaimHistory: "/merchant/rewardclaimhistory",
          RedeemHistory: "/merchant/redeemhistory",
          SalesHistory: "/merchant/saleshistory",
          AppUser: {
            Dashboard: "/merchant/customer/dashboard",
            RewardHistory: "/merchant/customer/rewardhistory",
            RewardClaimHistory: "/merchant/customer/rewardclaimhistory",
            RedeemHistory: "/merchant/customer/redeemhistory",
            SalesHistory: "/merchant/customer/saleshistory",
            // Dashboard: "/merchant/customer/dashboard",
            // UpdateStore: "/merchant/customer/updatestore",
            // RewardHistory: "/merchant/customer/rewardhistory",
            // TUCPlusRewardHistory: "/merchant/customer/tucplusrewardhistory",
            // TUCPlusRewardClaimHistory:
            //   "/merchant/customer/tucplusrewardclaimhistory",
            // RedeemHistory: "/merchant/customer/redeemhistory"
          },
          Pssp: "/merchant/gateways",
          CommissionHistory: "/merchant/commissionhistory",
          StoreSale: "/merchant/storesale",
          // Profile: "/merchant/profile",
          RewardInvoices: "/merchant/rewardinvoices",
          RedeemInvoices: "/merchant/redeeminvoices",
        },
        PanelMerchantRewards: {
          Invoices: "/mr/invoices",
          Invoice: "/mr/invoice",
          Cashiers: "/mr/cashiers",
          Cashier: "/mr/cashier",
          Managers: "/mr/managers",

          Transactions: {
            Sales: "/mr/transactions/saleshistory",
          },
          Stores: "/mr/stores",
          Store: {
            Dashboard: "/merchant/store/dashboard",
            Terminals: "/merchant/store/terminals",
            RewardHistory: "/merchant/store/rewardhistory",
            RewardClaimHistory: "/merchant/store/rewardclaimhistory",
            RedeemHistory: "/merchant/store/redeemhistory",
            SalesHistory: "/merchant/store/saleshistory",
            // UpdateStore: "/merchant/store/updatestore",
            // RewardHistory: "/merchant/store/rewardhistory",
            // TUCPlusRewardHistory: "/merchant/store/tucplusrewardhistory",
            // TUCPlusRewardClaimHistory:"/merchant/store/tucplusrewardclaimhistory",
            // RedeemHistory: "/merchant/store/redeemhistory"
          },

          Terminals: "/m/terminals",
          Terminal: {
            Dashboard: "/merchant/terminal/dashboard",
            RewardHistory: "/merchant/terminal/rewardhistory",
            RewardClaimHistory: "/merchant/terminal/rewardclaimhistory",
            RedeemHistory: "/merchant/terminal/redeemhistory",
            SalesHistory: "/merchant/terminal/saleshistory",
          },

          Live: "/merchant/live",
          TerminalLive: "/merchant/terminallive",
          Customers: "/merchant/customers",
          Customer: "/merchant/customer/dashboard",
          SubAccounts: "/merchant/subaccounts",

          RewardHistory: "/merchant/rewardhistory",
          RewardClaimHistory: "/merchant/rewardclaimhistory",
          RedeemHistory: "/merchant/redeemhistory",

          SalesHistory: "/merchant/saleshistory",
          StoreBankCollection: "/merchant/storebankcollection",

          AppUser: {
            Dashboard: "/merchant/customer/dashboard",
            RewardHistory: "/merchant/customer/rewardhistory",
            RewardClaimHistory: "/merchant/customer/rewardclaimhistory",
            RedeemHistory: "/merchant/customer/redeemhistory",
            SalesHistory: "/merchant/customer/saleshistory",

            // Dashboard: "/merchant/customer/dashboard",
            // UpdateStore: "/merchant/customer/updatestore",
            // RewardHistory: "/merchant/customer/rewardhistory",
            // TUCPlusRewardHistory: "/merchant/customer/tucplusrewardhistory",
            // TUCPlusRewardClaimHistory:
            //   "/merchant/customer/tucplusrewardclaimhistory",
            // RedeemHistory: "/merchant/customer/redeemhistory"
          },

          Pssp: "/merchant/gateways",

          CommissionHistory: "/merchant/commissionhistory",
          StoreSale: "/merchant/storesale",
          Profile: "/merchant/profile",

          RewardInvoices: "/merchant/rewardinvoices",
          RedeemInvoices: "/merchant/redeeminvoices",
        },
        Customers: "/system/customers",
        AppUsers: "/system/appusers",
        AppUser: {
          Dashboard: "/system/appuser/dashboard",
        },
        AddMerchant: "/system/addmerchant",
        Merchants: "/system/merchants",
        Merchant: {
          Dashboard: "/system/merchant/dashboard",
        },
        Stores: "/system/stores",
        AddStore: "/system/addstore",
        Store: {
          Dashboard: "/system/store/dashboard",
          UpdateStore: "/system/store/updatestore",
        },
        AddCashier: "/system/addcashier",
        Cashiers: "/system/cashiers",
        Cashier: {
          Dashboard: "/system/cashier/dashboard",
        },
        AddAcquirer: "/system/addacquirer",
        Acquirers: "/system/acquirers",
        Acquirer: {
          Dashboard: "/system/partner/dashboard",
        },
        AddPgAccount: "/system/addpgaccount",
        PgAccounts: "/system/pgaccounts",
        PgAccount: {
          Dashboard: "/system/pgaccount/dashboard",
        },
        AddPosAccount: "/system/addposaccount",
        PosAccounts: "/system/posaccounts",
        PosAccount: {
          Dashboard: "/provider/terminals",
        },
        AddPosTerminal: "/system/addposterminal",
        PosTerminals: "/system/posterminals",
        PosTerminal: {
          Dashboard: "/system/posterminal/dashboard",
        },
        Transactions: {
          Reward: "/system/transactions/reward",
          Redeem: "/system/transactions/redeem",
          Change: "/system/transactions/change",
          Gift: "/system/transactions/gift",
          Payments: "/system/transactions/payment",
        },
      },

      System: {
        NotFound: "/system/notfound",
        Login: "/account/login",
        MerchantSetup: "/account/setup",
        MerchantSetupComplete: "/account/setupcomplete",
        MerchantRewardSetup: "/account/reward",
        ForgotPassword: "/account/forgotpassword",
        ResetPassword: "/account/resetpassword",
        Dashboard: "/system/dashboard",

        Roles: "/system/roles",
        Features: "/system/features",
        Categories: "/system/categories",
        ResponseCodes: "/system/responsecodes",
        Log: "/system/log",
        Apps: "/system/apps",
        CoreUsage: "/system/usage",
        Configurations: "/system/configurations",
        Profile: "/system/profile",
        CoreTransactions: "/system/coretransactions",
        CoreUsers: "/system/coreusers",
        EditCoreUser: "/system/editcoreuser",
        AddCoreHelpers: "/system/addcorehelpers",
        EditCoreHelpers: "/system/editcorehelpers",
        CoreHelpers: "/system/corehelpers",
        CoreCommons: "/system/corecommons",
        CoreParameters: "/system/coreparameters",
      },
    },
    CommonResource: {
      SaveFilterTitle: "Save This Filter View",
      SaveFilterHelp: "Enter Filter Name",
      LogoutTitle: "Are You Sure You want to Logout?",
      ReferenceId: "Reference Id",
      ReferenceKey: " Reference Key",
      ReferenceCode: " Reference Code",
      SystemName: " Reference Code",
      Logout: "Logout",
      Name: "Name",
      Select: "Select",
      Cancel: "Cancel",
      Continue: "Continue",
      Status: "Status",
      CreateDate: "Added On",
      CreatedBy: "Added By",
      ModifyDate: "Updated On",
      ModifyBy: "Updated By",
      AccessPin: "4 digit pin",
      FilterName: "filter name",
      UpdateTitle: "Update details ?",
      UpdateHelp: "Enter 4 digit pin to update details",
      DeleteTitle: "Delete View ?",
      ResetKey: "Reset Key",
      ResetKeyHelp:
        "Press continue to reset app keys?",
      DeleteHelp:
        "View can not be recovered once deleted. Do you want to continue ?",
      InvalidImage: "Invalid image dimension. Please select valid image",
    },
    FilterStates: {
      Default: 'default',
      NoChange: 'nochange',
      Modified: 'modified'
    }
  };
  public darkStyle = [];
  public CheckMode: string = 'dark';
  private history: string[] = []
  public _Assets = {
    Box: {
      root: "card shadow-none",
      header:
        "card-header d-flex pd-y-15  align-items-center justify-content-between",
      headerTitle: "h6  m-0 mt-1 mb-1",
      filterIcons: "tx-12",
      headerTitleRight: "d-flex",
      headerTitleRightItem: "link-03 lh-0 mg-l-10",
      bodyFilter: "card-body pd-y-10",
      bodyContent: "card-body p-0",
      ShowIcon:
        "wd-35 ht-35 align-items-center justify-content-center op-6 d-none d-sm-flex",
      TextCenter: "text-center",
    },
  };

  constructor(
    public _Location: Location,
    public _Router: Router,
    public _TranslateService: TranslateService,
    public _Ng2FileInputService: Ng2FileInputService,
    private _Http: HttpClient
  ) {
    this.FullContainer = false;
    this.ContainerHeight = window.innerHeight - 56;
    var UserTimeZone = SystemHelper.GetUserTimeZone();
    if (UserTimeZone != undefined && UserTimeZone != null) {
      this.AppConfig.TimeZone = UserTimeZone;
    } else {
      this.AppConfig.TimeZone = "Asia/Calcutta";
    }
    this._TranslateService
      .get("Common.ReferenceId")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.ReferenceId = _Message;
      });
    this._TranslateService
      .get("Common.InvalidImage")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.ReferenceId = _Message;
      });

    this._TranslateService
      .get("Common.ReferenceCode")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.ReferenceCode = _Message;
      });

    this._TranslateService
      .get("Common.ReferenceKey")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.ReferenceKey = _Message;
      });

    this._TranslateService.get("Common.Name").subscribe((_Message: string) => {
      this.AppConfig.CommonResource.Name = _Message;
    });

    this._TranslateService
      .get("Common.Select")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.Select = _Message;
      });

    this._TranslateService
      .get("Common.Cancel")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.Cancel = _Message;
      });
    this._TranslateService
      .get("Common.Continue")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.Continue = _Message;
      });

    this._TranslateService
      .get("Common.Status")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.Status = _Message;
      });

    this._TranslateService
      .get("Common.CreateDate")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.CreateDate = _Message;
      });

    this._TranslateService
      .get("Common.CreatedBy")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.CreatedBy = _Message;
      });
    this._TranslateService
      .get("Common.ModifyDate")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.ModifyDate = _Message;
      });
    this._TranslateService
      .get("Common.ModifyBy")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.ModifyBy = _Message;
      });
    this._TranslateService
      .get("Common.DeleteTitle")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.DeleteTitle = _Message;
      });
    this._TranslateService
      .get("Common.DeleteHelp")
      .subscribe((_Message: string) => {
        this.AppConfig.CommonResource.DeleteHelp = _Message;
      });
    this.RefreshHelper();



    this._Router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }
  RefreshHelper() {
    this.AppConfig.Client = this.GetStorage(this.AppConfig.Storage.OReqH);
    this.AppConfig.Host = window.location.host;
    // console.log("AppConfig.Host = ",this.AppConfig.Host)
    if (this.AppConfig.Client != null) {
      this.AppConfig.ClientHeader = {
        "Content-Type": "application/json; charset=utf-8",
        hcak: this.AppConfig.Client.hcak,
        hcavk: this.AppConfig.Client.hcavk,
        hctk: null,
        hcudlt: this.AppConfig.Client.hcudlt,
        hcudln: this.AppConfig.Client.hcudln,
        hcuak: this.AppConfig.Client.hcuak,
        hcupk: this.AppConfig.Client.hcupk,
        hcudk: this.AppConfig.Client.hcudk,
        hcuata: "",
        hcuatp: "",
      };
    }
    if (
      this.AppConfig.Host == "interswitch.thankucash.com" ||
      this.AppConfig.Host == "app.thankucash.com" ||
      this.AppConfig.Host == "console.thankucash.com" ||
      this.AppConfig.Host == "betaconsole.thankucash.com" ||
      this.AppConfig.Host == "acquirer.thankucash.com" ||
      this.AppConfig.Host == "merchant.thankucash.com" ||
      this.AppConfig.Host == "provider.thankucash.com" ||
      this.AppConfig.Host == "ptsp.thankucash.com" ||
      this.AppConfig.Host == "pssp.thankucash.com" ||
      this.AppConfig.Host == "partners.thankucash.com" ||
      this.AppConfig.Host == "partner.thankucash.com"

    ) {
      this.AppConfig.HostConnect = "https://webconnect.thankucash.com/";
    } else if (
      this.AppConfig.Host == "playapp.thankucash.com" ||
      this.AppConfig.Host == "playacquirer.thankucash.com" ||
      this.AppConfig.Host == "playmerchant.thankucash.com" ||
      this.AppConfig.Host == "playprovider.thankucash.com" ||
      this.AppConfig.Host == "playptsp.thankucash.com" ||
      this.AppConfig.Host == "playpssp.thankucash.com"
    ) {
      this.AppConfig.HostConnect = "https://playwebconnect.thankucash.com/";
    } else if (
      this.AppConfig.Host == "testapp.thankucash.com" ||
      this.AppConfig.Host == "testacquirer.thankucash.com" ||
      this.AppConfig.Host == "testmerchant.thankucash.com" ||
      this.AppConfig.Host == "testprovider.thankucash.com" ||
      this.AppConfig.Host == "testconsole.thankucash.com" ||
      this.AppConfig.Host == "testptsp.thankucash.com" ||
      this.AppConfig.Host == "testpssp.thankucash.com" ||
      this.AppConfig.Host == "testpartners.thankucash.com" ||
      this.AppConfig.Host == "testpartner.thankucash.com"
    ) {
      this.AppConfig.HostConnect = "https://testwebconnect.thankucash.com/";
    } else if (this.AppConfig.Host == "localhost:4204") {
      // } else if (this.AppConfig.Host == "localhost:4444") {
      this.AppConfig.HostConnect = "https://testwebconnect.thankucash.com/";
      // this.AppConfig.HostConnect = "https://localhost:5001/";
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.com/";
    }
    else if (
      this.AppConfig.Host == 'partner.thankucash.dev' ||
      this.AppConfig.Host ==
      'partner.thankucash.dev.s3-website.eu-west-2.amazonaws.com'
    ) {
      this.AppConfig.HostConnect = 'https://webconnect.thankucash.dev/';
    } else if (this.AppConfig.Host == 'localhost:4200') {
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.com/";
      this.AppConfig.HostConnect = "https://localhost:5001/";
      // this.AppConfig.HostConnect = 'https://testwebconnect.thankucash.com/';
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.com/";
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.com/";
      // this.AppConfig.HostConnect = "https://testwebconnect.thankucash.com/";
    } else if (this.AppConfig.Host == 'http://127.0.0.1:5501') {
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.com/";
      //  this.AppConfig.HostConnect = "https://localhost:5001/";
      this.AppConfig.HostConnect = 'https://testwebconnect.thankucash.com/';
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.com/";
      // this.AppConfig.HostConnect = "https://webconnect.thankucash.com/";
      // this.AppConfig.HostConnect = "https://testwebconnect.thankucash.com/";
    }
    else if (this.AppConfig.Host == 'partner.thankucash.tech') {
      this.AppConfig.HostConnect = 'https://webconnect.thankucash.tech/';
    }
    else if (this.AppConfig.Host == 'partner.thankucash.co') {
      this.AppConfig.HostConnect = 'https://webconnect.thankucash.co/';
    }
    else {
      this.AppConfig.HostConnect = '';
    }
    this.Select2Options_Multiple = {
      multiple: true,
      placeholder: "Select",
    };
    this.S2_Sort_Option = {
      multiple: false,
      placeholder: "Sort Results",
    };

    this.Select2Options_Single = {
      multiple: false,
      placeholder: "Sort Results",
    };
    this.Select2Options_SearchColumn = {
      multiple: true,
      placeholder: "Search On Fields",
    };
    var UserAccountInformation = this.GetStorage(
      this.AppConfig.Storage.Account
    );
    if (UserAccountInformation != null) {
      this.AccessKey = UserAccountInformation.AccessKey;
      this.PublicKey = UserAccountInformation.PublicKey;
      this.LoginTime = UserAccountInformation.LoginTime;
      this.User = UserAccountInformation.User;
      this.UserAccount = UserAccountInformation.UserAccount;
      this.UserOwner = UserAccountInformation.UserOwner;
      this.UserCountry = UserAccountInformation.UserCountry;
      this.UserRoles = UserAccountInformation.UserRoles;

      if (
        this.UserAccount.AccountTypeCode == this.AppConfig.AccountType.Store
      ) {
        this.AppConfig.ActiveOwnerKey = this.UserAccount.AccountKey;
        this.AppConfig.ActiveOwnerId = this.UserAccount.AccountId;
        this.AppConfig.ActiveOwnerDisplayName = this.UserAccount.DisplayName;
        this.AppConfig.ActiveOwnerAccountCode = this.UserAccount.AccountCode;
        this.AppConfig.ActiveOwnerAccountTypeCode = this.UserAccount.AccountTypeCode;
        this.AppConfig.ActiveOwnerIsTucPlusEnabled = this.UserOwner.IsTucPlusEnabled;
      } else if (
        this.UserAccount.AccountTypeCode ==
        this.AppConfig.AccountType.MerchantSubAccount
      ) {
        this.AppConfig.ActiveOwnerKey = this.UserOwner.AccountKey;
        this.AppConfig.ActiveOwnerId = this.UserOwner.AccountId;
        this.AppConfig.ActiveOwnerDisplayName = this.UserOwner.DisplayName;
        this.AppConfig.ActiveOwnerAccountCode = this.UserOwner.AccountCode;
        this.AppConfig.ActiveOwnerAccountTypeCode = this.UserOwner.AccountTypeCode;
        this.AppConfig.ActiveOwnerIsTucPlusEnabled = this.UserOwner.IsTucPlusEnabled;
      } else if (
        this.UserAccount.AccountTypeCode ==
        this.AppConfig.AccountType.AcquirerSubAccount
      ) {
        this.AppConfig.ActiveOwnerKey = this.UserOwner.AccountKey;
        this.AppConfig.ActiveOwnerId = this.UserOwner.AccountId;
        this.AppConfig.ActiveOwnerDisplayName = this.UserOwner.DisplayName;
        this.AppConfig.ActiveOwnerAccountCode = this.UserOwner.AccountCode;
        this.AppConfig.ActiveOwnerAccountTypeCode = this.UserOwner.AccountTypeCode;
        this.AppConfig.ActiveOwnerIsTucPlusEnabled = this.UserOwner.IsTucPlusEnabled;
      } else {
        this.AppConfig.ActiveOwnerKey = this.UserAccount.AccountKey;
        this.AppConfig.ActiveOwnerId = this.UserAccount.AccountId;
        this.AppConfig.ActiveOwnerDisplayName = this.UserAccount.DisplayName;
        this.AppConfig.ActiveOwnerAccountCode = this.UserAccount.AccountCode;
        this.AppConfig.ActiveOwnerAccountTypeCode = this.UserAccount.AccountTypeCode;
        this.AppConfig.ActiveOwnerIsTucPlusEnabled = this.UserAccount.IsTucPlusEnabled;
      }
      if (UserAccountInformation.UserCountry.CountryIso == 'ng') {
        this.AppConfig.CurrencySymbol = this.UserCountry.CurrencyCode;
        this.AppConfig.CurrencySymbolCustom = this.UserCountry.CurrencyCode;
        this.CountryCode = '+' + UserAccountInformation.UserCountry.CountryIsd;

        this.UserCountrycode = UserAccountInformation.UserCountry.CountryName;
        this.UserCountrykey = UserAccountInformation.UserCountry.CountryKey
        this.UserCountryId = UserAccountInformation.UserCountry.CountryId
        this.AppConfig.TimeZone = 'Africa/Lagos';

      }
      else if (UserAccountInformation.UserCountry.CountryIso == 'GH') {
        this.AppConfig.CurrencySymbolCustom = "&#8373;";
        this.AppConfig.CurrencySymbol = "₵"
        this.CountryCode = '+' + UserAccountInformation.UserCountry.CountryIsd;

        this.UserCountrycode = UserAccountInformation.UserCountry.CountryName;
        this.UserCountrykey = UserAccountInformation.UserCountry.CountryKey
        this.UserCountryId = UserAccountInformation.UserCountry.CountryId;
        this.AppConfig.TimeZone = 'Africa/Accra';

      }
      else {
        this.AppConfig.CurrencySymbolCustom = "KSh";
        this.AppConfig.CurrencySymbol = "Ksh"
        this.CountryCode = '+' + UserAccountInformation.UserCountry.CountryIsd;

        this.UserCountrycode = UserAccountInformation.UserCountry.CountryName;
        this.UserCountrykey = UserAccountInformation.UserCountry.CountryKey
        this.UserCountryId = UserAccountInformation.UserCountry.CountryId;
        this.AppConfig.TimeZone = 'Africa/Nairobi';
      }
      if (this.UserRoles != undefined) {
        this.UserRoles.forEach((_UserRole) => {
          if (_UserRole.RolePermissions.length > 0) {
            _UserRole.RolePermissions.forEach((_Permission) => {
              this.UserRolePermissions.push(_Permission);
            });
          }
        });
      }
    }
    var UserLocationInformation = this.GetStorage(
      this.AppConfig.Storage.Location
    );
    if (UserLocationInformation != null) {
      this.UserLocation = UserLocationInformation;
    }
    var UserDeviceInformation = this.GetStorage(this.AppConfig.Storage.Device);
    if (UserDeviceInformation != null) {
      this.UserDevice = UserDeviceInformation;
    }
  }

  // ProcessLogout() {

  //   swal({
  //     position: "center",
  //     title: this.AppConfig.CommonResource.LogoutTitle,
  //     text: "Click on Logout button to confirm",
  //     animation: false,
  //     customClass: this.AppConfig.Alert_Animation,
  //     showCancelButton: true,
  //     confirmButtonColor: this.AppConfig.Color_Red,
  //     cancelButtonColor: this.AppConfig.Color_Grey,
  //     confirmButtonText: this.AppConfig.CommonResource.Logout,
  //     cancelButtonText: this.AppConfig.CommonResource.Cancel

  //   }).then((result) => {
  //     if (result.value) {
  //       var pData = {
  //         Task: this.AppConfig.Api.Logout
  //       };
  //       let _OResponse: Observable<OResponse>;
  //       _OResponse = this.PostData(this.AppConfig.NetworkLocation.V1.System, pData);
  //       _OResponse.subscribe(
  //         _Response => {
  //           if (_Response.Status == this.StatusSuccess) {
  //             this.NotifySuccess(_Response.Message);
  //           }
  //           else {
  //             this.NotifyError(_Response.Message);
  //           }
  //         },
  //         _Error => {
  //           console.log(_Error);
  //         });
  //       this.DeleteStorage(this.AppConfig.Storage.Account);
  //       this.DeleteStorage(this.AppConfig.Storage.Location);
  //       this.DeleteStorage(this.AppConfig.Storage.OReqH);
  //       this.DeleteStorage(this.AppConfig.Storage.Permissions);
  //       this._Router.navigate([this.AppConfig.Pages.System.Login]);
  //     }
  //     else { }
  //   });



  // }



  ProcessLogout() {

    swal({
      position: "center",
      title: this.AppConfig.CommonResource.LogoutTitle,
      text: "Click on Logout button to confirm",
      animation: false,
      customClass: this.AppConfig.Alert_Animation,
      showCancelButton: true,
      confirmButtonColor: this.AppConfig.Color_Red,
      cancelButtonColor: this.AppConfig.Color_Grey,
      confirmButtonText: this.AppConfig.CommonResource.Logout,
      cancelButtonText: this.AppConfig.CommonResource.Cancel

    }).then((result) => {
      if (result.value) {
        var pData = {
          Task: this.AppConfig.Api.Logout
        };
        let _OResponse: Observable<OResponse>;
        _OResponse = this.PostData(this.AppConfig.NetworkLocation.V1.System, pData);
        _OResponse.subscribe(
          _Response => {
            if (_Response.Status == this.StatusSuccess) {
              this.NotifySuccess(_Response.Message);
            }
            else {
              this.NotifyError(_Response.Message);
            }
          },
          _Error => {
            console.log(_Error);
          });
        this.DeleteStorage(this.AppConfig.Storage.Account);
        this.DeleteStorage(this.AppConfig.Storage.Location);
        this.DeleteStorage(this.AppConfig.Storage.OReqH);
        this.DeleteStorage(this.AppConfig.Storage.Permissions);
        this._Router.navigate([this.AppConfig.Pages.System.Login]);
      }
      else { }
    });



  }

  NavigateBack() {
    // this.canActivate();
    // this._Location.back();
    if (window.location.pathname == '/partner/dashboard') {
      this.ProcessLogout()
    }
    else {
      this._Location.back();

    }

    // if (this.AppConfig.Pages.System.Dashboard) {
    //  alert("back")
    // } else {
    //  // this._Router.navigate([this.AppConfig.Pages.System.Login]);
    //   this._Location.back();
    // }




    // this.history.pop()
    // console.log(this.history.length)
    // if (this.history.length > 0) {
    //   this._Location.back()
    // } else {
    //   alert("logout")
    // }

  }
  ToggleAsidePanel() {
    if (this.ShowAsidePanel == false) {
      this.ShowAsidePanel = true;
    } else {
      this.ShowAsidePanel = false;
    }
  }
  public _FileSelect_Poster: string = this.AppConfig.Storage.ImageStoragePoster;
  _FileSelect_Poster_Data: OStorageContent = {
    Name: null,
    Content: null,
    Extension: null,
    TypeCode: null,
    Height: 400,
    Width: 800,
  };
  _FileSelect_Poster_Processing = false;
  // public _FileSelect_Poster_onAdded(event: any) {
  //   var _FileName: string = event.file.name;
  //   var _File: File = event.file;
  //   var _FileReader: FileReader = new FileReader();
  //   _FileReader.onloadend = e => {
  //     var _FileContent = _FileReader.result;
  //     this._FileSelect_Poster_Processing = true;
  //     var _Image = new Image();
  //     _Image.src = _FileContent.toString();
  //     _Image.onload = () => {
  //       this._FileSelect_Poster_Data.Width = _Image.width;
  //       this._FileSelect_Poster_Data.Height = _Image.height;
  //       if (
  //         _Image.width == this._FileSelect_Poster_Data.Width &&
  //         _Image.height == this._FileSelect_Poster_Data.Height
  //       ) {
  //         var FileSelect_Icon_Data = this.GetImageDetails(
  //           _FileContent
  //         ) as OStorageContent;
  //         this._FileSelect_Poster_Data.Content = FileSelect_Icon_Data.Content;
  //         this._FileSelect_Poster_Data.Extension =
  //           FileSelect_Icon_Data.Extension;
  //         this._FileSelect_Poster_Data.Name = _FileName;
  //         this._FileSelect_Poster_Data.Width = _Image.width;
  //         this._FileSelect_Poster_Data.Height = _Image.height;
  //       } else {
  //         this.NotifyWarning(this.AppConfig.CommonResource.InvalidImage);
  //         this._FileSelect_Poster_Reset();
  //       }
  //     };
  //   };
  //   _FileReader.readAsDataURL(_File);
  // }
  public _FileSelect_Poster_Reset(): void {
    this._FileSelect_Poster_Data.Name = null;
    this._FileSelect_Poster_Data.Content = null;
    this._FileSelect_Poster_Data.Extension = null;
    // this._FileSelect_Poster_Data.Height = 0;
    // this._FileSelect_Poster_Data.Width = 0;
    if (this._FileSelect_Poster_Data.Content != null) {
      this._Ng2FileInputService.reset(this._FileSelect_Poster);
    }
  }

  _Icon_Cropper_ChangedEvent: any = "";
  _Icon_Cropper_Image: any = "";
  _Icon_Cropper_Data: OStorageContent = {
    Name: null,
    Content: null,
    Extension: null,
    TypeCode: null,
    Height: 400,
    Width: 800,
  };
  Icon_Change(event: any): void {
    this._Icon_Cropper_ChangedEvent = event;
  }
  Icon_Cropped(event: ImageCroppedEvent) {
    this._Icon_Cropper_Image = event.base64;
    var ImageDetails = this.GetImageDetails(this._Icon_Cropper_Image);
    this._Icon_Cropper_Data.Content = ImageDetails.Content;
    this._Icon_Cropper_Data.Extension = ImageDetails.Extension;
  }
  Icon_Crop_Failed() {
    this.NotifyWarning(
      "Unable to load image. Please make sure valid image selected or refresh page and try again"
    );
  }
  Icon_Crop_Loaded() {
    this.OpenModal("_Icon_Cropper_Modal");
  }
  Icon_Crop_Clear() {
    this._Icon_Cropper_ChangedEvent = "";
    this._Icon_Cropper_Image = "";
    this._Icon_Cropper_Data.Name = null;
    this._Icon_Cropper_Data.Content = null;
    this._Icon_Cropper_Data.Extension = null;
    this._Icon_Cropper_Data.TypeCode = null;
    this.CloseModal("_Icon_Cropper_Modal");
  }
  _Poster_Cropper_ChangedEvent: any = "";
  _Poster_Cropper_Image: any = "";
  _Poster_Cropper_Data: OStorageContent = {
    Name: null,
    Content: null,
    Extension: null,
    TypeCode: null,
    Height: 450,
    Width: 800,
  };
  Poster_Change(event: any): void {
    this._Poster_Cropper_ChangedEvent = event;
  }
  Poster_Cropped(event: ImageCroppedEvent) {
    this._Poster_Cropper_Image = event.base64;
    var ImageDetails = this.GetImageDetails(this._Poster_Cropper_Image);
    this._Poster_Cropper_Data.Content = ImageDetails.Content;
    this._Poster_Cropper_Data.Extension = ImageDetails.Extension;
  }
  Poster_Crop_Failed() {
    this.NotifyWarning(
      "Unable to load image. Please make sure valid image selected or refresh page and try again"
    );
  }
  Poster_Crop_Loaded() {
    this.OpenModal("_Poster_Cropper_Modal");
  }
  Poster_Crop_Clear() {
    this._Icon_Cropper_ChangedEvent;
    this._Poster_Cropper_ChangedEvent = "";
    this._Poster_Cropper_Image = "";
    this._Poster_Cropper_Data.Name = null;
    this._Poster_Cropper_Data.Content = null;
    this._Poster_Cropper_Data.Extension = null;
    this._Poster_Cropper_Data.TypeCode = null;
    this.CloseModal("_Poster_Cropper_Modal");
  }

  //#region DateRange
  RangeAltered: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public DateFilterRange: string;

  public DateRangeStartO: any;
  public DateRangeEndO: any;

  public DateRangeStart: any;
  public DateRangeEnd: any;

  public ShowDateRange: boolean;

  public ToogleRange(val: string): void {
    this.DateFilterRange = val;

    var isStartNotExist: boolean =
      this.DateRangeStart == undefined && this.DateRangeStart == null;
    var isEndNotExist: boolean =
      this.DateRangeEnd == undefined && this.DateRangeEnd == null;

    if (isStartNotExist || isEndNotExist) {
      this.DateRangeStart = moment().startOf("day");
      this.DateRangeEnd = moment().endOf("day");

      this.DateRangeStartO = moment().startOf("day");
      this.DateRangeEndO = moment().endOf("day");
    }
    switch (this.DateFilterRange) {
      case "A":
        {
          this.DateRangeStart = moment(this.DateRangeStartO)
            .startOf("month")
            .startOf("day");
          this.DateRangeEnd = moment(this.DateRangeEndO)
            .endOf("month")
            .endOf("day");
        }

        break;
      case "B":
        {
          this.DateRangeStart = moment(this.DateRangeStartO)
            .startOf("week")
            .startOf("day");
          this.DateRangeEnd = moment(this.DateRangeEndO)
            .endOf("week")
            .endOf("day");
        }

        break;
      case "C":
        {
          this.DateRangeStart = moment(this.DateRangeStartO);
          this.DateRangeEnd = moment(this.DateRangeEndO);
        }

        break;

      default:
        {
          this.DateRangeStart = moment(this.DateRangeStartO);
          this.DateRangeEnd = moment(this.DateRangeEndO);
        }
        break;
    }



    this.RangeAltered.next(true);
  }

  public ResetDateRange(): void {
    this.DateFilterRange = "C";
    this.DateRangeStart = moment().startOf("day");
    this.DateRangeEnd = moment().endOf("day");

    this.DateRangeStartO = moment().startOf("day");
    this.DateRangeEndO = moment().endOf("day");
  }

  public SetDateRange(event: any): void {
    var StartTime = moment(event.start).startOf("day");
    var EndTime = moment(event.end).endOf("day");

    switch (this.DateFilterRange) {
      case "A":
        {
          StartTime = StartTime.startOf("month");
          EndTime = EndTime.endOf("month");
        }

        break;
      case "B":
        {
          StartTime = StartTime.startOf("week");
          EndTime = EndTime.endOf("week");
        }

        break;
      case "C":
        {
          StartTime = StartTime;
          EndTime = EndTime;
        }

        break;

      default:
        {
          StartTime = StartTime;
          EndTime = EndTime;
        }
        break;
    }

    this.DateRangeStart = StartTime;
    this.DateRangeEnd = EndTime;

    this.DateRangeStartO = moment(event.start).startOf("day");
    this.DateRangeEndO = moment(event.end).endOf("day");

    this.RangeAltered.next(true);

  }

  //#endregion

  //#region PermanentFilters 

  FilterState: string;

  Active_FilterOptions: any[];
  FilterSnap: any;
  FilterSnapTemprary: any;
  FilterSnapPrestine: any;
  FilterSnapPrev: any = {};
  CleanConfig: any;
  CurrentType: string;



  Active_FilterInit(filterType: string, InitializeConfig: any): void {
    this.CurrentType = filterType;
    this.CleanConfig = cloneDeep(InitializeConfig);
    this.CleanConfig.OtherFilters = [];
    this.CleanConfig.SalesRange = {
      SalesMin: 0,
      SalesMax: 10000000,
      RewardMin: 0,
      RewardMax: 10000000,
      RedeemMin: 0,
      RedeemMax: 10000000
    };
    if (this.CleanConfig.Sort) {
      this.CleanConfig.Sort.SortColumn = null;
    }

    this.Active_FilterOptions = [];

    //#region Create Default Option 

    var temp: any = cloneDeep(InitializeConfig);
    temp.id = -1;
    temp.text = "Default View";
    temp.Sort.SortDefaultName = temp.Sort.SortDefaultName + " desc";
    temp.OtherFilters = [];
    temp.SalesRange = {
      SalesMin: 0,
      SalesMax: 10000000,
      RewardMin: 0,
      RewardMax: 10000000,
      RedeemMin: 0,
      RedeemMax: 10000000
    };
    this.Active_FilterOptions.push(temp);

    //#endregion

    //#region Initiate FilterSnap With Default 

    this.FilterSnap = cloneDeep(temp);
    this.FilterSnap.OtherFilters = [];
    this.FilterSnap.SalesRange = {
      SalesMin: 0,
      SalesMax: 10000000,
      RewardMin: 0,
      RewardMax: 10000000,
      RedeemMin: 0,
      RedeemMax: 10000000
    };
    this.FilterSnap.Badges = [];
    this.FilterSnapTemprary = cloneDeep(this.FilterSnap);
    this.FilterSnapPrestine = cloneDeep(this.FilterSnap);

    //#endregion

    //#region Get Stored Values 

    //var StorageValue = this.GetStorage(filterType);

    var filterData = {
      Task: "getliststates",
      "ReferenceId": this.UserAccount.AccountId,
      "ReferenceKey": this.UserAccount.AccountKey,
      "SubReferenceKey": filterType,
      "Offset": 0,
      "Limit": 100
    }

    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V3.SystemMange, filterData);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          var _ResponseData = _Response.Result as any;

          var FilterArray = null;
          if (_ResponseData.Data.length > 0) {
            FilterArray = JSON.parse(_ResponseData.Data[0].Data);
          }

          if (FilterArray != null) {
            this.Active_FilterOptions = this.Active_FilterOptions.concat(
              FilterArray
            );
          }
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );



    //#endregion
    this.ComputeFilterState();
  }

  Save_NewFilter(filterType: string, AccessType?: number): void {

    //#region check max limit 

    if (this.Active_FilterOptions.length >= 10) {
      this.NotifyError("You can store maximum 10 filters.");
      return;
    }

    //#endregion

    //#region checkforduplicate

    var IsNameDuplicate: boolean = this.IsNameDuplicate();
    if (IsNameDuplicate) {
      this.NotifyError("Duplicate Name. Please Enter New Name");
      return;
    }

    var IsStructureDuplicate: boolean = this.IsStructureDuplicate();
    if (IsStructureDuplicate) {
      return;
    }

    //#endregion

    this.FilterSnap.id = this.Active_FilterOptions.length - 1;
    this.Active_FilterOptions.push(cloneDeep(this.FilterSnap));
    // this.SaveStorage(
    //   filterType,
    //   this.Active_FilterOptions.slice(1, this.Active_FilterOptions.length)
    // );

    var filterData = {
      Task: "saveliststate",
      "AccountId": this.UserAccount.AccountId,
      "AccountKey": this.UserAccount.AccountKey,
      "SystemName": filterType,
      "AccessTypeId": ((AccessType != undefined) ? AccessType : 0),
      "Data": JSON.stringify(this.Active_FilterOptions.slice(1, this.Active_FilterOptions.length))
    }

    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V3.SystemMange, filterData);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AccountOverview = _Response.Result as OAccountOverview;
          this.NotifySuccess(_Response.Message);
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );

    //#region ReStorePreviousState 
    this.FilterSnap.id = this.FilterSnapPrev.id;
    this.FilterSnap.text = this.FilterSnapPrev.text;
    //#endregion

    this.FilterSnapTemprary = cloneDeep(this.FilterSnap);
    this.FilterSnapPrestine = cloneDeep(this.FilterSnap);

    this.ComputeFilterState();
  }

  Delete_Filter(filterType: string): void {
    var deleteIndex = 0;
    for (let index = 0; index < this.Active_FilterOptions.length; index++) {
      const element = this.Active_FilterOptions[index];
      if (element.text == this.FilterSnap.text) {
        deleteIndex = index;
        break;
      }
    }

    //#region delete existing
    if (deleteIndex == 0) {
      this.NotifyError("default Filter cannot be deleted");
      return;
    } else {
      //#region set current snap to default
      this.FilterSnap = cloneDeep(this.Active_FilterOptions[0]);
      this.FilterSnap.element = undefined;

      this.FilterSnapTemprary = cloneDeep(this.FilterSnap);
      this.FilterSnapPrestine = cloneDeep(this.FilterSnap);
      //#endregion
    }

    this.Active_FilterOptions.splice(deleteIndex, 1);

    // this.SaveStorage(
    //   filterType,
    //   this.Active_FilterOptions.slice(1, this.Active_FilterOptions.length)
    // );


    var filterData = {
      Task: "saveliststate",
      "AccountId": this.UserAccount.AccountId,
      "AccountKey": this.UserAccount.AccountKey,
      "SystemName": filterType,
      "Data": JSON.stringify(this.Active_FilterOptions.slice(1, this.Active_FilterOptions.length))
    }

    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V3.SystemMange, filterData);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AccountOverview = _Response.Result as OAccountOverview;
          this.NotifySuccess("View Deleted Successfully");
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );

    //#endregion

    this.ComputeFilterState();
  }

  IsNameDuplicate(): boolean {
    for (let index = 0; index < this.Active_FilterOptions.length; index++) {
      const element = this.Active_FilterOptions[index];
      if (this.FilterSnap.text == element.text) {
        return true;
      }
    }
    return false;
  }

  IsStructureDuplicate(): boolean {
    //#region clone FilterSnap 
    var FilterSnapClone: any = cloneDeep(this.FilterSnap);
    FilterSnapClone.text = null;
    FilterSnapClone.id = null;
    FilterSnapClone.Badges = null;
    FilterSnapClone.disabled = null;
    FilterSnapClone.element = null;
    FilterSnapClone.selected = null;
    //#endregion

    for (let index = 0; index < this.Active_FilterOptions.length; index++) {
      const element = this.Active_FilterOptions[index];

      var elementName: string = element.text;
      //#region clone ArrayElement 

      var ElementClone: any = cloneDeep(element);
      ElementClone.text = null;
      ElementClone.id = null;
      ElementClone.Badges = null;
      ElementClone.disabled = null;
      ElementClone.element = null;
      ElementClone.selected = null;

      //#endregion

      var areTwoEqual: boolean = this.AreTwoFilterSnapEqual(FilterSnapClone, ElementClone, 'check for duplicate');

      if (areTwoEqual) {
        this.NotifyError("Duplicate Filter." + elementName + " " + "is similar Filter.");
        return true;
      }

    }
    return false;
  }


  Active_FilterValueChanged(event: any): void {
    try {
      if (event.data.length > 0) {
        this.FilterSnap = cloneDeep(event.data[0]);
        this.FilterSnap.element = undefined;
        this.FilterSnapTemprary = cloneDeep(this.FilterSnap);
        this.FilterSnapPrestine = cloneDeep(this.FilterSnap);
      }
    } catch (error) {

    }

    this.ComputeFilterState();
  }

  _RefreshUI: boolean = true;
  Update_CurrentFilterSnap(event: any, Type: any, List_Config?: any, SubType?: any) {
    var ResetOffset = false;
    this.FilterSnapTemprary.RefreshData = false;
    this.FilterSnapTemprary.RefreshCount = false;

    if (
      !(
        isNull(List_Config.SearchBaseConditions) ||
        isUndefined(List_Config.SearchBaseConditions)
      )
    ) {
      this.FilterSnapTemprary.SearchBaseConditions = List_Config.SearchBaseConditions;
    }

    if (Type == this.AppConfig.ListToggleOption.Limit) {
      this.FilterSnapTemprary.PageRecordLimit = event;
      ResetOffset = true;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
    } else if (Type == this.AppConfig.ListToggleOption.Search) {
      if (event != null) {
        this.FilterSnapTemprary.SearchParameter = event.target.value;
      }
      ResetOffset = true;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
    } else if (Type == this.AppConfig.ListToggleOption.Date) {
      if (
        this.FilterSnapTemprary.StartDate == undefined &&
        this.FilterSnapTemprary.EndDate == undefined
      ) {
        this.FilterSnapTemprary.StartTime = event.start;
        this.FilterSnapTemprary.EndTime = event.end;
      }
      ResetOffset = true;
      this.FilterSnapTemprary.RefreshData = false;
      this.FilterSnapTemprary.RefreshCount = false;
    } else if (Type == this.AppConfig.ListToggleOption.DateExpiry) {
      if (
        this.FilterSnapTemprary.ExpiryStartDate == undefined &&
        this.FilterSnapTemprary.ExpiryEndDate == undefined
      ) {
        this.FilterSnapTemprary.ExpiryStartDate = event.start;
        this.FilterSnapTemprary.ExpiryEndDate = event.end;
      }
      ResetOffset = true;
      this.FilterSnapTemprary.RefreshData = false;
      this.FilterSnapTemprary.RefreshCount = false;
    }
    else if (Type == this.AppConfig.ListToggleOption.Status) {

      this.FilterSnapTemprary.StatusName = event.data[0].text;

      if (this.CurrentType == this.AppConfig.FilterTypeOption.MerchantSales) {
        this.FilterSnapTemprary.Status = event.data[0].id;
      } else {
        this.FilterSnapTemprary.Status = event.data[0].code;
      }

      this.FilterSnapTemprary.RefreshData = false;
      this.FilterSnapTemprary.RefreshCount = false;
      ResetOffset = false;
    } else if (Type == this.AppConfig.ListToggleOption.Sort) {
      if (isUndefined(this.FilterSnapTemprary.Sort)) {
        this.FilterSnapTemprary.Sort = {};
      }
      this.FilterSnapTemprary.Sort.SortColumn = event.SystemName;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
      ResetOffset = true;
    } else if (Type == this.AppConfig.ListToggleOption.SortOrder) {
      if (isUndefined(this.FilterSnapTemprary.Sort)) {
        this.FilterSnapTemprary.Sort = {};
      }
      if (this.FilterSnapTemprary.Sort.SortOrder == "asc") {
        this.FilterSnapTemprary.Sort.SortOrder = "desc";
      } else {
        this.FilterSnapTemprary.Sort.SortOrder = "asc";
      }
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
      ResetOffset = true;
    } else if (Type == this.AppConfig.ListToggleOption.SortApply) {
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
      ResetOffset = true;
    } else if (Type == this.AppConfig.ListToggleOption.SortReset) {
      if (isUndefined(this.FilterSnapTemprary.Sort)) {
        this.FilterSnapTemprary.Sort = {};
      }

      this.FilterSnapTemprary.Sort.SortOrder = "desc";
      this.FilterSnapTemprary.Sort.SortColumn = null;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
      ResetOffset = true;
    } else if (Type == this.AppConfig.ListToggleOption.Page) {
      this.FilterSnapTemprary.ActivePage = event;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = false;
    } else if (Type == this.AppConfig.ListToggleOption.MultiSelect) {
      this.FilterSnapTemprary.ActivePage = event;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
    } else if (Type == this.AppConfig.ListToggleOption.Refresh) {
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
    } else if (Type == this.AppConfig.ListToggleOption.Refresh) {
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
    } else if (Type == this.AppConfig.ListToggleOption.ResetOffset) {
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
      ResetOffset = true;
    } else if (Type == this.AppConfig.ListToggleOption.ApplyFilter) {
      ResetOffset = true;
      this.FilterSnapTemprary.RefreshData = true;
      this.FilterSnapTemprary.RefreshCount = true;
    } else if (Type == this.AppConfig.ListToggleOption.CancelFilter) {
    } else if (Type == this.AppConfig.ListToggleOption.SalesRange) {
      this.FilterSnapTemprary.SalesRange = event.data;
    } else if (Type == this.AppConfig.ListToggleOption.Other) {
      var data: any = cloneDeep(event.data[0]);
      data.element = undefined;
      data.OtherType = SubType;

      var CurrentIndex = this.FilterSnapTemprary.OtherFilters.findIndex((filter) => (filter.data[0].OtherType == SubType));
      if (CurrentIndex == -1) {
        this.FilterSnapTemprary.OtherFilters.push({ data: [data], value: event.value });
      } else {
        this.FilterSnapTemprary.OtherFilters[CurrentIndex] = { data: [data], value: event.value };
      }

      CurrentIndex = data;

    }
    if (ResetOffset == true) {
      this.FilterSnapTemprary.ActivePage = 1;
      this.FilterSnapTemprary.TotalRecords = 0;
      this.FilterSnapTemprary.ShowingStart = 0;
      this.FilterSnapTemprary.ShowingEnd = 0;
    }

    this.ComputeFilterState();

  }

  MakeFilterSnapPermanent(): void {
    this.FilterSnap = cloneDeep(this.FilterSnapTemprary);
    this.ComputeFilterState();
  }

  ResetFilterSnap(): void {
    var index: number = (this.FilterSnap.id < 1) ? 0 : (this.FilterSnap.id - 1);

    this.FilterSnapTemprary = cloneDeep(this.Active_FilterOptions[index]);
    this.FilterSnap = cloneDeep(this.Active_FilterOptions[index]);

  }

  ComputeFilterState(): void {

    //#region clone FilterSnap 
    var FilterSnapClone: any = cloneDeep(this.FilterSnap);
    FilterSnapClone.text = null;
    FilterSnapClone.id = null;
    FilterSnapClone.Badges = null;
    FilterSnapClone.disabled = null;
    FilterSnapClone.element = null;
    FilterSnapClone.selected = null;
    //#endregion

    //#region clone CleanConfig 
    var CleanConfigClone: any = cloneDeep(this.CleanConfig);
    CleanConfigClone.text = null;
    CleanConfigClone.id = null;
    CleanConfigClone.Badges = null;
    CleanConfigClone.disabled = null;
    CleanConfigClone.element = null;
    CleanConfigClone.selected = null;
    CleanConfigClone.selected = null;
    //#endregion

    if (this.AreTwoFilterSnapEqual(FilterSnapClone, CleanConfigClone, 'check for Default')) {
      this.FilterState = this.AppConfig.FilterStates.Default;
    } else if (this.AreTwoFilterSnapEqual(this.FilterSnap, this.FilterSnapPrestine, 'check for nochange')) {
      this.FilterState = this.AppConfig.FilterStates.NoChange;
    } else {
      this.FilterState = this.AppConfig.FilterStates.Modified;
    }

    // alert(this.FilterState);
  }

  //#endregion

  _FileSelect_Icon_Data: OStorageContent = {
    Name: null,
    Content: null,
    Extension: null,
    TypeCode: null,
    Height: 128,
    Width: 128,
  };

  public _FileSelect_Icon: string = this.AppConfig.Storage.ImageStorage;
  _FileSelect_Icon_Processing = false;
  //   public _FileSelect_Icon_onAdded(event: any) {
  //     var _FileName: string = event.file.name;
  //     var _File: File = event.file;
  //     var _FileReader: FileReader = new FileReader();
  //     _FileReader.onloadend = e => {
  //       var _FileContent = _FileReader.result;
  //       this._FileSelect_Icon_Processing = true;
  //       var _Image = new Image();
  //       _Image.src = _FileContent.toString();
  //       _Image.onload = () => {
  //         if (_Image.width == this._FileSelect_Icon_Data.Width && _Image.height == this._FileSelect_Icon_Data.Height) {
  //           var FileSelect_Icon_Data = this.GetImageDetails(_FileContent) as OStorageContent;
  //           this._FileSelect_Icon_Data.Content = FileSelect_Icon_Data.Content;
  //           this._FileSelect_Icon_Data.Extension = FileSelect_Icon_Data.Extension;
  //           this._FileSelect_Icon_Data.Name = _FileName;
  //           this._FileSelect_Icon_Data.Width = _Image.width;
  //           this._FileSelect_Icon_Data.Height = _Image.height;
  //         }
  //       else {
  //         this.NotifyWarning(this.AppConfig.CommonResource.InvalidImage);
  //         if (this._Ng2FileInputService.getCurrentFiles(this._FileSelect_Icon).length > 0) {
  //           this._Ng2FileInputService.reset(this._FileSelect_Icon);
  //         }
  //         this._FileSelect_Icon_Reset();
  //       }
  //     };
  //   };
  //   _FileReader.readAsDataURL(_File);
  // }
  public _FileSelect_Icon_Reset(): void {
    this._FileSelect_Icon_Data.Name = null;
    this._FileSelect_Icon_Data.Content = null;
    this._FileSelect_Icon_Data.Extension = null;
    // this._FileSelect_Icon_Data.Height = 0;
    // this._FileSelect_Icon_Data.Width = 0;
    if (
      this._Ng2FileInputService.getCurrentFiles(this._FileSelect_Icon).length >
      0
    ) {
      this._Ng2FileInputService.reset(this._FileSelect_Icon);
    }
    // if (this._FileSelect_Icon_Data.Content != null) {
    //   this._Ng2FileInputService.reset(this._FileSelect_Icon);
    // }
  }

  public Get_UserAccountDetails(ShowHeader: boolean) {
    this.AppConfig.ShowHeader = ShowHeader;

    this.IsFormProcessing = true;
    var pData = {
      Task: this.AppConfig.Api.Core.GetUserAccount,
      Reference: this.GetSearchConditionStrict(
        "",
        "ReferenceKey",
        this.AppConfig.DataType.Text,
        this.AppConfig.ActiveReferenceKey,
        "="
      ),
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.System, pData);
    _OResponse.subscribe(
      (_Response) => {
        if (_Response.Status == this.StatusSuccess) {
          this._UserAccount = _Response.Result as OUserDetails;

          this._UserAccount.DateOfBirth = this.GetDateS(
            this._UserAccount.DateOfBirth
          );
          this._UserAccount.LastLoginDateS = this.GetDateTimeS(
            this._UserAccount.LastLoginDate
          );
          this._UserAccount.CreateDateS = this.GetDateTimeS(
            this._UserAccount.CreateDate
          );
          this._UserAccount.ModifyDateS = this.GetDateTimeS(
            this._UserAccount.ModifyDate
          );
          this._UserAccount.StatusI = this.GetStatusIcon(
            this._UserAccount.StatusCode
          );
          this._UserAccount.StatusB = this.GetStatusBadge(
            this._UserAccount.StatusCode
          );
          this._UserAccount.StatusC = this.GetStatusColor(
            this._UserAccount.StatusCode
          );

          if (this._UserAccount.CreatedByDisplayName != undefined) {
            if (this._UserAccount.CreatedByDisplayName.length > 8) {
              this._UserAccount.CreatedByDisplayNameShort =
                this._UserAccount.CreatedByDisplayName.substring(0, 8) + "..";
            } else {
              this._UserAccount.CreatedByDisplayNameShort = this._UserAccount.CreatedByDisplayName;
            }
          }
          if (this._UserAccount.ModifyByDisplayName != undefined) {
            if (this._UserAccount.ModifyByDisplayName.length > 8) {
              this._UserAccount.ModifyByDisplayNameShort =
                this._UserAccount.ModifyByDisplayName.substring(0, 8) + "..";
            } else {
              this._UserAccount.ModifyByDisplayNameShort = this._UserAccount.ModifyByDisplayName;
            }
          }
          this.IsFormProcessing = false;
        } else {
          this.IsFormProcessing = false;
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.HandleException(_Error);
      }
    );
  }
  FlashSwalSuccess(Title, Message) {
    swal({
      type: "success",
      title: Title,
      text: Message,
      position: this.AppConfig.Alert_Position,
      animation: true,
      customClass: this.AppConfig.Alert_Animation,
      allowOutsideClick: this.AppConfig.Alert_AllowOutsideClick,
      allowEscapeKey: this.AppConfig.Alert_AllowEscapeKey,
      timer: 1600
    }).then((result) => { });
  }
  NotifySwalSuccess(Title, Message) {
    swal({
      type: "success",
      title: Title,
      text: Message,
      position: this.AppConfig.Alert_Position,
      animation: this.AppConfig.Alert_AllowAnimation,
      customClass: this.AppConfig.Alert_Animation,
      allowOutsideClick: this.AppConfig.Alert_AllowOutsideClick,
      allowEscapeKey: this.AppConfig.Alert_AllowEscapeKey,
    }).then((result) => { });
  }
  NotifySwalError(Title, Message) {
    swal({
      type: "error",
      title: Title,
      text: Message,
      position: this.AppConfig.Alert_Position,
      animation: this.AppConfig.Alert_AllowAnimation,
      customClass: this.AppConfig.Alert_Animation,
      allowOutsideClick: this.AppConfig.Alert_AllowOutsideClick,
      allowEscapeKey: this.AppConfig.Alert_AllowEscapeKey,
    }).then((result) => { });
  }

  NotifySuccess(Message) {
    notie.alert({
      type: "1",
      text: Message,
    });
  }
  NotifyWarning(Message) {
    notie.alert({
      type: "2",
      text: Message,
    });
  }
  NotifyError(Message) {
    notie.alert({
      type: "3",
      text: Message,
    });
  }
  NotifyInfo(Message) {
    notie.alert({
      type: "4",
      text: Message,
    });
  }
  HandleException(Exception: HttpErrorResponse) {
    this.IsFormProcessing = false;
    // console.log(Exception);
    if (
      Exception.status != undefined &&
      Exception.error != null &&
      Exception.status == 401
    ) {
      var ResponseData = JSON.parse(atob(Exception.error.zx)) as OResponse;
      this.NotifyError(ResponseData.Message);
      setTimeout(() => {
        window.location.href = "/account/login";
      }, 1500);
    } else {
      if (Exception.error instanceof Error) {
        this.NotifyError(
          "Sorry, error occured while connecting server:" +
          Exception.error.message
        );
      } else {
        this.NotifyError(
          "Sorry, error occured while connecting server : " +
          Exception.message +
          " Response Code -" +
          Exception.status
        );
      }
    }
  }
  GetRandomNumber() {
    this.RandomNumber = Math.floor(1000 + Math.random() * 9000);
    return this.RandomNumber;
  }
  GeneratePassoword() {
    var plength = 14;
    var keylistalpha = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var keylistint = "123456789";
    var keylistspec = "!@#_";
    var temp = "";
    var len = plength / 2;
    var len = len - 1;
    var lenspec = plength - len - len;

    for (let index = 0; index < len; index++)
      temp += keylistalpha.charAt(
        Math.floor(Math.random() * keylistalpha.length)
      );

    for (let index = 0; index < lenspec; index++)
      temp += keylistspec.charAt(
        Math.floor(Math.random() * keylistspec.length)
      );

    for (let index = 0; index < len; index++)
      temp += keylistint.charAt(Math.floor(Math.random() * keylistint.length));

    temp = temp
      .split("")
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join("");

    this.RandomPassword = temp;
    return temp;
  }
  RemoveItemFromArray(ItemToRemove, DataSet: any[]) {
    var Index = DataSet.indexOf(ItemToRemove, 0);
    if (Index > -1) {
      DataSet.splice(Index, 1);
    }
    return DataSet;
  }
  OpenModal(ModalId) {
    $("#" + ModalId).modal("show");
  }
  CloseModal(ModalId) {
    $("#" + ModalId).modal("hide");
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.Poster_Crop_Clear();
    this.Icon_Crop_Clear();
    this.AppConfig.ShowHeader = true;
    this.FullContainer = false;
    if (this.GetStorage(this.AppConfig.Storage.Account) != null) {
      return true;
    } else {
      this._Router.navigate([this.AppConfig.Pages.System.Login]);
      return false;
    }
    return true;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // this.AppConfig.ActiveOwnerAccountTypeCode = null;
    // this.AppConfig.ActiveOwnerAccountTypeId = null;
    this.AppConfig.ShowHeader = true;
    this.FullContainer = false;
    var AccountTypeCode = childRoute.data.accounttypecode;
    if (AccountTypeCode != undefined) {
      this.AppConfig.ActiveReferenceAccountTypeCode = AccountTypeCode;
    } else {
      this.AppConfig.ActiveReferenceAccountTypeCode = null;
    }
    var ActiveReferenceListType = childRoute.data.listtype;
    if (ActiveReferenceListType != undefined) {
      this.AppConfig.ActiveReferenceListType = ActiveReferenceListType;
    } else {
      this.AppConfig.ActiveReferenceListType = null;
    }

    if (this.UserAccount != null) {
      var PageName = childRoute.data.PageName;
      if (PageName != undefined) {
        this._TranslateService.get(PageName).subscribe((_PageName: string) => {
          this.AppConfig.ActivePageName = _PageName;
        });
      }
      return true;
    } else {
      this._Router.navigate([this.AppConfig.Pages.System.Login]);
      return false;
    }
    return true;

    // if (childRoute.params.referencekey != undefined) {
    //     this._HelperService.ActiveReferenceKey = childRoute.params.referencekey;
    // }
    // else {
    //     this._HelperService.ActiveReferenceKey = null;
    // }
    // if (childRoute.data.accounttypecode != undefined) {
    //     this._HelperService.ActiveReferenceAccountTypeCode = childRoute.data.accounttypecode;
    // }
    // else {
    //     this._HelperService.ActiveReferenceAccountTypeCode = null;
    // }
    // if (childRoute.data.listtype != undefined) {
    //     this._HelperService.ActiveListType = childRoute.data.listtype;
    // }
    // else {
    //     this._HelperService.ActiveListType = null;
    // }
    return true;
  }
  GetDateTime(Date) {
    return SystemHelper.GetDateTime(Date, this.AppConfig.TimeZone);
  }
  GetTimeS(Date) {
    return SystemHelper.GetTimeS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.TimeFormat
    );
  }
  GetDateS(Date) {
    return SystemHelper.GetDateS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateFormat
    );
  }

  DateInUTC(dt: any): any {

    try {
      var m = dt;
      var timezoneoffset = -(moment().zone());
      var m = moment(dt).utc().add(timezoneoffset, 'minutes');
      return m;
    } catch (error) {
      return dt;
    }

  }


  GetDayS(Date) {
    return SystemHelper.GetDateS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateDayFormat
    );
  }
  GetDateMonthS(Date) {
    return SystemHelper.GetDateS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateMonthFormat
    );
  }
  GetDateYearS(Date) {
    return SystemHelper.GetDateS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateYearFormat
    );
  }

  TriggerClick(Item) {
    return SystemHelper.TriggerClick(Item);
  }
  CheckDateIsAfter(Date, CompareTo) {
    return SystemHelper.CheckDateIsAfter(Date, CompareTo);
  }
  CheckDateIsBefore(Date, CompareTo) {
    return SystemHelper.CheckDateIsBefore(Date, CompareTo);
  }
  GetTimeDifference(Date, CompareTo) {
    return SystemHelper.GetTimeDifference(Date, CompareTo);
  }
  GetTimeDifferenceS(Date, CompareTo) {
    return SystemHelper.GetTimeDifferenceS(Date, CompareTo);
  }
  GetDateTimeS(Date) {
    return SystemHelper.GetDateTimeS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateTimeFormat
    );
  }
  GetDateTimeSForChart(Date) {
    return SystemHelper.GetDateTimeS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateTimeFormatForChart
    );
  }

  FormatDateForRMTarget(Date, format) {
    return SystemHelper.GetDateTimeS(
      moment(Date, 'DD-MM-YYYY'),
      this.AppConfig.TimeZone,
      format
    );
  }

  GetDateTimeSChart(Date) {
    return SystemHelper.GetDateTimeS(
      Date,
      this.AppConfig.TimeZone,
      this.AppConfig.DateTimeFormatForChartX
    );
  }
  GetTimeInterval(Date, CompareTo) {
    return SystemHelper.GetTimeInterval(
      Date,
      CompareTo,
      this.AppConfig.TimeZone
    );
  }
  newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  dec2hex(dec) {
    return ("0" + dec.toString(16)).substr(-2);
  }

  GenerateGuid() {
    var arr = new Uint8Array(40 / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, this.dec2hex).join("");
  }

  GenerateId(): number {
    return Math.floor(Math.random() * 90000) + 10000;
  }

  DivideTwoNumbers(num: number, din: number): number {
    var isDinUndefined = isUndefined(din);
    var isDinNull = isNull(din);

    if (!isDinUndefined && !isDinNull && din == 0) {
      return 0;
    } else {
      return num / din;
    }
  }

  GetStatusBadge(StatusCode) {
    var ColorCyan = "badge cyan";
    var ColorSuccess = "badge badge-success";
    var ColorWarning = "badge badge-warning";
    var ColorDanger = "badge badge-danger";
    var StatusIcon = "";
    if (isNaN(StatusCode) == false) {
      if (
        StatusCode == 293 ||
        StatusCode == 294 ||
        StatusCode == 1 ||
        StatusCode == 450 ||
        StatusCode == 452 ||
        StatusCode == 4 ||
        StatusCode == 299 ||
        StatusCode == 298 ||
        StatusCode == 29 ||
        StatusCode == 27 ||
        StatusCode == 461 ||
        StatusCode == 38
      ) {
        StatusIcon = ColorWarning;
      }
      if (
        StatusCode == 2 ||
        StatusCode == 28 ||
        StatusCode == 451 ||
        StatusCode == 295 ||
        StatusCode == 297 ||
        StatusCode == 463 ||
        StatusCode == 39
      ) {
        StatusIcon = ColorSuccess;
      }
      if (
        StatusCode == 3 ||
        StatusCode == 32 ||
        StatusCode == 30 ||
        StatusCode == 453 ||
        StatusCode == 454 ||
        StatusCode == 455 ||
        StatusCode == 31 ||
        StatusCode == 296 ||
        StatusCode == 300 ||
        StatusCode == 462 ||
        StatusCode == 464 ||
        StatusCode == 301 ||
        StatusCode == 40
      ) {
        StatusIcon = ColorDanger;
      }
      return StatusIcon;
    } else {
      if (
        StatusCode == "campaign.paused" ||
        StatusCode == "campaign.creating" ||
        StatusCode == "campaign.underreview" ||
        StatusCode == "campaign.lowbalance" ||
        StatusCode == "default.inactive" ||
        StatusCode == "default.suspended" ||
        StatusCode == "transaction.pending" ||
        StatusCode == "invoice.pending" ||
        StatusCode == "transaction.processing"
      ) {
        StatusIcon = ColorWarning;
      }
      if (
        StatusCode == "campaign.approved" ||
        StatusCode == "campaign.published" ||
        StatusCode == "default.active" ||
        StatusCode == "default.padi" ||
        StatusCode == "transaction.success"
      ) {
        StatusIcon = ColorSuccess;
      }
      if (
        StatusCode == "campaign.expired" ||
        StatusCode == "campaign.archived" ||
        StatusCode == "campaign.rejected" ||
        StatusCode == "default.disabled" ||
        StatusCode == "default.blocked" ||
        StatusCode == "transaction.error" ||
        StatusCode == "transaction.failed" ||
        StatusCode == "transaction.cancelled" ||
        StatusCode == "invoice.cancelled"
      ) {
        StatusIcon = ColorDanger;
      }
      return StatusIcon;
    }
  }
  GetStatusColor(StatusCode) {
    var ColorSuccess = "#20ab68";
    var ColorWarning = "#fda61c";
    var ColorDanger = "#e9223f";
    var StatusIcon = "";
    if (isNaN(StatusCode) == false) {
      if (
        StatusCode == 1 ||
        StatusCode == 4 ||
        StatusCode == 450 ||
        StatusCode == 452 ||
        StatusCode == 29 ||
        StatusCode == 461 ||
        StatusCode == 27
      ) {
        StatusIcon = ColorWarning;
      }
      if (
        StatusCode == 2 ||
        StatusCode == 451 ||
        StatusCode == 463 ||
        StatusCode == 28
      ) {
        StatusIcon = ColorSuccess;
      }
      if (
        StatusCode == 3 ||
        StatusCode == 32 ||
        StatusCode == 453 ||
        StatusCode == 454 ||
        StatusCode == 455 ||
        StatusCode == 462 ||
        StatusCode == 464 ||
        StatusCode == 30 ||
        StatusCode == 31
      ) {
        StatusIcon = ColorDanger;
      }
      return StatusIcon;
    } else {
      if (
        StatusCode == "default.inactive" ||
        StatusCode == "default.suspended" ||
        StatusCode == "transaction.pending" ||
        StatusCode == "transaction.processing"
      ) {
        StatusIcon = ColorWarning;
      }
      if (
        StatusCode == "default.active" ||
        StatusCode == "transaction.success"
      ) {
        StatusIcon = ColorSuccess;
      }
      if (
        StatusCode == "default.disabled" ||
        StatusCode == "default.blocked" ||
        StatusCode == "transaction.error" ||
        StatusCode == "transaction.failed" ||
        StatusCode == "transaction.cancelled"
      ) {
        StatusIcon = ColorDanger;
      }
      return StatusIcon;
    }
  }
  GetStatusIcon(StatusCode) {
    var ColorPrimary = "text-primary";
    var ColorInfo = "text-info";
    var ColorSuccess = "text-success";
    var ColorWarn = "text-warn";
    var ColorWarning = "text-warning";
    var ColorDanger = "text-danger";
    var StatusIcon = "fa fa-circle text-default f-s-11 p-r-5 ";
    if (isNaN(StatusCode) == false) {
      if (
        StatusCode == 1 ||
        StatusCode == 4 ||
        StatusCode == 29 ||
        StatusCode == 450 ||
        StatusCode == 452 ||
        StatusCode == 461 ||
        StatusCode == 27
      ) {
        StatusIcon = "fa fa-circle text-warning f-s-11 p-r-5 ";
      }
      if (
        StatusCode == 2 ||
        StatusCode == 463 ||
        StatusCode == 451 ||
        StatusCode == 28
      ) {
        StatusIcon = "fa fa-circle text-success f-s-11 p-r-5";
      }
      if (
        StatusCode == 3 ||
        StatusCode == 32 ||
        StatusCode == 462 ||
        StatusCode == 464 ||
        StatusCode == 453 ||
        StatusCode == 454 ||
        StatusCode == 455 ||
        StatusCode == 30 ||
        StatusCode == 31
      ) {
        StatusIcon = "fa fa-circle text-danger f-s-11 p-r-5";
      }
      return StatusIcon;
    } else {
      if (
        StatusCode == "default.suspended" ||
        StatusCode == "default.inactive" ||
        StatusCode == "transaction.pending" ||
        StatusCode == "transaction.processing"
      ) {
        StatusIcon = "fa fa-circle text-warning f-s-11 p-r-5 ";
      }
      if (
        StatusCode == "default.active" ||
        StatusCode == "transaction.success"
      ) {
        StatusIcon = "fa fa-circle text-success f-s-11 p-r-5";
      }
      if (
        StatusCode == "default.disabled" ||
        StatusCode == "default.blocked" ||
        StatusCode == "transaction.error" ||
        StatusCode == "transaction.failed" ||
        StatusCode == "transaction.cancelled"
      ) {
        StatusIcon = "fa fa-circle text-danger f-s-11 p-r-5";
      }
      return StatusIcon;
    }
  }
  SaveStorage(StorageName, StorageValue) {
    try {
      var StringV = btoa(JSON.stringify(StorageValue));
      localStorage.setItem(StorageName, StringV);
      return true;
    } catch (e) {
      // console.log(e);
      alert(e);
      return false;
    }
  }
  SaveStorageValue(StorageName, StorageValue) {
    try {
      localStorage.setItem(StorageName, btoa(StorageValue));
      return true;
    } catch (e) {
      alert(e);
      return false;
    }
  }
  GetStorage(StorageName) {
    var StorageValue = localStorage.getItem(StorageName);
    if (StorageValue != undefined) {
      if (StorageValue != null) {
        return JSON.parse(atob(StorageValue));
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  GetStorageValue(StorageName) {
    var StorageValue = localStorage.getItem(StorageName);
    if (StorageValue != undefined) {
      if (StorageValue != null) {
        return atob(StorageValue);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  DeleteStorage(StorageName) {
    localStorage.removeItem(StorageName);
    return true;
  }
  DownloadCsv(Data, FileName, CsvOptions) {
    // this._Angular2CsvModule.();
    // new Angu(Data, FileName, CsvOptions);
    // new Angular2Csv(Data, FileName, CsvOptions);
    //   new Angular2Csv(Data, FileName, CsvOptions);
  }


  PreventText(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  //for accepting the ponit/decimal values
  //reward percentage
  // PreventTextReward(event: any) {
  //   const pattern = /[-+]?[0-9]*\.?[0-9]*/;
  //   let inputChar = String.fromCharCode(event.charCode);
  //   if (event.keyCode != 8 && !pattern.test(inputChar)) {
  //     event.preventDefault();
  //   }
  // }



  GetSearchCondition(BaseString, ColumnName, ColumnType, ColumnValue) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }

    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      ColumnType != undefined &&
      ColumnValue != undefined &&
      ColumnName != null &&
      ColumnType != null &&
      ColumnValue != null &&
      ColumnName != "" &&
      ColumnType != "" &&
      ColumnValue != ""
    ) {
      if (ColumnType == "text") {
        if (
          SearchExpression != undefined &&
          SearchExpression != null &&
          SearchExpression != ""
        ) {
          SearchExpression += " OR ";
        }
        SearchExpression += ColumnName + '.Contains("' + ColumnValue + '")';
      } else if (ColumnType == "number") {
        if (isNaN(ColumnValue) == false) {
          if (
            SearchExpression != undefined &&
            SearchExpression != null &&
            SearchExpression != ""
          ) {
            SearchExpression = "( " + SearchExpression + ") AND ";
          }
          SearchExpression += ColumnName + " = " + ColumnValue + " ";
        }
      }

      if (BaseString != undefined && BaseString != null && BaseString != "") {
        BaseString += " OR ";
      }

      //  BaseString += SearchExpression;
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetSortCondition(SortConditions) {
    var NewSortCondition = "";
    if (
      SortConditions != undefined ||
      SortConditions != null ||
      SortConditions.length != 0
    ) {
      for (let index = 0; index < SortConditions.length; index++) {
        const element = SortConditions[index];
        if (index == SortConditions.length - 1) {
          NewSortCondition = NewSortCondition + element;
        } else {
          NewSortCondition = NewSortCondition + element + ",";
        }
      }
    }
    return NewSortCondition;
  }
  GetSearchConditionStrict(
    BaseString,
    ColumnName,
    ColumnType,
    ColumnValue,
    Condition
  ) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }

    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      ColumnType != undefined &&
      // ColumnValue != undefined &&
      ColumnName != null &&
      ColumnType != null &&
      //ColumnValue != null &&
      ColumnName != "" &&
      ColumnType != "" //&&
      // ColumnValue != ''
    ) {
      if (ColumnType == "text") {
        if (
          SearchExpression != undefined &&
          SearchExpression != null &&
          SearchExpression != ""
        ) {
          SearchExpression = "( " + SearchExpression + ") AND ";
        }
        // SearchExpression += ColumnName + '.Contains("' + ColumnValue + '")';
        if (ColumnValue == "") {
          SearchExpression += ColumnName + " " + Condition + ' "" ';
        } else if (ColumnValue != null && ColumnValue != "") {
          SearchExpression +=
            ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
        } else {
          SearchExpression +=
            "( " + ColumnName + " " + Condition + " null " + ")";
        }
      } else if (ColumnType == "number") {
        if (
          SearchExpression != undefined &&
          SearchExpression != null &&
          SearchExpression != ""
        ) {
          SearchExpression = "( " + SearchExpression + ") AND ";
        }
        if (ColumnValue != null && ColumnValue != "") {
          SearchExpression +=
            ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
        } else {
          SearchExpression += "( " + ColumnName + " " + Condition + " " + ")";
        }
      }

      if (BaseString != undefined && BaseString != null && BaseString != "") {
        BaseString += " OR ";
      }

      //  BaseString += SearchExpression;
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetSearchConditionStrictOr(
    BaseString,
    ColumnName,
    ColumnType,
    ColumnValue,
    Condition
  ) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }

    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      ColumnType != undefined &&
      // ColumnValue != undefined &&
      ColumnName != null &&
      ColumnType != null &&
      //ColumnValue != null &&
      ColumnName != "" &&
      ColumnType != "" //&&
      // ColumnValue != ''
    ) {
      if (ColumnType == this.AppConfig.DataType.Text) {
        if (
          SearchExpression != undefined &&
          SearchExpression != null &&
          SearchExpression != ""
        ) {
          SearchExpression = "( " + SearchExpression + ") OR ";
        }
        // SearchExpression += ColumnName + '.Contains("' + ColumnValue + '")';
        if (ColumnValue == "") {
          SearchExpression += ColumnName + " " + Condition + ' "" ';
        } else if (ColumnValue != null && ColumnValue != "") {
          SearchExpression +=
            ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
        } else {
          SearchExpression +=
            "( " + ColumnName + " " + Condition + " null " + ")";
        }
      } else if (ColumnType == this.AppConfig.DataType.Number) {
        if (
          isNaN(ColumnValue) == false &&
          ColumnValue.toString().indexOf(".") == -1
        ) {
          if (
            SearchExpression != undefined &&
            SearchExpression != null &&
            SearchExpression != ""
          ) {
            SearchExpression = "( " + SearchExpression + ") OR ";
          }
          if (ColumnValue != null && ColumnValue != "") {
            SearchExpression +=
              ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
          } else {
            SearchExpression += "( " + ColumnName + " " + Condition + " " + ")";
          }
        }
      } else if (ColumnType == this.AppConfig.DataType.Decimal) {
        if (isNaN(ColumnValue) == false) {
          if (
            SearchExpression != undefined &&
            SearchExpression != null &&
            SearchExpression != ""
          ) {
            SearchExpression = "( " + SearchExpression + ") OR ";
          }
          if (ColumnValue != null && ColumnValue != "") {
            SearchExpression +=
              ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
          } else {
            SearchExpression += "( " + ColumnName + " " + Condition + " " + ")";
          }
        }
      }
      if (BaseString != undefined && BaseString != null && BaseString != "") {
        BaseString += " AND ";
      }

      //  BaseString += SearchExpression;
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetDateCondition(BaseString, ColumnName, StartTime, EndTime) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }

    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      StartTime != undefined &&
      EndTime != undefined &&
      ColumnName != null &&
      StartTime != null &&
      EndTime != null &&
      ColumnName != "" &&
      StartTime != "" &&
      EndTime != ""
    ) {
      StartTime = moment(StartTime).subtract(1, "seconds");
      EndTime = moment(EndTime).add(1, "seconds");
      var FSd = new Date(StartTime);
      var TStartDateM = moment(FSd).utc().format("YYYY-MM-DD HH:mm:ss");
      var ESd = new Date(EndTime);
      var TEndTimeM = moment(ESd).utc().format("YYYY-MM-DD HH:mm:ss");
      // var TStartTime = FSd.getFullYear() + '-' + (FSd.getMonth() + 1) + '-' + (FSd.getDate()) + ' 23:59:00';
      // var TEndTime = ESd.getFullYear() + '-' + (ESd.getMonth() + 1) + '-' + (ESd.getDate()) + ' 23:59:00';
      if (
        SearchExpression != undefined &&
        SearchExpression != null &&
        SearchExpression != ""
      ) {
        SearchExpression = "( " + SearchExpression + ") AND ";
      }
      SearchExpression +=
        "( " +
        ColumnName +
        ' > "' +
        TStartDateM +
        '" AND ' +
        ColumnName +
        ' < "' +
        TEndTimeM +
        '")';
      if (BaseString != undefined && BaseString != null && BaseString != "") {
        BaseString += " AND ";
      }
      //  BaseString += SearchExpression;
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetDateOnlyCondition(BaseString, ColumnName, StartTime) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }

    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      StartTime != undefined &&
      ColumnName != null &&
      StartTime != null &&
      ColumnName != "" &&
      StartTime != ""
    ) {
      StartTime = moment(StartTime).format("LL");
      var FSd = new Date(StartTime);
      var TStartDateM = moment(FSd).utc().format("YYYY-MM-DD");
      if (
        SearchExpression != undefined &&
        SearchExpression != null &&
        SearchExpression != ""
      ) {
        SearchExpression = "( " + SearchExpression + ") AND ";
      }
      SearchExpression += "( " + ColumnName + ' = "' + TStartDateM + '")';
      if (BaseString != undefined && BaseString != null && BaseString != "") {
        BaseString += " AND ";
      }
      //  BaseString += SearchExpression;
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetSearchConditionRange(BaseString, ColumnName, StartRange, EndRange) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }
    StartRange = StartRange - 1;
    EndRange = EndRange + 1;
    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      StartRange != undefined &&
      EndRange != undefined &&
      ColumnName != null &&
      StartRange != null &&
      EndRange != null &&
      ColumnName != "" &&
      StartRange != "" &&
      EndRange != ""
    ) {
      if (
        SearchExpression != undefined &&
        SearchExpression != null &&
        SearchExpression != ""
      ) {
        SearchExpression = "( " + SearchExpression + ") AND ";
      }
      SearchExpression +=
        "( " +
        ColumnName +
        ' > "' +
        StartRange +
        '" AND ' +
        ColumnName +
        ' < "' +
        EndRange +
        '")';
      if (BaseString != undefined && BaseString != null && BaseString != "") {
        BaseString += " AND ";
      }
      //  BaseString += SearchExpression;
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetSearchConditionStrictFromArray(
    BaseString,
    ColumnName,
    ColumnType,
    ColumnValueArray,
    Condition
  ) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }
    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      ColumnType != undefined &&
      ColumnName != null &&
      ColumnType != null &&
      ColumnName != "" &&
      ColumnType != "" //&&
    ) {
      if (
        ColumnValueArray != undefined ||
        ColumnValueArray != null ||
        ColumnValueArray.length != 0
      ) {
        if (ColumnType == "text") {
          var TExpression = "";
          for (let index = 0; index < ColumnValueArray.length; index++) {
            const ColumnValue = ColumnValueArray[index];
            if (TExpression != "") {
              TExpression += " OR ";
            }
            if (ColumnValue != null && ColumnValue != "") {
              TExpression +=
                ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
            } else {
              TExpression +=
                " " + ColumnName + " " + Condition + " null " + " ";
            }
          }
          if (TExpression != "") {
            if (SearchExpression != "") {
              SearchExpression =
                "( " + SearchExpression + ") AND (" + TExpression + ")";
            } else {
              SearchExpression = TExpression;
            }
          }
        } else if (ColumnType == "number") {
          var TColSearch = "";
          if (ColumnValueArray.length == 1) {
            const ColumnValue = ColumnValueArray[0];
            if (ColumnValue != null && ColumnValue != "") {
              TColSearch +=
                " " + ColumnName + " " + Condition + ' "' + ColumnValue + '"';
            } else {
              TColSearch += " " + ColumnName + " " + Condition + " " + "";
            }
          } else {
            for (let index = 0; index < ColumnValueArray.length; index++) {
              const ColumnValue = ColumnValueArray[index];
              if (TColSearch != "") {
                TColSearch += " OR ";
              }
              if (ColumnValue != null && ColumnValue != "") {
                TColSearch +=
                  " " +
                  ColumnName +
                  " " +
                  Condition +
                  ' "' +
                  ColumnValue +
                  '" ';
              } else {
                TColSearch += "  " + ColumnName + " " + Condition + " " + " ";
              }
            }
          }
          if (TColSearch != "") {
            if (SearchExpression != "") {
              SearchExpression =
                "( " + SearchExpression + ") AND (" + TColSearch + ")";
            } else {
              SearchExpression = TColSearch;
            }
          }
        }
      }
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetSearchConditionSubStrictFromArray(
    BaseString,
    ColumnName,
    ColumnType,
    ColumnValueArray,
    Condition
  ) {
    if (BaseString == undefined || BaseString == null) {
      BaseString = "";
    }
    var SearchExpression = BaseString;
    if (
      ColumnName != undefined &&
      ColumnType != undefined &&
      ColumnName != null &&
      ColumnType != null &&
      ColumnName != "" &&
      ColumnType != "" //&&
    ) {
      if (
        ColumnValueArray != undefined ||
        ColumnValueArray != null ||
        ColumnValueArray.length != 0
      ) {
        if (ColumnType == "text") {
          var TExpression = "";
          for (let index = 0; index < ColumnValueArray.length; index++) {
            const ColumnValue = ColumnValueArray[index];
            if (TExpression != "") {
              TExpression += " OR ";
            }
            if (ColumnValue != null && ColumnValue != "") {
              TExpression +=
                ColumnName + " " + Condition + ' "' + ColumnValue + '" ';
            } else {
              TExpression +=
                " " + ColumnName + " " + Condition + " null " + " ";
            }
          }
          if (TExpression != "") {
            if (SearchExpression != "") {
              SearchExpression =
                "( " + SearchExpression + ") AND (" + TExpression + ")";
            } else {
              SearchExpression = TExpression;
            }
          }
        } else if (ColumnType == "number") {
          var TColSearch = "";
          if (ColumnValueArray.length == 1) {
            const ColumnValue = ColumnValueArray[0];
            if (ColumnValue != null && ColumnValue != "") {
              TColSearch +=
                " " + ColumnName + " " + Condition + ' "' + ColumnValue + '"';
            } else {
              TColSearch += " " + ColumnName + " " + Condition + " " + "";
            }
          } else {
            for (let index = 0; index < ColumnValueArray.length; index++) {
              const ColumnValue = ColumnValueArray[index];
              if (TColSearch != "") {
                TColSearch += " OR ";
              }
              if (ColumnValue != null && ColumnValue != "") {
                TColSearch +=
                  " " +
                  ColumnName +
                  " " +
                  Condition +
                  ' "' +
                  ColumnValue +
                  '" ';
              } else {
                TColSearch += "  " + ColumnName + " " + Condition + " " + " ";
              }
            }
          }
          if (TColSearch != "") {
            if (SearchExpression != "") {
              SearchExpression =
                "( " + SearchExpression + ") AND (" + TColSearch + ")";
            } else {
              SearchExpression = TColSearch;
            }
          }
        }
      }
      return SearchExpression;
    } else {
      return BaseString;
    }
  }
  GetImageDetails(ImageBase64) {
    var _ImageContent: OStorageContent = {
      Name: null,
      Content: null,
      Extension: null,
      TypeCode: null,
      Width: 0,
      Height: 0,
    };

    if (ImageBase64 != null && ImageBase64 != "") {
      var ImageContent = ImageBase64;
      var ImageExtension = "jpg";
      var ImageBase = ImageContent.replace("data:image/png;base64,", "")
        .replace("data:image/jpg;base64,", "")
        .replace("data:image/jpeg;base64,", "")
        .replace("data:image/gif;base64,", "");
      if (ImageContent.includes("data:image/png;base64,")) {
        ImageExtension = "png";
      } else if (ImageContent.includes("data:image/jpg;base64,")) {
        ImageExtension = "jpg";
      } else if (ImageContent.includes("data:image/jpeg;base64,")) {
        ImageExtension = "jpeg";
      } else if (ImageContent.includes("data:image/gif;base64,")) {
        ImageExtension = "gif";
      } else {
        ImageExtension = "jpg";
      }
      _ImageContent.Content = ImageBase;
      _ImageContent.Extension = ImageExtension;
      return _ImageContent;
    } else {
      return _ImageContent;
    }
  }

  GetTerminalStatusCount(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getterminalstatuscount",
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._TerminalStatusCount = _Response.Result as OTerminalStatusCount;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetUserCounts(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getusercounts",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._UserCounts = _Response.Result as OUserCounts;
          if (
            this._AccountOverview.SettlementCredit != undefined &&
            this._AccountOverview.SettlementDebit != undefined
          ) {
            this._AccountOverview.SettlementPending =
              this._AccountOverview.SettlementCredit -
              this._AccountOverview.SettlementDebit;
          }
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetSalesOverview(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId
  ) {
    this.IsFormProcessing = true;
    this._SalesOverview.TransactionTypeLabels = [];
    this._SalesOverview.TransactionTypeData = [];
    this._SalesOverview.TransactionTypeUsersLabels = [];
    this._SalesOverview.TransactionTypeUsersData = [];
    this._SalesOverview.TransactionStatusLabels = [];
    this._SalesOverview.TransactionStatusData = [];

    this._SalesOverview.TransactionTypeSalesLabels = [];
    this._SalesOverview.TransactionTypeSalesData = [];

    var Data = {
      Task: "getsalesoverview",
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._SalesOverview = _Response.Result as OSalesOverview;
          this._SalesOverview.FirstTransactionDate = this.GetDateTimeS(
            this._SalesOverview.FirstTransactionDate
          );
          this._SalesOverview.LastTransactionDateD = this.GetTimeDifferenceS(
            this._SalesOverview.LastTransactionDate,
            moment()
          );
          this._SalesOverview.LastTransactionDate = this.GetDateTimeS(
            this._SalesOverview.LastTransactionDate
          );

          this._SalesOverview.TransactionTypeUsersLabels = [];
          this._SalesOverview.TransactionTypeUsersData = [];
          this._SalesOverview.TransactionTypeUsersLabels.push("Cash Tr Users");
          this._SalesOverview.TransactionTypeUsersLabels.push("Card Tr. Users");
          this._SalesOverview.TransactionTypeUsersData.push(
            this._SalesOverview.TotalCashTransactionCustomer
          );
          this._SalesOverview.TransactionTypeUsersData.push(
            this._SalesOverview.TotalCardTransactionCustomer
          );

          this._SalesOverview.TransactionTypeSalesLabels = [];
          this._SalesOverview.TransactionTypeSalesData = [];
          this._SalesOverview.TransactionTypeSalesLabels.push("Cash Payments");
          this._SalesOverview.TransactionTypeSalesLabels.push("Card Payments");
          this._SalesOverview.TransactionTypeSalesData.push(
            this._SalesOverview.TotalCashTransactionInvoiceAmount
          );
          this._SalesOverview.TransactionTypeSalesData.push(
            this._SalesOverview.TotalCardTransactionInvoiceAmount
          );

          this._SalesOverview.TransactionTypeLabels = [];
          this._SalesOverview.TransactionTypeData = [];
          this._SalesOverview.TransactionTypeLabels.push("Cash Transactions");
          this._SalesOverview.TransactionTypeLabels.push("Card Transactions");
          this._SalesOverview.TransactionTypeData.push(
            this._SalesOverview.TotalCashTransaction
          );
          this._SalesOverview.TransactionTypeData.push(
            this._SalesOverview.TotalCardTransaction
          );

          this._SalesOverview.TransactionStatusLabels = [];
          this._SalesOverview.TransactionStatusData = [];
          this._SalesOverview.TransactionStatusLabels.push("Success");
          this._SalesOverview.TransactionStatusLabels.push("Failed");
          this._SalesOverview.TransactionStatusData.push(
            this._SalesOverview.TotalSuccessfulTransaction
          );
          this._SalesOverview.TransactionStatusData.push(
            this._SalesOverview.TotalFailedTransaction
          );
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetSalesSummary(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    this._SalesSummary.TransactionTypeLabels = [];
    this._SalesSummary.TransactionTypeData = [];
    this._SalesSummary.TransactionTypeUsersLabels = [];
    this._SalesSummary.TransactionTypeUsersData = [];
    this._SalesSummary.TransactionStatusLabels = [];
    this._SalesSummary.TransactionStatusData = [];
    this._SalesSummary.TransactionTypeSalesLabels = [];
    this._SalesSummary.TransactionTypeSalesData = [];
    var Data = {
      Task: "getsalessummary",
      StartTime: StartTime,
      EndTime: EndTime,
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._SalesSummary = _Response.Result as OSalesSummary;
          this._SalesSummary.FirstTransactionDate = this.GetDateTimeS(
            this._SalesSummary.FirstTransactionDate
          );
          this._SalesSummary.LastTransactionDateD = this.GetTimeDifferenceS(
            this._SalesSummary.LastTransactionDate,
            moment()
          );
          this._SalesSummary.LastTransactionDate = this.GetDateTimeS(
            this._SalesSummary.LastTransactionDate
          );

          this._SalesSummary.TransactionTypeUsersLabels = [];
          this._SalesSummary.TransactionTypeUsersData = [];
          this._SalesSummary.TransactionTypeUsersLabels.push("Cash Tr Users");
          this._SalesSummary.TransactionTypeUsersLabels.push("Card Tr. Users");
          this._SalesSummary.TransactionTypeUsersData.push(
            this._SalesSummary.TotalCashTransactionCustomer
          );
          this._SalesSummary.TransactionTypeUsersData.push(
            this._SalesSummary.TotalCardTransactionCustomer
          );

          this._SalesSummary.TransactionTypeSalesLabels = [];
          this._SalesSummary.TransactionTypeSalesData = [];
          this._SalesSummary.TransactionTypeSalesLabels.push("Cash Payments");
          this._SalesSummary.TransactionTypeSalesLabels.push("Card Payments");
          this._SalesSummary.TransactionTypeSalesData.push(
            this._SalesSummary.TotalCashTransactionInvoiceAmount
          );
          this._SalesSummary.TransactionTypeSalesData.push(
            this._SalesSummary.TotalCardTransactionInvoiceAmount
          );

          this._SalesSummary.TransactionTypeLabels = [];
          this._SalesSummary.TransactionTypeData = [];
          this._SalesSummary.TransactionTypeLabels.push("Cash Transactions");
          this._SalesSummary.TransactionTypeLabels.push("Card Transactions");
          this._SalesSummary.TransactionTypeData.push(
            this._SalesSummary.TotalCashTransaction
          );
          this._SalesSummary.TransactionTypeData.push(
            this._SalesSummary.TotalCardTransaction
          );

          this._SalesSummary.TransactionStatusLabels = [];
          this._SalesSummary.TransactionStatusData = [];
          this._SalesSummary.TransactionStatusLabels.push("Success");
          this._SalesSummary.TransactionStatusLabels.push("Failed");
          this._SalesSummary.TransactionStatusData.push(
            this._SalesSummary.TotalSuccessfulTransaction
          );
          this._SalesSummary.TransactionStatusData.push(
            this._SalesSummary.TotalFailedTransaction
          );
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRewardsSummary(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getrewardssummary",
      StartTime: StartTime,
      EndTime: EndTime,
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RewardsSummary = _Response.Result as ORewardsSummary;
          this._RewardsSummary.FirstTransactionDateD = this.GetDateTimeS(
            this._RewardsSummary.FirstTransactionDate
          );
          this._RewardsSummary.FirstTransactionDate = this.GetDateTimeS(
            this._RewardsSummary.FirstTransactionDate
          );
          this._RewardsSummary.LastTransactionDateD = this.GetTimeDifferenceS(
            this._RewardsSummary.LastTransactionDate,
            moment()
          );
          this._RewardsSummary.LastTransactionDate = this.GetDateTimeS(
            this._RewardsSummary.LastTransactionDate
          );
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  public _OSalesHistory = {
    Labels: [],
    SaleColors: [],
    SaleDataSet: [],

    SaleCustomersColors: [],
    SaleCustomersDataSet: [],

    TransactionStatusDataSetColors: [],
    TransactionStatusDataSet: [],

    TransactionTypeDataSetColors: [],
    TransactionTypeDataSet: [],

    TransactionTypeCustomersDataSetColors: [],
    TransactionTypeCustomersDataSet: [],
  };
  GetSalesHistory(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getsaleshistory",
      StartTime: StartTime,
      EndTime: EndTime,
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          var TStatusDataSet = [];
          var TStatusDataSetColor = [];
          this._OSalesHistory.Labels = [];
          var ResponseData = _Response.Result;
          var TSaleColor = [
            {
              backgroundColor: [],
            },
          ];
          var TSaleDataSet = [
            {
              label: "Sale",
              fill: false,
              borderColor: "#907eec",
              borderDash: [5, 5],
              backgroundColor: "#907eec",
              pointBackgroundColor: "#fdab29",
              pointBorderColor: "#fdab29",
              pointHoverBackgroundColor: "#fdab29",
              pointHoverBorderColor: "#fdab29",
              data: [],
            },
          ];
          var TSaleCustomersColor = [
            {
              backgroundColor: [],
            },
          ];
          var TSaleCustomersDataSet = [
            {
              label: "Customer Visits",
              fill: false,
              borderColor: "#907eec",
              borderDash: [5, 5],
              backgroundColor: "#907eec",
              pointBackgroundColor: "#fdab29",
              pointBorderColor: "#fdab29",
              pointHoverBackgroundColor: "#fdab29",
              pointHoverBorderColor: "#fdab29",
              data: [],
            },
          ];
          var TTypeDataSetColor = [];
          var TTypeDataSet = [];
          var TTypeCustomersDataSetColor = [];
          var TTypeCustomersDataSet = [];
          var TTypeDataSetCardItemColor = {
            backgroundColor: [],
          };
          var TTypeDataSetCashItemColor = {
            backgroundColor: [],
          };
          var TTypeCustomersDataSetCardItemColor = {
            backgroundColor: [],
          };
          var TTypeCustomersDataSetCashItemColor = {
            backgroundColor: [],
          };
          var TTypeDataSetCardItem = {
            label: "Card",
            backgroundColor: [],
            data: [],
          };
          var TTypeDataSetCashItem = {
            label: "Cash",
            backgroundColor: [],
            data: [],
          };
          var TTypeCustomersDataSetCardItem = {
            label: "Card Customers",
            backgroundColor: [],
            data: [],
          };
          var TTypeCustomersDataSetCashItem = {
            label: "Cash Customers",
            backgroundColor: [],
            data: [],
          };
          var TStatusDataSetSuccessItemColor = {
            backgroundColor: [],
          };
          var TStatusDataSetErrorItemError = {
            backgroundColor: [],
          };
          var TStatusDataSetSuccessItem = {
            label: "Success",
            backgroundColor: [],
            data: [],
          };
          var TStatusDataSetErrorItem = {
            label: "Error",
            backgroundColor: [],
            data: [],
          };
          ResponseData.forEach((element) => {
            var Data = element.Data;
            this._OSalesHistory.Labels.push(this.GetDateMonthS(element.Date));
            TSaleDataSet[0].data.push(
              Math.round(Data.TotalSuccessfulTransactionInvoiceAmount)
            );
            TSaleColor[0].backgroundColor.push("rgba(97, 192, 60, 0.7)");
            TSaleCustomersDataSet[0].data.push(
              Data.TotalSuccessfulTransactionCustomer
            );
            TSaleCustomersColor[0].backgroundColor.push(
              "rgba(153, 0, 153, 0.7)"
            );
            TTypeDataSetCardItem.data.push(
              Math.round(Data.TotalCardTransactionInvoiceAmount)
            );
            TTypeDataSetCardItemColor.backgroundColor.push("#61c03c");
            TTypeDataSetCashItem.data.push(
              Math.round(Data.TotalCashTransactionInvoiceAmount)
            );
            TTypeDataSetCashItemColor.backgroundColor.push("#ff9900");
            TTypeCustomersDataSetCardItem.data.push(
              Data.TotalCardTransactionCustomer
            );
            TTypeCustomersDataSetCardItemColor.backgroundColor.push("#990099");
            TTypeCustomersDataSetCashItem.data.push(
              Data.TotalCashTransactionCustomer
            );
            TTypeCustomersDataSetCashItemColor.backgroundColor.push("#00aff0");
            TStatusDataSetSuccessItem.data.push(
              Data.TotalSuccessfulTransaction
            );
            TStatusDataSetSuccessItemColor.backgroundColor.push("#61c03c");
            TStatusDataSetErrorItem.data.push(Data.TotalFailedTransaction);
            TStatusDataSetErrorItemError.backgroundColor.push("#dc3912");
          });
          TStatusDataSetColor.push(TStatusDataSetSuccessItemColor);
          TStatusDataSetColor.push(TStatusDataSetErrorItemError);
          TStatusDataSet.push(TStatusDataSetSuccessItem);
          TStatusDataSet.push(TStatusDataSetErrorItem);
          TTypeDataSetColor.push(TTypeDataSetCardItemColor);
          TTypeDataSetColor.push(TTypeDataSetCashItemColor);
          TTypeDataSet.push(TTypeDataSetCardItem);
          TTypeDataSet.push(TTypeDataSetCashItem);
          TTypeCustomersDataSetColor.push(TTypeCustomersDataSetCardItemColor);
          TTypeCustomersDataSetColor.push(TTypeCustomersDataSetCashItemColor);
          TTypeCustomersDataSet.push(TTypeCustomersDataSetCardItem);
          TTypeCustomersDataSet.push(TTypeCustomersDataSetCashItem);
          this._OSalesHistory.TransactionStatusDataSet = TStatusDataSet;
          this._OSalesHistory.TransactionStatusDataSetColors = TStatusDataSetColor;
          this._OSalesHistory.TransactionTypeDataSet = TTypeDataSet;
          this._OSalesHistory.TransactionTypeDataSetColors = TTypeDataSetColor;
          this._OSalesHistory.TransactionTypeCustomersDataSet = TTypeCustomersDataSet;
          this._OSalesHistory.TransactionTypeCustomersDataSetColors = TTypeCustomersDataSetColor;
          this._OSalesHistory.SaleDataSet = TSaleDataSet;
          this._OSalesHistory.SaleColors = TSaleColor;
          this._OSalesHistory.SaleCustomersDataSet = TSaleCustomersDataSet;
          this._OSalesHistory.SaleCustomersColors = TSaleCustomersColor;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetCardTypeSalesSummary(
    UserAccountId,
    UserAccountTypeId,
    SubUserAccountId,
    SubUserAccountTypeId,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    this._CardTypeSalesSummary.CardTypeLabels = [];
    this._CardTypeSalesSummary.CardTypeData = [];
    var Data = {
      Task: "getcardtypesalessummary",
      StartTime: StartTime,
      EndTime: EndTime,
      UserAccountId: UserAccountId,
      UserAccountTypeId: UserAccountTypeId,
      SubUserAccountId: SubUserAccountId,
      SubUserAccountTypeId: SubUserAccountTypeId,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V2.TUCAnalytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._CardTypeSalesSummary = _Response.Result as OCardTypeSalesSummary;
          this._CardTypeSalesSummary.CardTypeLabels = [];
          this._CardTypeSalesSummary.CardTypeData = [];
          if (
            this._CardTypeSalesSummary != undefined &&
            this._CardTypeSalesSummary.CardTypes != undefined
          ) {
            this._CardTypeSalesSummary.CardTypes.forEach((element) => {
              this._CardTypeSalesSummary.CardTypeLabels.push(
                element.CardTypeName
              );
              this._CardTypeSalesSummary.CardTypeData.push(
                element.TotalTransaction
              );
              element.FirstTransactionDate = this.GetDateTimeS(
                element.FirstTransactionDate
              );
              element.LastTransactionDateD = this.GetTimeDifferenceS(
                element.LastTransactionDate,
                moment()
              );
              element.LastTransactionDate = this.GetDateTimeS(
                element.LastTransactionDate
              );
            });
          }
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetAccountOverview(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getaccountoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AccountOverview = _Response.Result as OAccountOverview;
          if (
            this._AccountOverview.SettlementCredit != undefined &&
            this._AccountOverview.SettlementDebit != undefined
          ) {
            this._AccountOverview.SettlementPending =
              this._AccountOverview.SettlementCredit -
              this._AccountOverview.SettlementDebit;
          }
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetAccountOverviewNext(
    UserAccountKey,
    SubUserAccountKey,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getaccountoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AccountOverviewNext = _Response.Result as OAccountOverview;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRewardOverview(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getrewardoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RewardOverview = _Response.Result as ORewardOverview;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRewardOverviewNext(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getrewardoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RewardOverviewNext = _Response.Result as ORewardOverview;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRewardTypeOverview(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getrewardtypeoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RewardTypeOverview = _Response.Result as ORewardTypeOverview;

          if (
            this._RewardTypeOverview.Transactions &&
            this._RewardTypeOverview.Transactions > 0
          ) {
            this._RewardTypeOverview.CardRewardTransactionsPerc =
              (this._RewardTypeOverview.CashRewardTransactions * 100) /
              this._RewardTypeOverview.Transactions;
            this._RewardTypeOverview.CashRewardTransactionsPerc =
              (this._RewardTypeOverview.CardRewardTransactions * 100) /
              this._RewardTypeOverview.Transactions;
          }

          this._RewardTypeOverview.RewardTypeLabels = [];
          this._RewardTypeOverview.RewardTypeData = [];
          this._RewardTypeOverview.RewardTypeLabels.push("Cash");
          this._RewardTypeOverview.RewardTypeLabels.push("Card");
          this._RewardTypeOverview.RewardTypeData.push(
            this._RewardTypeOverview.CashRewardPurchaseAmount
          );
          this._RewardTypeOverview.RewardTypeData.push(
            this._RewardTypeOverview.CardRewardPurchaseAmount
          );

          this._RewardTypeOverview.AppUsersCardTypeLabel = [];
          this._RewardTypeOverview.AppUsersCardTypeDataUsers = [];
          this._RewardTypeOverview.AppUsersCardTypeDataTransactions = [];
          this._RewardTypeOverview.AppUsersCardTypeDataPurchase = [];
          this._RewardTypeOverview.AppUsersCardType.forEach((element) => {
            if (
              this._RewardTypeOverview.Transactions &&
              this._RewardTypeOverview.Transactions > 0
            ) {
              element.TransactionPerc =
                (element.Purchase * 100) /
                this._RewardTypeOverview.Transactions;
            }
            this._RewardTypeOverview.AppUsersCardTypeLabel.push(element.Name);
            this._RewardTypeOverview.AppUsersCardTypeDataUsers.push(
              element.Users
            );
            this._RewardTypeOverview.AppUsersCardTypeDataTransactions.push(
              element.Transactions
            );
            this._RewardTypeOverview.AppUsersCardTypeDataPurchase.push(
              Math.round(element.Purchase)
            );
            element.Purchase = Math.round(element.Purchase);
          });

          // this._RewardTypeOverview.BankCompareLabel = [];
          // this._RewardTypeOverview.BankCompareDataUsers = [];
          // this._RewardTypeOverview.BankCompareDataTransactions = [];
          // this._RewardTypeOverview.BankCompareDataPurchase = [];
          // this._RewardTypeOverview.AppUsersBank.forEach(element => {
          //   this._RewardTypeOverview.BankCompareLabel.push(element.Name);
          //   this._RewardTypeOverview.AppUsersCardTypeDataPurchase.push(
          //     Math.round(element.Purchase)
          //   );
          //   element.Purchase = Math.round(element.Purchase);
          // });

          this._RewardTypeOverview.AppUsersBankLabel = [];
          this._RewardTypeOverview.AppUsersBankDataUsers = [];
          this._RewardTypeOverview.AppUsersBankDataTransactions = [];
          this._RewardTypeOverview.AppUsersBankDataPurchase = [];
          if (this._RewardTypeOverview.AppUsersBank != undefined) {
            this._RewardTypeOverview.AppUsersBank.forEach((element) => {
              element.Purchase = Math.round(element.Purchase);
              if (element.Users > 0) {
                this._RewardTypeOverview.AppUsersBankLabel.push(element.Name);
                this._RewardTypeOverview.AppUsersBankDataUsers.push(
                  element.Users
                );
                this._RewardTypeOverview.AppUsersBankDataTransactions.push(
                  element.Transactions
                );
                this._RewardTypeOverview.AppUsersBankDataPurchase.push(
                  Math.round(element.Purchase)
                );
              }
            });
          }

          this._RewardTypeOverview.AppUsersBankLabel = [];
          this._RewardTypeOverview.AppUsersBankDataUsers = [];
          this._RewardTypeOverview.AppUsersBankDataTransactions = [];
          this._RewardTypeOverview.AppUsersBankDataPurchase = [];
          if (this._RewardTypeOverview.AppUsersBank != undefined) {
            this._RewardTypeOverview.AppUsersBank.forEach((element) => {
              element.Purchase = Math.round(element.Purchase);
              if (element.Users > 0) {
                this._RewardTypeOverview.AppUsersBankLabel.push(element.Name);
                this._RewardTypeOverview.AppUsersBankDataUsers.push(
                  element.Users
                );
                this._RewardTypeOverview.AppUsersBankDataTransactions.push(
                  element.Transactions
                );
                this._RewardTypeOverview.AppUsersBankDataPurchase.push(
                  Math.round(element.Purchase)
                );
              }
            });
          }

          this._RewardTypeOverview.AppUsersBankLabel = [];
          this._RewardTypeOverview.AppUsersBankDataUsers = [];
          this._RewardTypeOverview.AppUsersBankDataTransactions = [];
          this._RewardTypeOverview.AppUsersBankDataPurchase = [];
          if (this._RewardTypeOverview.AppUsersBank != undefined) {
            this._RewardTypeOverview.AppUsersBank.forEach((element) => {
              element.Purchase = Math.round(element.Purchase);
              if (element.Users > 0) {
                this._RewardTypeOverview.AppUsersBankLabel.push(element.Name);
                this._RewardTypeOverview.AppUsersBankDataUsers.push(
                  element.Users
                );
                this._RewardTypeOverview.AppUsersBankDataTransactions.push(
                  element.Transactions
                );
                this._RewardTypeOverview.AppUsersBankDataPurchase.push(
                  Math.round(element.Purchase)
                );
              }
            });
          }
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRewardTypeOverviewNext(
    UserAccountKey,
    SubUserAccountKey,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getrewardtypeoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RewardTypeOverviewNext = _Response.Result as ORewardTypeOverview;
          this._RewardTypeOverviewNext.RewardTypeLabels = [];
          this._RewardTypeOverviewNext.RewardTypeData = [];
          this._RewardTypeOverviewNext.RewardTypeLabels.push("Cash");
          this._RewardTypeOverviewNext.RewardTypeLabels.push("Card");
          this._RewardTypeOverviewNext.RewardTypeData.push(
            this._RewardTypeOverviewNext.CashRewardTransactions
          );
          this._RewardTypeOverviewNext.RewardTypeData.push(
            this._RewardTypeOverviewNext.CardRewardTransactions
          );

          this._RewardTypeOverviewNext.AppUsersCardTypeLabel = [];
          this._RewardTypeOverviewNext.AppUsersCardTypeDataUsers = [];
          this._RewardTypeOverviewNext.AppUsersCardTypeDataTransactions = [];
          this._RewardTypeOverviewNext.AppUsersCardTypeDataPurchase = [];
          this._RewardTypeOverviewNext.AppUsersCardType.forEach((element) => {
            this._RewardTypeOverviewNext.AppUsersCardTypeLabel.push(
              element.Name
            );
            this._RewardTypeOverviewNext.AppUsersCardTypeDataUsers.push(
              element.Users
            );
            this._RewardTypeOverviewNext.AppUsersCardTypeDataTransactions.push(
              element.Transactions
            );
            this._RewardTypeOverviewNext.AppUsersCardTypeDataPurchase.push(
              Math.round(element.Purchase)
            );
            element.Purchase = Math.round(element.Purchase);
          });

          this._RewardTypeOverviewNext.AppUsersBankLabel = [];
          this._RewardTypeOverviewNext.AppUsersBankDataUsers = [];
          this._RewardTypeOverviewNext.AppUsersBankDataTransactions = [];
          this._RewardTypeOverviewNext.AppUsersBankDataPurchase = [];
          this._RewardTypeOverviewNext.AppUsersBank.forEach((element) => {
            element.Purchase = Math.round(element.Purchase);
            if (element.Users > 0) {
              this._RewardTypeOverviewNext.AppUsersBankLabel.push(element.Name);
              this._RewardTypeOverviewNext.AppUsersBankDataUsers.push(
                element.Users
              );
              this._RewardTypeOverviewNext.AppUsersBankDataTransactions.push(
                element.Transactions
              );
              this._RewardTypeOverviewNext.AppUsersBankDataPurchase.push(
                Math.round(element.Purchase)
              );
            }
          });
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRedeemOverview(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getredeemoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RedeemOverview = _Response.Result as ORedeemOverview;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetRedeemOverviewNext(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getredeemoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._RedeemOverviewNext = _Response.Result as ORedeemOverview;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetAppUsersOverview(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getappusersoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      ParentKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AppUsersOverview = _Response.Result as OAppUsersOverview;
          this._AppUsersOverview.AppUsersLabelsByGender = [];
          this._AppUsersOverview.AppUsersDataByGender = [];
          this._AppUsersOverview.AppUsersLabelsByAgeGroup = [];
          this._AppUsersOverview.AppUsersVisitByAgeGroup = [];
          this._AppUsersOverview.AppUsersCountByAgeGroup = [];
          this._AppUsersOverview.AppUsersPurchaseByAgeGroup = [];

          this._AppUsersOverview.AppUsersLabelsByGender.push("Male");
          this._AppUsersOverview.AppUsersLabelsByGender.push("Female");

          this._AppUsersOverview.AppUsersDataByGender.push(
            this._AppUsersOverview.AppUsersMale
          );
          this._AppUsersOverview.AppUsersDataByGender.push(
            this._AppUsersOverview.AppUsersFemale
          );
          this._AppUsersOverview.AppUsersByAgeGroup.forEach((element) => {
            if (element.Name != "Unknown") {
              this._AppUsersOverview.AppUsersLabelsByAgeGroup.push(
                element.Name
              );
              this._AppUsersOverview.AppUsersVisitByAgeGroup.push(
                element.Visits
              );
              this._AppUsersOverview.AppUsersCountByAgeGroup.push(
                element.Users
              );
              this._AppUsersOverview.AppUsersPurchaseByAgeGroup.push(
                Math.round(element.Purchase)
              );
            }
          });
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetAppUsersOverviewNext(
    UserAccountKey,
    SubUserAccountKey,
    StartTime,
    EndTime
  ) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getappusersoverview",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      ParentKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._AppUsersOverviewNext = _Response.Result as OAppUsersOverview;
          this._AppUsersOverviewNext.AppUsersLabelsByGender = [];
          this._AppUsersOverviewNext.AppUsersDataByGender = [];
          this._AppUsersOverviewNext.AppUsersLabelsByAgeGroup = [];
          this._AppUsersOverviewNext.AppUsersVisitByAgeGroup = [];
          this._AppUsersOverviewNext.AppUsersCountByAgeGroup = [];
          this._AppUsersOverviewNext.AppUsersPurchaseByAgeGroup = [];

          this._AppUsersOverviewNext.AppUsersLabelsByGender.push("Male");
          this._AppUsersOverviewNext.AppUsersLabelsByGender.push("Female");

          this._AppUsersOverviewNext.AppUsersDataByGender.push(
            this._AppUsersOverviewNext.AppUsersMale
          );
          this._AppUsersOverviewNext.AppUsersDataByGender.push(
            this._AppUsersOverviewNext.AppUsersFemale
          );
          this._AppUsersOverviewNext.AppUsersByAgeGroup.forEach((element) => {
            if (element.Name != "Unknown") {
              this._AppUsersOverviewNext.AppUsersLabelsByAgeGroup.push(
                element.Name
              );
              this._AppUsersOverviewNext.AppUsersVisitByAgeGroup.push(
                element.Visits
              );
              this._AppUsersOverviewNext.AppUsersCountByAgeGroup.push(
                element.Users
              );
              this._AppUsersOverviewNext.AppUsersPurchaseByAgeGroup.push(
                Math.round(element.Purchase)
              );
            }
          });
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetVisitHistory(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getvisithistory",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          var ChartResponse = _Response.Result;
          this._UserOverviewPlot.HourlyVisitLabel = [];
          this._UserOverviewPlot.HourlyVisitData = [];
          ChartResponse.DateRange.forEach((RangeItem) => {
            this._UserOverviewPlot.HourlyVisitLabel.push(
              this.GetTimeS(RangeItem.StartTime)
            );
          });
          var TPlotDataSet = [];
          if (ChartResponse.DateRange.length > 0) {
            var RangeItem = ChartResponse.DateRange[0].Data;
            RangeItem.forEach((element) => {
              var DataSetItem = {
                label: element.Name,
                data: [],
              };
              TPlotDataSet.push(DataSetItem);
            });
            ChartResponse.DateRange.forEach((RangeItem) => {
              var Data = RangeItem.Data;
              Data.forEach((element) => {
                TPlotDataSet[0].data.push(parseInt(element.Value));
                var DataItem = TPlotDataSet.find(
                  (x) => x.label == element.Name
                );
                if (DataItem != undefined) {
                  DataItem.data.push(parseInt(element.Value));
                }
              });
            });
          }
          this._UserOverviewPlot.HourlyVisitData = TPlotDataSet;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  GetVisitHistoryNext(UserAccountKey, SubUserAccountKey, StartTime, EndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getvisithistory",
      StartTime: StartTime, // new Date(2017, 0, 1, 0, 0, 0, 0),
      EndTime: EndTime, // moment().add(2, 'days'),
      UserAccountKey: UserAccountKey,
      SubUserAccountKey: SubUserAccountKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(this.AppConfig.NetworkLocation.V2.ThankU, Data);
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          var ChartResponse = _Response.Result;
          this._UserOverviewPlot.HourlyVisitNextLabel = [];
          this._UserOverviewPlot.HourlyVisitNextData = [];
          ChartResponse.DateRange.forEach((RangeItem) => {
            this._UserOverviewPlot.HourlyVisitNextLabel.push(
              this.GetTimeS(RangeItem.StartTime)
            );
          });
          var TPlotDataSet = [];
          if (ChartResponse.DateRange.length > 0) {
            var RangeItem = ChartResponse.DateRange[0].Data;
            RangeItem.forEach((element) => {
              var DataSetItem = {
                label: element.Name,
                data: [],
              };
              TPlotDataSet.push(DataSetItem);
            });
            ChartResponse.DateRange.forEach((RangeItem) => {
              var Data = RangeItem.Data;
              Data.forEach((element) => {
                TPlotDataSet[0].data.push(parseInt(element.Value));
                var DataItem = TPlotDataSet.find(
                  (x) => x.label == element.Name
                );
                if (DataItem != undefined) {
                  DataItem.data.push(parseInt(element.Value));
                }
              });
            });
          }
          this._UserOverviewPlot.HourlyVisitNextData = TPlotDataSet;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  // Sub AccountOverview Region Start
  GetSubAccountOverviewLite(TodayStartTime, TodayEndTime) {
    this.IsFormProcessing = true;
    var Data = {
      Task: "getaccountoverview",
      StartTime: TodayStartTime,
      EndTime: TodayEndTime,
      AccountKey: this.UserAccount.AccountKey,
      AccountId: this.UserAccount.AccountId,
      SubAccountId: this.AppConfig.ActiveReferenceId,
      SubAccountKey: this.AppConfig.ActiveReferenceKey,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this.PostData(
      this.AppConfig.NetworkLocation.V3.Analytics,
      Data
    );
    _OResponse.subscribe(
      (_Response) => {
        this.IsFormProcessing = false;
        if (_Response.Status == this.StatusSuccess) {
          this._SubAccountOverview = _Response.Result as OAccountOverview;
        } else {
          this.NotifyError(_Response.Message);
        }
      },
      (_Error) => {
        this.IsFormProcessing = false;
        this.HandleException(_Error);
      }
    );
  }
  // Sub AccountOverview Region End
  SetNetwork() {
    var DeviceSerialNumber = null;
    var _Storage_UserDevice = this.GetStorage(this.AppConfig.Storage.Device);
    if (_Storage_UserOReqH == null) {
      var DeviceInformation = {
        SerialNumber: this.newGuid(),
      };
      this.SaveStorage(this.AppConfig.Storage.Device, DeviceInformation);
    } else {
      DeviceSerialNumber = _Storage_UserDevice.SerialNumber;
    }
    var _Storage_UserOReqH = this.GetStorage(this.AppConfig.Storage.OReqH);
    if (_Storage_UserOReqH == null) {
      var ehcak = btoa(this.AppConfig.AppKey);
      var ehcavk = btoa(this.AppConfig.AppVersionKey);
      var ehctk = btoa("na");
      var ehcudv = btoa(DeviceSerialNumber);
      var ehcudlt = btoa("0");
      var ehcudln = btoa("0");
      var ekuak = "";
      var ehcupk = btoa("0");
      var ehcudk = btoa("0");
      var OReqH = {
        "Content-Type": "application/json; charset=utf-8",
        hcak: ehcak,
        hcavk: ehcavk,
        hctk: ehctk,
        hcudv: ehcudv,
        hcudlt: ehcudlt,
        hcudln: ehcudln,
        hcuak: ekuak,
        hcupk: ehcupk,
        hcudk: ehcudk,
        hcuata: "",
        hcuatp: "",
      };
      this.AppConfig.ClientHeader = OReqH;
      this.SaveStorage(this.AppConfig.Storage.OReqH, OReqH);
    }
  }
  public IconData = {
    url: "assets/img/map-store-marker.png", // gives a data://<value>
    scaledSize: {
      height: 26,
      width: 26,
    },
  };

  PostData(PostLocation, Data): Observable<OResponse> {
    this.AppConfig.ClientHeader.hcuata = "";
    this.AppConfig.ClientHeader.hcuatp = "";
    var NetworkLocation = this.AppConfig.HostConnect + PostLocation + Data.Task;
    this.AppConfig.ClientHeader.hctk = btoa(Data.Task);
    if (
      Data.AuthPin != undefined &&
      Data.AuthPin != null &&
      Data.AuthPin != ""
    ) {
      this.AppConfig.ClientHeader.hcuata = btoa(Data.AuthPin);
    }
    if (
      Data.AuthPassword != undefined &&
      Data.AuthPassword != null &&
      Data.AuthPassword != ""
    ) {
      this.AppConfig.ClientHeader.hcuatp = btoa(Data.AuthPassword);
    }
    let _Headers = new HttpHeaders(this.AppConfig.ClientHeader);
    var ORequest = {
      zx: btoa(JSON.stringify(Data)),
    };
    var RequestData = JSON.stringify(ORequest);
    return this._Http
      .post<ONetworkResponse>(NetworkLocation, RequestData, {
        headers: _Headers,
      })
      .pipe(map((_Response) => JSON.parse(atob(_Response.zx)) as OResponse))
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }
  public ChartConfig = {
    SalesTypeColors: [
      {
        backgroundColor: ["#FFC20A", "#10b759"],
      },
    ],
    SalesTypeCustomersColors: [
      {
        backgroundColor: ["#00aff0", "#990099"],
      },
    ],
    TransactionStatusDataSetColors: [
      {
        backgroundColor: ["#61c03c"],
      },
      {
        backgroundColor: ["#dc3912"],
      },
    ],
    TransactionStatusColors: [
      {
        backgroundColor: ["#00cccc", "#f10075"],
      },
    ],
    CardTypeColors: [
      {
        backgroundColor: ["#ffc107", "#00cccc", "#f10075", "#0168fa"],
      },
    ],
    Plugins: [pluginDataLabels],
    DoughnetChart: {
      Type: "doughnut",
      Options: {
        responsive: true,
        plugins: {
          datalabels: {
            backgroundColor: "#fff",
            color: "#000000bf",
            borderRadius: "2",
            borderWidth: "1",
            borderColor: "transparent",
            anchor: "center",
            align: "center",
            padding: 2,
            opacity: 0.9,
            display: true,
            font: {
              size: 10,
              weight: 500,
            },
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += data;
              });
              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              // return percentage;
              const label = ctx.chart.data.labels[ctx.dataIndex];
              if (label != undefined) {
                return label + "\n" + percentage;
                // return label;
              } else {
                return value;
              }
            },
          },
        },
        legend: {
          display: false,
          position: "right",
        },
        legendCallback: function (chart) {
          // Return the HTML string here.
        },
      },
    },
    LineChart: {
      layout: {
        padding: {
          top: 16,
        },
      },
      Type: "line",
      Options: {
        responsive: true,
        legend: {
          display: true,
          position: "bottom",
        },
        ticks: {
          autoSkip: false,
        },
        scales: {
          xAxes: [
            {
              // categoryPercentage: 0.98,
              // barPercentage: 0.98,
              maxBarThickness: 50,
              categoryPercentage: 0.4,
              barPercentage: 0.4,
              gridLines: {
                stacked: true,
                display: false,
              },
              ticks: {
                autoSkip: false,
                fontSize: 11,
              },
            },
          ],
          yAxes: [
            {
              maxBarThickness: 50,
              categoryPercentage: 0.4,
              barPercentage: 0.4,
              gridLines: {
                stacked: true,
                display: false,
              },
              ticks: {
                beginAtZero: true,
                fontSize: 11,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            backgroundColor: "#ffffff47",
            color: "#798086",
            borderRadius: "2",
            borderWidth: "1",
            borderColor: "transparent",
            anchor: "end",
            align: "end",
            padding: 2,
            font: {
              size: 9,
              weight: 400,
            },
            formatter: (value, ctx) => {
              const label = ctx.chart.data.labels[ctx.dataIndex];
              if (label != undefined) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
            },
          },
        },
      },
    },
    BarChart: {
      layout: {
        padding: {
          top: 16,
        },
      },
      Type: "bar",
      Options: {
        cornerRadius: 20,
        responsive: true,
        legend: {
          display: false,
          position: "right",
        },
        ticks: {
          autoSkip: false,
        },
        scales: {
          xAxes: [
            {
              // stacked: true,
              // categoryPercentage: 0.98,
              // barPercentage: 0.98,
              // maxBarThickness: 30,
              // categoryPercentage: 0.4,
              // barPercentage: 0.4,
              gridLines: {
                stacked: true,
                display: false,
              },
              ticks: {
                autoSkip: false,
                fontSize: 11,
              },
            },
          ],
          yAxes: [
            {
              // maxBarThickness: 30,
              // categoryPercentage: 0.4,
              // barPercentage: 0.4,
              // stacked: true,
              gridLines: {
                stacked: true,
                display: false,
              },
              ticks: {
                beginAtZero: true,
                fontSize: 11,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            backgroundColor: "#ffffff47",
            color: "#798086",
            borderRadius: "2",
            borderWidth: "1",
            borderColor: "transparent",
            anchor: "end",
            align: "end",
            padding: 2,
            font: {
              size: 10,
              weight: 500,
            },
            formatter: (value, ctx) => {
              const label = ctx.chart.data.labels[ctx.dataIndex];
              if (label != undefined) {
                return value;
              } else {
                return value;
              }
            },
          },
        },
      },
    },
  };
  _UserOverviewPlot = {
    PieChartPlugins: [pluginDataLabels],
    StartTime: null,
    EndTime: null,
    GuageLight: {
      Type: "arch",
      Append: "%",
      Cap: "round",
      Thick: 6,
      Size: 55,
      Color: "#ffffff",
    },
    PlotChartHeight: 95,
    ChartTypeDoughnet: "doughnut",
    ChartTypeBar: "bar",
    ChartTypeBarHorizontal: "horizontalBar",
    ChartTypeBarHOptions: {
      layout: {
        padding: {
          right: 16,
        },
      },
      responsive: true,
      legend: {
        display: false,
      },
      ticks: {
        autoSkip: false,
      },
      scales: {
        xAxes: [
          {
            categoryPercentage: 0.1,
            barPercentage: 0.1,
            gridLines: {
              stacked: true,
              display: false,
            },
            ticks: {
              autoSkip: false,
              fontSize: 11,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              stacked: true,
              display: false,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          backgroundColor: "transparent",
          color: "#000000bf",
          borderRadius: "2",
          borderWidth: "1",
          borderColor: "transparent",
          anchor: "end",
          align: "end",
          padding: 2,
          font: {
            size: 10,
            weight: 500,
          },
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            if (label != undefined) {
              return value;
            } else {
              return value;
            }
          },
        },
      },
    },
    ChartTypeBarOptions: {
      layout: {
        padding: {
          top: 12,
        },
      },
      responsive: true,
      legend: {
        display: false,
      },
      ticks: {
        autoSkip: false,
      },
      scales: {
        xAxes: [
          {
            categoryPercentage: 0.98,
            barPercentage: 0.98,
            gridLines: {
              stacked: true,
              display: false,
            },
            ticks: {
              autoSkip: false,
              fontSize: 11,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              stacked: true,
              display: false,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          backgroundColor: "transparent",
          color: "#000000bf",
          borderRadius: "2",
          borderWidth: "1",
          borderColor: "transparent",
          anchor: "end",
          align: "end",
          padding: 2,
          font: {
            size: 10,
            weight: 500,
          },
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            if (label != undefined) {
              return value;
            } else {
              return value;
            }
          },
        },
      },
    },
    ChartTypePieOptions: {
      responsive: true,
      plugins: {
        datalabels: {
          backgroundColor: "transparent",
          color: "#000000bf",
          borderRadius: "2",
          borderWidth: "1",
          borderColor: "transparent",
          anchor: "end",
          align: "end",
          padding: 2,
          display: true,
          font: {
            size: 10,
            weight: 500,
          },
          formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map((data) => {
              sum += data;
            });
            let percentage = ((value * 100) / sum).toFixed(2) + "%";
            // return percentage;
            const label = ctx.chart.data.labels[ctx.dataIndex];
            if (label != undefined) {
              return label + "\n" + percentage;
              // return label;
            } else {
              return value;
            }
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
      },
      legendCallback: function (chart) {
        // Return the HTML string here.
      },
    },
    Colors: [
      {
        backgroundColor: "#907eec",
      },
      {
        backgroundColor: "rgb(0, 194, 146)",
      },
      {
        backgroundColor: "rgb(171, 140, 228)",
      },
      {
        backgroundColor: "rgb(255, 180, 99)",
      },
      {
        backgroundColor: "rgb(3, 169, 243)",
      },
      {
        backgroundColor: "rgb(251, 150, 120)",
      },
    ],
    PieColors: [
      {
        backgroundColor: [
          "rgb(0, 194, 146)",
          "rgb(171, 140, 228)",
          "rgb(255, 180, 99)",
          "rgb(3, 169, 243)",
          "rgb(251, 150, 120)",
        ],
      },
    ],
    ChartTypePlotOptions: {
      fill: false,
      scales: {
        xAxes: [
          {
            gridLines: {
              stacked: true,
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              stacked: true,
              display: false,
            },
          },
        ],
      },
      legend: {
        display: true,
        position: "bottom",
      },
      plugins: {
        datalabels: {
          backgroundColor: "transparent",
          color: "#000000bf",
          borderRadius: "2",
          borderWidth: "1",
          borderColor: "transparent",
          anchor: "end",
          align: "end",
          padding: 2,
          font: {
            size: 11,
            weight: 500,
          },
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            if (label != undefined) {
              return value;
            } else {
              return value;
            }
          },
        },
      },
    },
    RewardTypeLabel: [],
    RewardTypeData: [],
    GenderLabel: [],
    GenderData: [],
    RegSourceLabel: [],
    RegSourceData: [],
    VisitorLabel: [],
    VisitorData: [],
    RewardLabel: [],
    RewardData: [],
    RedeemData: [],
    RedeemLabel: [],
    PaymentData: [],
    PaymentLabel: [],
    RewardPlotLabel: [],
    RewardPlotDataSet: [],
    RedeemPlotLabel: [],
    RedeemPlotDataSet: [],
    VisitorsPlotLabel: [],
    VisitorsPlotDataSet: [],
    SalePlotLabel: [],
    SalePlotDataSet: [],
    CardUserPlotLabel: [],
    CardUserPlotDataSet: [],
    PaymentsPlotLabel: [],
    PaymentsPlotDataSet: [],
    HourlyVisitLabel: [],
    HourlyVisitData: [],
    HourlySalesData: [],
    HourlyVisitNextLabel: [],
    HourlyVisitNextData: [],
  };
  public _CoreHelper: OCoreHelper = {
    ParentCode: null,
    CreateDate: null,
    CreatedByDisplayName: null,
    CreatedByIconUrl: null,
    CreatedByKey: null,
    Description: null,
    IconUrl: null,
    ModifyByDisplayName: null,
    ModifyByIconUrl: null,
    ModifyByKey: null,
    ModifyDate: null,
    Name: null,
    ParentName: null,
    PosterUrl: null,
    Reference: null,
    ReferenceKey: null,
    Sequence: null,
    StatusCode: null,
    StatusId: null,
    StatusName: null,
    SubParentCode: null,
    SubParentName: null,
    SystemName: null,
    TypeName: null,
    Value: null,
    StatusI: null,
    CreateDateS: null,
    ModifyDateS: null,
  };
  public _CoreCommon: OCoreCommon = {
    // LastRequestDateTime: null,
    // AccountDisplayName: null,
    StatusI: null,
    // StatusC:null,
    // StatusB:null,
    Count: 0,
    CreateDate: null,
    CreatedByDisplayName: null,
    CreatedByIconUrl: null,
    CreatedByKey: null,
    Data: null,
    Description: null,
    HelperCode: null,
    HelperName: null,
    IconUrl: null,
    ModifyByDisplayName: null,
    ModifyByIconUrl: null,
    ModifyByKey: null,
    ModifyDate: null,
    Name: null,
    ParentCode: null,
    ParentKey: null,
    ParentName: null,
    PosterUrl: null,
    Reference: null,
    ReferenceKey: null,
    Sequence: null,
    SubItemsCount: null,
    StatusCode: null,
    StatusId: null,
    StatusName: null,
    SubParentCode: null,
    SubParentKey: null,
    SubParentName: null,
    SubValue: null,
    SystemName: null,
    TypeCode: null,
    TypeName: null,
    UserAccountDisplayName: null,
    UserAccountIconUrl: null,
    UserAccountKey: null,
    Value: null,
    CreateDateS: null,
    ModifyDateS: null,
    // ImageUrl: null,
    // IpAddress: null,
    // LogRequest: null,
    // ActiveAppKey: null,
    // ActiveAppVersionKey: null,
  };
  public _SubCoreCommon: OCoreCommon = {
    // LastRequestDateTime: null,
    // AccountDisplayName: null,
    StatusI: null,
    // StatusC:null,
    // StatusB:null,
    Count: 0,
    CreateDate: null,
    CreatedByDisplayName: null,
    CreatedByIconUrl: null,
    CreatedByKey: null,
    Data: null,
    Description: null,
    HelperCode: null,
    HelperName: null,
    IconUrl: null,
    ModifyByDisplayName: null,
    ModifyByIconUrl: null,
    ModifyByKey: null,
    ModifyDate: null,
    Name: null,
    ParentCode: null,
    ParentKey: null,
    SubItemsCount: null,
    ParentName: null,
    PosterUrl: null,
    Reference: null,
    ReferenceKey: null,
    Sequence: null,
    StatusCode: null,
    StatusId: null,
    StatusName: null,
    SubParentCode: null,
    SubParentKey: null,
    SubParentName: null,
    SubValue: null,
    SystemName: null,
    TypeCode: null,
    TypeName: null,
    UserAccountDisplayName: null,
    UserAccountIconUrl: null,
    UserAccountKey: null,
    Value: null,
    CreateDateS: null,
    ModifyDateS: null,
    // ImageUrl: null,
    // IpAddress: null,
    // LogRequest: null,
    // ActiveAppKey: null,
    // ActiveAppVersionKey: null,
  };
  public _CoreParameter: OCoreParameter = {
    ReferenceId: 0,
    CommonParentCode: null,
    CommonParentKey: null,
    CommonParentName: null,
    Count: 0,
    CreateDate: null,
    CreatedByDisplayName: null,
    CreatedByIconUrl: null,
    CreatedByKey: null,
    Data: null,
    Description: null,
    HelperCode: null,
    HelperName: null,
    IconUrl: null,
    ModifyByDisplayName: null,
    SubItemsCount: null,
    ModifyByIconUrl: null,
    ModifyByKey: null,
    ModifyDate: null,
    Name: null,
    ParentCode: null,
    ParentKey: null,
    ParentName: null,
    PosterUrl: null,
    Reference: null,
    ReferenceKey: null,
    Sequence: null,
    StatusCode: null,
    StatusId: null,
    StatusName: null,
    SubCommonParentCode: null,
    SubCommonParentKey: null,
    SubCommonParentName: null,
    SubParentCode: null,
    SubParentKey: null,
    SubParentName: null,
    SubValue: null,
    SystemName: null,
    TypeCode: null,
    TypeName: null,
    UserAccountDisplayName: null,
    UserAccountIconUrl: null,
    UserAccountKey: null,
    Value: null,
    StatusI: null,
    CreateDateS: null,
    ModifyDateS: null,
  };
  public _SubCoreParameter: OCoreParameter = {
    CommonParentCode: null,
    CommonParentKey: null,
    CommonParentName: null,
    Count: 0,
    CreateDate: null,
    CreatedByDisplayName: null,
    CreatedByIconUrl: null,
    CreatedByKey: null,
    Data: null,
    Description: null,
    HelperCode: null,
    HelperName: null,
    IconUrl: null,
    ModifyByDisplayName: null,
    ModifyByIconUrl: null,
    ModifyByKey: null,
    ModifyDate: null,
    Name: null,
    ParentCode: null,
    ParentKey: null,
    ParentName: null,
    PosterUrl: null,
    SubItemsCount: null,
    Reference: null,
    ReferenceKey: null,
    Sequence: null,
    StatusCode: null,
    StatusId: null,
    StatusName: null,
    SubCommonParentCode: null,
    SubCommonParentKey: null,
    SubCommonParentName: null,
    SubParentCode: null,
    SubParentKey: null,
    SubParentName: null,
    SubValue: null,
    SystemName: null,
    TypeCode: null,
    TypeName: null,
    UserAccountDisplayName: null,
    UserAccountIconUrl: null,
    UserAccountKey: null,
    Value: null,
    StatusI: null,
    CreateDateS: null,
    ModifyDateS: null,
  };
  public _UserAccount: OUserDetails = {
    // 
    AddressComponent: null,
    // 
    MerchantDisplayName: null,
    SecondaryEmailAddress: null,
    BankDisplayName: null,
    BankKey: null,
    SubOwnerAddress: null,
    SubOwnerLatitude: null,
    SubOwnerDisplayName: null,
    SubOwnerKey: null,
    SubOwnerLongitude: null,
    AccessPin: null,
    LastLoginDateS: null,
    AppKey: null,
    AppName: null,
    AppVersionKey: null,
    CreateDate: null,
    CreateDateS: null,
    CreatedByDisplayName: null,
    CreatedByIconUrl: null,
    CreatedByKey: null,
    Description: null,
    IconUrl: null,
    ModifyByDisplayName: null,
    ModifyByIconUrl: null,
    ModifyByKey: null,
    ModifyDate: null,
    ModifyDateS: null,
    PosterUrl: null,
    ReferenceKey: null,
    StatusCode: null,
    StatusI: null,
    StatusId: null,
    StatusName: null,
    AccountCode: null,
    AccountOperationTypeCode: null,
    AccountOperationTypeName: null,
    AccountTypeCode: null,
    AccountTypeName: null,
    Address: null,
    AppVersionName: null,
    ApplicationStatusCode: null,
    ApplicationStatusName: null,
    AverageValue: null,
    CityAreaKey: null,
    CityAreaName: null,
    CityKey: null,
    CityName: null,
    ContactNumber: null,
    CountValue: null,
    CountryKey: null,
    CountryName: null,
    DateOfBirth: null,
    DisplayName: null,
    EmailAddress: null,
    EmailVerificationStatus: null,
    EmailVerificationStatusDate: null,
    FirstName: null,
    GenderCode: null,
    GenderName: null,
    LastLoginDate: null,
    LastName: null,
    Latitude: null,
    Longitude: null,
    MobileNumber: null,
    Name: null,
    NumberVerificationStatus: null,
    NumberVerificationStatusDate: null,
    OwnerDisplayName: null,
    OwnerKey: null,
    Password: null,
    Reference: null,
    ReferralCode: null,
    ReferralUrl: null,
    RegionAreaKey: null,
    RegionAreaName: null,
    RegionKey: null,
    RegionName: null,
    RegistrationSourceCode: null,
    RegistrationSourceName: null,
    RequestKey: null,
    RoleKey: null,
    RoleName: null,
    SecondaryPassword: null,
    SystemPassword: null,
    UserName: null,
    WebsiteUrl: null,
  };

  public _RewardOverview: ORewardOverview = {
    RewardAmount: 0,
    RewardChargeAmount: 0,
    RewardPurchaseAmount: 0,
    RewardTransactions: 0,
    RewardUserAmount: 0,
    TUCPlusRewardAmount: 0,
    TUCPlusRewardChargeAmount: 0,
    TUCPlusRewardClaimedAmount: 0,
    TUCPlusRewardClaimedTransactions: 0,
    TUCPlusRewardPurchaseAmount: 0,
    TUCPlusRewardTransactions: 0,
    TUCPlusUserRewardAmount: 0,
  };
  public _RewardOverviewNext: ORewardOverview = {
    RewardAmount: 0,
    RewardChargeAmount: 0,
    RewardPurchaseAmount: 0,
    RewardTransactions: 0,
    RewardUserAmount: 0,
    TUCPlusRewardAmount: 0,
    TUCPlusRewardChargeAmount: 0,
    TUCPlusRewardClaimedAmount: 0,
    TUCPlusRewardClaimedTransactions: 0,
    TUCPlusRewardPurchaseAmount: 0,
    TUCPlusRewardTransactions: 0,
    TUCPlusUserRewardAmount: 0,
  };
  public _RewardTypeOverview: ORewardTypeOverview = {
    CardRewardAmount: 0,
    CardRewardPurchaseAmount: 0,
    CardRewardTransactions: 0,
    CashRewardAmount: 0,
    CashRewardPurchaseAmount: 0,
    CashRewardTransactions: 0,
    OtherRewardAmount: 0,
    OtherRewardPurchaseAmount: 0,
    OtherRewardTransactions: 0,
    Transactions: 0,
    RewardTypeData: [],
    RewardTypeLabels: [],

    CardRewardTransactionsPerc: 0,
    CashRewardTransactionsPerc: 0,

    AppUsersBank: [],
    AppUsersBankLabel: [],
    AppUsersBankDataPurchase: [],
    AppUsersBankDataTransactions: [],
    AppUsersBankDataUsers: [],

    BankCompare: [],
    BankCompareLabel: [],
    BankCompareDataUsers: [],
    BankCompareDataTransactions: [],
    BankCompareDataPurchase: [],

    AppUsersCardType: [],
    AppUsersCardTypeLabel: [],
    AppUsersCardTypeDataPurchase: [],
    AppUsersCardTypeDataTransactions: [],
    AppUsersCardTypeDataUsers: [],
  };
  public _RewardTypeOverviewNext: ORewardTypeOverview = {
    CardRewardAmount: 0,
    CardRewardPurchaseAmount: 0,
    CardRewardTransactions: 0,
    CashRewardAmount: 0,
    CashRewardPurchaseAmount: 0,
    CashRewardTransactions: 0,
    OtherRewardAmount: 0,
    OtherRewardPurchaseAmount: 0,
    OtherRewardTransactions: 0,
    RewardTypeData: [],
    RewardTypeLabels: [],
    Transactions: 0,

    CardRewardTransactionsPerc: 0,
    CashRewardTransactionsPerc: 0,

    AppUsersBank: [],
    AppUsersBankLabel: [],
    AppUsersBankDataPurchase: [],
    AppUsersBankDataTransactions: [],
    AppUsersBankDataUsers: [],

    BankCompare: [],
    BankCompareLabel: [],
    BankCompareDataUsers: [],
    BankCompareDataTransactions: [],
    BankCompareDataPurchase: [],

    AppUsersCardType: [],
    AppUsersCardTypeLabel: [],
    AppUsersCardTypeDataPurchase: [],
    AppUsersCardTypeDataTransactions: [],
    AppUsersCardTypeDataUsers: [],
  };
  public _RedeemOverview: ORedeemOverview = {
    RedeemAmount: 0,
    RedeemPurchaseAmount: 0,
    RedeemTransactions: 0,
  };
  public _RedeemOverviewNext: ORedeemOverview = {
    RedeemAmount: 0,
    RedeemPurchaseAmount: 0,
    RedeemTransactions: 0,
  };
  public _AppUsersOverview: OAppUsersOverview = {
    AppUsers: 0,
    AppUsersByAgeGroup: [],
    AppUsersCountByAgeGroup: [],
    AppUsersLabelsByAgeGroup: [],
    AppUsersPurchaseByAgeGroup: [],
    AppUsersVisitByAgeGroup: [],
    AppUsersDataByGender: [],
    AppUsersLabelsByGender: [],
    AppUsersFemale: 0,
    AppUsersMale: 0,
    AppUsersOther: 0,
    OwnAppUsers: 0,
    ReferralAppUsers: 0,
    RepeatingAppUsers: 0,
    UniqueAppUsers: 0,
  };
  public _AppUsersOverviewNext: OAppUsersOverview = {
    AppUsers: 0,
    AppUsersByAgeGroup: [],
    AppUsersCountByAgeGroup: [],
    AppUsersLabelsByAgeGroup: [],
    AppUsersPurchaseByAgeGroup: [],
    AppUsersVisitByAgeGroup: [],
    AppUsersDataByGender: [],
    AppUsersLabelsByGender: [],
    AppUsersFemale: 0,
    AppUsersMale: 0,
    AppUsersOther: 0,
    OwnAppUsers: 0,
    ReferralAppUsers: 0,
    RepeatingAppUsers: 0,
    UniqueAppUsers: 0,
  };
  public _UserCounts: OUserCounts = {
    RewardPercentage: 0,
    ThankUCashPlus: 0,
    ThankUCashPlusBalanceValidity: 0,
    ThankUCashPlusMinRedeemAmount: 0,
    ThankUCashPlusMinTransferAmount: 0,
    TotalCustomerNew: 0,
    TotalCustomerRepeating: 0,
    TotalCustomerUnique: 0,
    TotalAcquirer: 0,
    TotalCashier: 0,
    TotalCustomer: 0,
    TotalManager: 0,
    TotalMerchant: 0,
    TotalPssp: 0,
    TotalPtsp: 0,
    TotalRm: 0,
    TotalStore: 0,
    TotalTerminal: 0,
  };
  public _CardTypeSalesSummary: OCardTypeSalesSummary = {
    CardTypeLabels: [],
    CardTypeData: [],
    CardTypes: [],
  };
  _TerminalStatusCount: OTerminalStatusCount = {
    Total: 0,
    Unused: 0,
    Active: 0,
    Idle: 0,
    Dead: 0,
  };
  public _SalesOverview: OSalesOverview = {
    TotalTransaction: 0,
    TotalTransactionCustomer: 0,
    TotalTransactionInvoiceAmount: 0,
    TotalSuccessfulTransaction: 0,
    TotalSuccessfulTransactionCustomer: 0,
    TotalSuccessfulTransactionInvoiceAmount: 0,
    TotalFailedTransaction: 0,
    TotalFailedTransactionCustomer: 0,
    TotalFailedTransactionInvoiceAmount: 0,
    TotalCardTransaction: 0,
    TotalCardTransactionInvoiceAmount: 0,
    TotalCashTransaction: 0,
    TotalCashTransactionCustomer: 0,
    TotalCashTransactionInvoiceAmount: 0,

    FirstTransactionDate: null,
    LastTransactionDate: null,
    LastTransactionDateD: "",
    TotalCardTransactionCustomer: 0,
    TransactionTypeData: [],
    TransactionTypeLabels: [],
  };
  public _SalesSummary: OSalesSummary = {
    TotalTransaction: 0,
    TotalTransactionCustomer: 0,
    TotalTransactionInvoiceAmount: 0,
    TotalSuccessfulTransaction: 0,
    TotalSuccessfulTransactionCustomer: 0,
    TotalSuccessfulTransactionInvoiceAmount: 0,
    TotalFailedTransaction: 0,
    TotalFailedTransactionCustomer: 0,
    TotalFailedTransactionInvoiceAmount: 0,
    TotalCardTransaction: 0,
    TotalCardTransactionInvoiceAmount: 0,
    TotalCashTransaction: 0,
    TotalCashTransactionCustomer: 0,
    TotalCashTransactionInvoiceAmount: 0,

    FirstTransactionDate: null,
    LastTransactionDate: null,
    LastTransactionDateD: "",
    TotalCardTransactionCustomer: 0,
    TransactionTypeData: [],
    TransactionTypeLabels: [],
  };
  public _RewardsSummary: ORewardsSummary = {
    TotalTransaction: 0,
    TotalTransactionCustomer: 0,
    TotalTransactionInvoiceAmount: 0,

    TotalRewardTransaction: 0,
    TotalRewardTransactionCustomer: 0,
    TotalRewardTransactionAmount: 0,
    TotalRewardTransactionInvoiceAmount: 0,

    TotalTucRewardTransaction: 0,
    TotalTucRewardTransactionCustomer: 0,
    TotalTucRewardTransactionAmount: 0,
    TotalTucRewardTransactionInvoiceAmount: 0,

    TotalTucPlusRewardTransaction: 0,
    TotalTucPlusRewardTransactionCustomer: 0,
    TotalTucPlusRewardTransactionAmount: 0,
    TotalTucPlusRewardTransactionInvoiceAmount: 0,

    TotalTucPlusRewardClaimTransaction: 0,
    TotalTucPlusRewardClaimTransactionCustomer: 0,
    TotalTucPlusRewardClaimTransactionAmount: 0,
    TotalTucPlusRewardClaimTransactionInvoiceAmount: 0,

    TotalRedeemTransaction: 0,
    TotalRedeemTransactionCustomer: 0,
    TotalRedeemTransactionAmount: 0,
    TotalRedeemTransactionInvoiceAmount: 0,

    FirstTransactionDate: null,
    FirstTransactionDateD: "",
    LastTransactionDate: null,
    LastTransactionDateD: "",

    TucBalance: 0,
    TucPlusBalance: 0,
    TucBalanceCredit: 0,
    TucBalanceDebit: 0,
    TucBalanceTransaction: 0,
    TucPlusBalanceCredit: 0,
    TucPlusBalanceDebit: 0,
    TucPlusBalanceTransaction: 0,
    UserBalance: [],
  };

  public _SubAccountOverview: OAccountOverview = {
    TotalTransactions: 0,
    TotalSale: 0,

    TransactionFailed: 0,
    TransactionSuccess: 0,
    UnusedTerminals: 0,
    ReferredAppUsers: 0,
    ReferredAppUsersPurchase: 0,
    ReferredAppUsersVisit: 0,
    ReferredMerchantSale: 0,
    ReferredMerchantVisits: 0,
    ReferredMerchants: 0,
    ReferredReferredStores: 0,
    TerminalsOverview: [],
    StoresOverview: [],
    Merchants: 0,
    Stores: 0,
    Acquirers: 0,
    Terminals: 0,
    Ptsp: 0,
    Pssp: 0,
    Cashiers: 0,
    RewardCards: 0,
    RewardCardsUsed: 0,
    ThankUCashPlus: 0,
    ThankUCashPlusForMerchant: 0,
    ThankUCashPlusBalanceValidity: 0,
    ThankUCashPlusMinRedeemAmount: 0,
    ThankUCashPlusMinTransferAmount: 0,
    RewardPercentage: 0,
    CommissionPercentage: 0,
    Balance: 0,
    CashRewardAmount: 0,
    CashRewardPurchaseAmount: 0,
    CashRewardTransactions: 0,
    CardRewardAmount: 0,
    CardRewardPurchaseAmount: 0,
    CardRewardTransactions: 0,
    RewardTransactions: 0,
    RewardAmount: 0,
    RewardPurchaseAmount: 0,
    RewardLastTransaction: null,
    RedeemTransactions: 0,
    RedeemAmount: 0,
    RedeemPurchaseAmount: 0,
    RedeemLastTransaction: null,
    Transactions: 0,
    TransactionsPercentage: 0,
    NewTransactions: 0,
    NewTransactionsPercentage: 0,
    RepeatingTransactions: 0,
    RepeatingTransactionsPercentage: 0,
    ReferralTransactions: 0,
    ReferralTransactionsPercentage: 0,
    PurchaseAmount: 0,
    PurchaseAmountPercentage: 0,
    NewPurchaseAmount: 0,
    NewPurchaseAmountPercentage: 0,
    RepeatingPurchaseAmount: 0,
    RepeatingPurchaseAmountPercentage: 0,
    ReferralPurchaseAmount: 0,
    ReferralPurchaseAmountPercentage: 0,
    Commission: 0,
    LastCommissionDate: null,
    IssuerCommissionAmount: 0,
    LastIssuerCommissionDate: null,
    CommissionAmount: 0,
    SettlementCredit: 0,
    SettlementDebit: 0,
    AppUsers: 0,
    AppUsersPercentage: 0,
    OwnAppUsers: 0,
    OwnAppUsersPercentage: 0,
    RepeatingAppUsers: 0,
    RepeatingAppUsersPercentage: 0,
    ReferralAppUsers: 0,
    ReferralAppUsersPercentage: 0,
    AppUsersMale: 0,
    AppUsersFemale: 0,
    LastAppUserDate: null,
    LastTransaction: {
      Amount: 0,
      InvoiceAmount: 0,
      MerchantName: "",
      ReferenceId: 0,
      RewardAmount: 0,
      TransactionDate: null,
      TypeName: null,
    },
    GenderLabel: [],
    GenderData: [],
    RewardTypeLabel: [],
    RewardTypeData: [],
    VisitTrendLabel: [],
    VisitTrendData: [],
    AcquirerAmountDistribution: [],
    Charge: 0,
    ClaimedReward: 0,
    ActiveTerminals: 0,
    AppUsersByAge: [],
    AppUsersOther: 0,
    AppUsersPurchaseByAge: [],
    CardRewardPurchaseAmountOther: 0,
    CardRewardTransactionsOther: 0,
    ClaimedRewardTransations: 0,
    Credit: 0,
    DeadTerminals: 0,
    Debit: 0,
    FrequentBuyers: [],
    IdleTerminals: 0,
    LastTransactionDate: null,
    MerchantOverview: [],
    OtherRewardAmount: 0,
    OtherRewardPurchaseAmount: 0,
    OtherRewardTransactions: 0,
    PosOverview: [],
    RewardChargeAmount: 0,
    RewardUserAmount: 0,
    SettlementPending: 0,
    TUCPlusBalance: 0,
    TUCPlusPurchaseAmount: 0,
    TUCPlusReward: 0,
    TUCPlusRewardAmount: 0,
    TUCPlusRewardChargeAmount: 0,
    TUCPlusRewardClaimedAmount: 0,
    TUCPlusRewardClaimedTransactions: 0,
    TUCPlusRewardPurchaseAmount: 0,
    TUCPlusRewardTransactions: 0,
    TUCPlusUserRewardAmount: 0,
    ThankUAmount: 0,
    TransactionIssuerAmountCredit: 0,
    TransactionIssuerAmountDebit: 0,
    TransactionIssuerChargeCredit: 0,
    TransactionIssuerChargeDebit: 0,
    TransactionIssuerTotalCreditAmount: 0,
    TransactionIssuerTotalDebitAmount: 0,
    UniqueAppUsers: 0,
    UserAmount: 0,
  };

  public _AccountOverview: OAccountOverview = {
    TransactionFailed: 0,
    TransactionSuccess: 0,
    UnusedTerminals: 0,
    ReferredAppUsers: 0,
    ReferredAppUsersPurchase: 0,
    ReferredAppUsersVisit: 0,
    ReferredMerchantSale: 0,
    ReferredMerchantVisits: 0,
    ReferredMerchants: 0,
    ReferredReferredStores: 0,
    TerminalsOverview: [],
    StoresOverview: [],
    Merchants: 0,
    Stores: 0,
    Acquirers: 0,
    Terminals: 0,
    Ptsp: 0,
    Pssp: 0,
    Cashiers: 0,
    RewardCards: 0,
    RewardCardsUsed: 0,
    ThankUCashPlus: 0,
    ThankUCashPlusForMerchant: 0,
    ThankUCashPlusBalanceValidity: 0,
    ThankUCashPlusMinRedeemAmount: 0,
    ThankUCashPlusMinTransferAmount: 0,
    RewardPercentage: 0,
    CommissionPercentage: 0,
    Balance: 0,
    CashRewardAmount: 0,
    CashRewardPurchaseAmount: 0,
    CashRewardTransactions: 0,
    CardRewardAmount: 0,
    CardRewardPurchaseAmount: 0,
    CardRewardTransactions: 0,
    RewardTransactions: 0,
    RewardAmount: 0,
    RewardPurchaseAmount: 0,
    RewardLastTransaction: null,
    RedeemTransactions: 0,
    RedeemAmount: 0,
    RedeemPurchaseAmount: 0,
    RedeemLastTransaction: null,
    Transactions: 0,
    TransactionsPercentage: 0,
    NewTransactions: 0,
    NewTransactionsPercentage: 0,
    RepeatingTransactions: 0,
    RepeatingTransactionsPercentage: 0,
    ReferralTransactions: 0,
    ReferralTransactionsPercentage: 0,
    PurchaseAmount: 0,
    PurchaseAmountPercentage: 0,
    NewPurchaseAmount: 0,
    NewPurchaseAmountPercentage: 0,
    RepeatingPurchaseAmount: 0,
    RepeatingPurchaseAmountPercentage: 0,
    ReferralPurchaseAmount: 0,
    ReferralPurchaseAmountPercentage: 0,
    Commission: 0,
    LastCommissionDate: null,
    IssuerCommissionAmount: 0,
    LastIssuerCommissionDate: null,
    CommissionAmount: 0,
    SettlementCredit: 0,
    SettlementDebit: 0,
    AppUsers: 0,
    AppUsersPercentage: 0,
    OwnAppUsers: 0,
    OwnAppUsersPercentage: 0,
    RepeatingAppUsers: 0,
    RepeatingAppUsersPercentage: 0,
    ReferralAppUsers: 0,
    ReferralAppUsersPercentage: 0,
    AppUsersMale: 0,
    AppUsersFemale: 0,
    LastAppUserDate: null,
    LastTransaction: {
      Amount: 0,
      InvoiceAmount: 0,
      MerchantName: "",
      ReferenceId: 0,
      RewardAmount: 0,
      TransactionDate: null,
      TypeName: null,
    },
    GenderLabel: [],
    GenderData: [],
    RewardTypeLabel: [],
    RewardTypeData: [],
    VisitTrendLabel: [],
    VisitTrendData: [],
    AcquirerAmountDistribution: [],
    Charge: 0,
    ClaimedReward: 0,
    ActiveTerminals: 0,
    AppUsersByAge: [],
    AppUsersOther: 0,
    AppUsersPurchaseByAge: [],
    CardRewardPurchaseAmountOther: 0,
    CardRewardTransactionsOther: 0,
    ClaimedRewardTransations: 0,
    Credit: 0,
    DeadTerminals: 0,
    Debit: 0,
    FrequentBuyers: [],
    IdleTerminals: 0,
    LastTransactionDate: null,
    MerchantOverview: [],
    OtherRewardAmount: 0,
    OtherRewardPurchaseAmount: 0,
    OtherRewardTransactions: 0,
    PosOverview: [],
    RewardChargeAmount: 0,
    RewardUserAmount: 0,
    SettlementPending: 0,
    TUCPlusBalance: 0,
    TUCPlusPurchaseAmount: 0,
    TUCPlusReward: 0,
    TUCPlusRewardAmount: 0,
    TUCPlusRewardChargeAmount: 0,
    TUCPlusRewardClaimedAmount: 0,
    TUCPlusRewardClaimedTransactions: 0,
    TUCPlusRewardPurchaseAmount: 0,
    TUCPlusRewardTransactions: 0,
    TUCPlusUserRewardAmount: 0,
    ThankUAmount: 0,
    TransactionIssuerAmountCredit: 0,
    TransactionIssuerAmountDebit: 0,
    TransactionIssuerChargeCredit: 0,
    TransactionIssuerChargeDebit: 0,
    TransactionIssuerTotalCreditAmount: 0,
    TransactionIssuerTotalDebitAmount: 0,
    UniqueAppUsers: 0,
    UserAmount: 0,
  };
  public _AccountOverviewNext: OAccountOverview = {
    ReferredAppUsers: 0,
    ReferredAppUsersPurchase: 0,
    ReferredAppUsersVisit: 0,
    ReferredMerchantSale: 0,
    ReferredMerchantVisits: 0,
    ReferredMerchants: 0,
    ReferredReferredStores: 0,
    TerminalsOverview: [],
    StoresOverview: [],
    Merchants: 0,
    Stores: 0,
    Acquirers: 0,
    Terminals: 0,
    Ptsp: 0,
    Pssp: 0,
    Cashiers: 0,
    RewardCards: 0,
    RewardCardsUsed: 0,
    ThankUCashPlus: 0,
    ThankUCashPlusForMerchant: 0,
    ThankUCashPlusBalanceValidity: 0,
    ThankUCashPlusMinRedeemAmount: 0,
    ThankUCashPlusMinTransferAmount: 0,
    RewardPercentage: 0,
    CommissionPercentage: 0,
    Balance: 0,
    CashRewardAmount: 0,
    CashRewardPurchaseAmount: 0,
    CashRewardTransactions: 0,
    CardRewardAmount: 0,
    CardRewardPurchaseAmount: 0,
    CardRewardTransactions: 0,
    RewardTransactions: 0,
    RewardAmount: 0,
    RewardPurchaseAmount: 0,
    RewardLastTransaction: null,
    RedeemTransactions: 0,
    RedeemAmount: 0,
    RedeemPurchaseAmount: 0,
    RedeemLastTransaction: null,
    Transactions: 0,
    TransactionsPercentage: 0,
    NewTransactions: 0,
    NewTransactionsPercentage: 0,
    RepeatingTransactions: 0,
    RepeatingTransactionsPercentage: 0,
    ReferralTransactions: 0,
    ReferralTransactionsPercentage: 0,
    PurchaseAmount: 0,
    PurchaseAmountPercentage: 0,
    NewPurchaseAmount: 0,
    NewPurchaseAmountPercentage: 0,
    RepeatingPurchaseAmount: 0,
    RepeatingPurchaseAmountPercentage: 0,
    ReferralPurchaseAmount: 0,
    ReferralPurchaseAmountPercentage: 0,
    Commission: 0,
    LastCommissionDate: null,
    IssuerCommissionAmount: 0,
    LastIssuerCommissionDate: null,
    CommissionAmount: 0,
    SettlementCredit: 0,
    SettlementDebit: 0,
    AppUsers: 0,
    AppUsersPercentage: 0,
    OwnAppUsers: 0,
    OwnAppUsersPercentage: 0,
    RepeatingAppUsers: 0,
    RepeatingAppUsersPercentage: 0,
    ReferralAppUsers: 0,
    ReferralAppUsersPercentage: 0,
    AppUsersMale: 0,
    AppUsersFemale: 0,
    LastAppUserDate: null,
    LastTransaction: {
      Amount: 0,
      InvoiceAmount: 0,
      MerchantName: "",
      ReferenceId: 0,
      RewardAmount: 0,
      TransactionDate: null,
      TypeName: null,
    },
    GenderLabel: [],
    GenderData: [],
    RewardTypeLabel: [],
    RewardTypeData: [],
    VisitTrendLabel: [],
    VisitTrendData: [],
    AcquirerAmountDistribution: [],
    Charge: 0,
    ClaimedReward: 0,
    ActiveTerminals: 0,
    AppUsersByAge: [],
    AppUsersOther: 0,
    AppUsersPurchaseByAge: [],
    CardRewardPurchaseAmountOther: 0,
    CardRewardTransactionsOther: 0,
    ClaimedRewardTransations: 0,
    Credit: 0,
    DeadTerminals: 0,
    Debit: 0,
    FrequentBuyers: [],
    IdleTerminals: 0,
    LastTransactionDate: null,
    MerchantOverview: [],
    OtherRewardAmount: 0,
    OtherRewardPurchaseAmount: 0,
    OtherRewardTransactions: 0,
    PosOverview: [],
    RewardChargeAmount: 0,
    RewardUserAmount: 0,
    SettlementPending: 0,
    TUCPlusBalance: 0,
    TUCPlusPurchaseAmount: 0,
    TUCPlusReward: 0,
    TUCPlusRewardAmount: 0,
    TUCPlusRewardChargeAmount: 0,
    TUCPlusRewardClaimedAmount: 0,
    TUCPlusRewardClaimedTransactions: 0,
    TUCPlusRewardPurchaseAmount: 0,
    TUCPlusRewardTransactions: 0,
    TUCPlusUserRewardAmount: 0,
    ThankUAmount: 0,
    TransactionIssuerAmountCredit: 0,
    TransactionIssuerAmountDebit: 0,
    TransactionIssuerChargeCredit: 0,
    TransactionIssuerChargeDebit: 0,
    TransactionIssuerTotalCreditAmount: 0,
    TransactionIssuerTotalDebitAmount: 0,
    UniqueAppUsers: 0,
    UserAmount: 0,
  };

  public _DateRangeDifference(DatePrev: string, DateNex: string): any {
    var _Diff: any = {};

    var seconds = Math.floor(
      (Date.parse(DateNex).valueOf() - Date.parse(DatePrev).valueOf()) / 1000
    );
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    _Diff.days = Math.floor(hours / 24);

    _Diff.hours = hours - _Diff.days * 24;
    _Diff.minutes = minutes - _Diff.days * 24 * 60 - hours * 60;
    _Diff.seconds =
      seconds - _Diff.days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    // console.log(_Diff);
    return _Diff;
  }

  JData = [
    {
      featureType: "administrative.locality",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "geometry.fill",
      stylers: [
        {
          saturation: "100",
        },
        {
          lightness: "-53",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#efefe9",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#ebe6e1",
        },
        {
          lightness: "0",
        },
      ],
    },
    {
      featureType: "landscape.natural.landcover",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#cdec9d",
        },
      ],
    },
    {
      featureType: "poi.attraction",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.attraction",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ff0000",
        },
      ],
    },
    {
      featureType: "poi.business",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.business",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ee9334",
        },
      ],
    },
    {
      featureType: "poi.government",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
        {
          lightness: "-22",
        },
        {
          saturation: "15",
        },
        {
          color: "#b8a1cd",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on",
        },
        {
          saturation: "0",
        },
        {
          lightness: "-25",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          visibility: "on",
        },
        {
          lightness: "-82",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          saturation: "0",
        },
        {
          lightness: "0",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          saturation: "1",
        },
        {
          lightness: "11",
        },
        {
          gamma: "2.60",
        },
        {
          color: "#aa85e5",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          gamma: "6.13",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
        {
          saturation: "75",
        },
        {
          color: "#818fe6",
        },
        {
          lightness: "14",
        },
        {
          gamma: "1.61",
        },
      ],
    },
  ];

  GoogleAddressArrayToJson(address_components: any): any {
    var addressJson: any = {};
    for (let index = 0; index < address_components.length; index++) {
      const element = address_components[index];
      addressJson[element.types[0]] = element.long_name;
    }
    return addressJson;
  }

  //#region TerminalSorting

  POSActivityStatus: any = {
    Active: "active", //Last Transaction in last 7 days
    Idle: "idle", //Last transcation  before last 7 days
    Dead: "dead", //Last transaction before 1 month
    UnUsed: "unused", //no transaction on this terminal
  };

  SortedTerminals: any = {
    All: [],
    Active: [], //Last Transaction in last 7 days
    Idle: [], //Last transcation  before last 7 days
    Dead: [], //Last transaction before 1 month
    UnUsed: [], //no transaction on this terminal
  };

  ResetSortedTerminals(): void {
    this.SortedTerminals.Active = [];
    this.SortedTerminals.Idle = [];
    this.SortedTerminals.Dead = [];
    this.SortedTerminals.UnUsed = [];
  }

  public SortTerminals(Data: any[]): void {
    this.ResetSortedTerminals();

    this.SortedTerminals.All = Data;

    var SevenDayBefore = moment().startOf("day").subtract(6, "d");
    var ThirtyDayBefore = moment().startOf("day").subtract(30, "d");

    for (let index = 0; index < Data.length; index++) {

      const TClone = cloneDeep(Data[index]);

      var IsNotLastTransactionDate: boolean = (TClone.LastTransactionDate == undefined) || (TClone.LastTransactionDate == null);

      if (IsNotLastTransactionDate) {
        this.SortedTerminals.UnUsed.push(TClone);
        Data[index].ActivityStatus = "Unused";
        continue;
      }

      var TCloneDate = moment(TClone.LastTransactionDate);

      if (TCloneDate.isBefore(ThirtyDayBefore)) {
        this.SortedTerminals.Dead.push(TClone);
        Data[index].ActivityStatus = this.POSActivityStatus.Dead;
      } else if (TCloneDate.isBefore(SevenDayBefore)) {
        this.SortedTerminals.Idle.push(TClone);
        Data[index].ActivityStatus = this.POSActivityStatus.Idle;
      } else if (TCloneDate.isAfter(SevenDayBefore)) {
        this.SortedTerminals.Active.push(TClone);
        Data[index].ActivityStatus = this.POSActivityStatus.Active;
      } else {
        this.SortedTerminals.UnUsed.push(TClone);
        Data[index].ActivityStatus = this.POSActivityStatus.Unused;
      }
    }

  }

  //#endregion

  //#region dispatchevent
  isprocessingtoogle: EventEmitter<number> = new EventEmitter();
  toogleFormProcessingFlag(value: boolean): void {
    this.IsFormProcessing = value;
    this.isprocessingtoogle.emit(0);
  }
  //#endregion

  CalculateLastSevenDay(momentdate: any): any[] {
    var DateArray: any[] = [momentdate.startOf("day")];
    var DateArrayFormat: any[] = [
      this.GetDateTimeSChart(moment().startOf("day")),
    ];

    for (let index = 1; index < 7; index++) {
      var element = DateArray[index - 1].subtract(1, "d");
      DateArray.push(element);

      DateArrayFormat.push(this.GetDateTimeSChart(element._d));
    }

    return DateArrayFormat.reverse();
  }

  //#region calculate intermediate dates

  CalculateIntermediateDate(momentDateStart: any, momentDateEnd: any): any[] {

    var intermediatecount: number = 0;
    var currDate = momentDateStart.startOf("day");
    var lastDate = momentDateEnd.startOf("day");

    //#region push start date

    var DateArray: any[] = [moment(momentDateStart).startOf("day")];
    var DateArrayFormat: any[] = [
      this.GetDateTimeSChart(moment(momentDateStart).endOf("day")),
    ];

    //#endregion

    //#region push internal dates

    while (currDate.add(1, "days").diff(lastDate) < 0) {
      intermediatecount += 1;
      DateArray.push(currDate.clone().toDate());
      DateArrayFormat.push(this.GetDateTimeSChart(currDate.clone().toDate()));
    }

    //#endregion

    //#region push final dates

    if (intermediatecount != 0) {
      DateArray.push(lastDate.clone().toDate());
      DateArrayFormat.push(this.GetDateTimeSChart(lastDate.clone().toDate()));
    } else {
      //if start date and end date are different
      if (momentDateStart.startOf("day").isSame(momentDateEnd.startOf("day"))) {
        DateArray.push(lastDate.clone().toDate());
        DateArrayFormat.push(this.GetDateTimeSChart(lastDate.clone().toDate()));
      }
    }

    //#endregion

    return DateArrayFormat;
  }

  //#endregion

  //#region ReloadEventEmitF
  ReloadEventEmit: EventEmitter<number> = new EventEmitter();

  CloneJson(Json: any): void {
    try {
      return JSON.parse(JSON.stringify(Json));
    } catch (error) {
      return null;
    }
  }

  deepCopy(sourceObject: any, destinationObject: any): void {

    for (const key in sourceObject) {
      if (typeof sourceObject[key] != "object") {
        destinationObject[key] = sourceObject[key];
      } else {
        destinationObject[key] = {};
        this.deepCopy(sourceObject[key], destinationObject[key]);
      }
    }
  }

  TerminalsBubbleSort(terminals: any[]) {
    let swapped;
    do {
      swapped = false;
      for (let i = 0; i < terminals.length; i++) {

        if (terminals[i + 1] == undefined) {
          continue;
        }

        if (moment(terminals[i].LastTransactionDate).isBefore(moment(terminals[i + 1].LastTransactionDate))) {
          let tmp = terminals[i];
          terminals[i] = terminals[i + 1];
          terminals[i + 1] = tmp;
          swapped = true;
        }
      }
    } while (swapped);

    return terminals;
  }

  public DataReloadEligibility(Type: any): boolean {
    return (Type == this.AppConfig.ListToggleOption.Search
      || Type == this.AppConfig.ListToggleOption.Page
      || Type == this.AppConfig.ListToggleOption.Refresh
      || Type == this.AppConfig.ListToggleOption.Limit);
    ;
  }

  // public AreTwoFilterSnapEqual(FilterSnapClone: any, ElementClone: any): boolean {
  //   try {

  //     return JSON.stringify(lodash.omit(FilterSnapClone, lodash.functions(FilterSnapClone))) ==
  //       JSON.stringify(lodash.omit(ElementClone, lodash.functions(ElementClone)));
  //   } catch (error) {
  //     return false;
  //   }
  // }

  // public AreTwoFilterSnapEqual(FilterSnapClone: any, ElementClone: any): boolean {
  //   try {

  //     var compare = jsonDiff.diff(lodash.omit(FilterSnapClone, lodash.functions(FilterSnapClone)), lodash.omit(ElementClone, lodash.functions(ElementClone)));

  //     if (compare == undefined) { return true; }
  //     else { return false; }

  //   } catch (error) {
  //     return false;
  //   }
  // }


  public AreTwoFilterSnapEqual(FilterSnapClone: any, ElementClone: any, print?: string): boolean {
    // console.log(print);

    var FilterSnapClone_C = cloneDeep(FilterSnapClone);
    var ElementClone_C = cloneDeep(ElementClone);

    FilterSnapClone_C.RefreshCount = 0;
    ElementClone_C.RefreshCount = 0;

    FilterSnapClone_C.RefreshData = undefined;
    ElementClone_C.RefreshData = undefined;

    FilterSnapClone_C.TotalRecords = 0;
    ElementClone_C.TotalRecords = 0;

    FilterSnapClone_C.SearchBaseConditions = undefined;
    ElementClone_C.SearchBaseConditions = undefined;

    // ElementClone_C.StatusName = undefined;
    // FilterSnapClone_C.StatusName = undefined;

    if (FilterSnapClone_C.Sort) {
      FilterSnapClone_C.Sort.SortDefaultName = undefined;
    }
    if (ElementClone_C.Sort) {
      ElementClone_C.Sort.SortDefaultName = undefined;
    }

    try {

      var compare = jsonDiff.diff(lodash.omit(FilterSnapClone_C, lodash.functions(FilterSnapClone_C)), lodash.omit(ElementClone_C, lodash.functions(ElementClone_C)));
      if (compare == undefined) {
        // console.log('Found Equal');
        return true;
      }
      else {
        // console.log('Found Different', compare);
        return false;
      }

    } catch (error) {
      return false;
    }
  }


  // StopClickPropogation(): void {
  //   $(".dropdown-menu").click(() => {
  //     event.stopPropagation();
  //   });
  // };

  // StopClickPropogationTarget(): void {
  //   $(".target").click(() => {
  //     event.stopPropagation();
  //   });
  // };




  StopClickPropogation(): void {
    $(".dropdown-menu").click(() => {
      event.stopPropagation();
    });

    $(".select2").click(() => {
      setTimeout(() => {
        this.StopClickPropogationSearch();
      }, 50);
    });
  };


  StopClickPropogationTarget(): void {
    $(".target").click(() => {
      event.stopPropagation();
    });
  };

  StopClickPropogationSearch(): void {
    $(".select2-search__field").click(() => {
      event.stopPropagation();
    });
  };

}

interface ONetworkResponse {
  zx: string;
  fx: string;
  vx: string;
}
