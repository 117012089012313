import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../../service/service';
import { Address } from "ngx-google-places-autocomplete/objects/address";
import {
    HttpClientModule,
    HttpClient,
    HttpHeaders,
    HttpErrorResponse
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
@Component({
    selector: 'registerreward',
    templateUrl: './registerreward.component.html',
})
export class RegisterRewardComponent implements OnInit {
    public IsMobileNumber1 = true;
    public MobileNumber1 = null;
    public MobileNumber1Message = null;
    public MobileNumber2 = null;
    public MobileNumber3 = null;
    Form_AddUser_Processing = false;
    Form_AddUser: FormGroup;
    constructor(
        private _Http: HttpClient,
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
    ) {

    }
    ngOnInit() {
        var smsC = this._HelperService.GetStorage(this._HelperService.UserAccount.AccountCode);
        if (smsC != null) {
            this.Attempts = smsC;
        }
    }

    public Attempts = 0;
    RewardCustomer1() {
        var smsC = this._HelperService.GetStorage(this._HelperService.UserAccount.AccountCode);
        if (smsC != null) {
            this.Attempts = smsC;
        }
        if (this.Attempts < 3) {
            if (this.MobileNumber1 == undefined && this.MobileNumber1 == null && this.MobileNumber1 == '') {
                this._HelperService.NotifyError("Please enter mobile number 1")
            }
            else {
                this.Form_AddUser_Processing = true;
                this.MobileNumber1Message = "Sending request...";
                this._HelperService.IsFormProcessing = true;
                var pData1 =
                {
                    MerchantId: this._HelperService.UserAccount.AccountCode,
                };
                let _OResponseConnect: Observable<OResponse>;
                _OResponseConnect = this.ConnectMerchant(pData1);
                _OResponseConnect.subscribe(
                    _ResponseConnect => {
                        this._HelperService.IsFormProcessing = false;
                        console.log(_ResponseConnect);
                        if (_ResponseConnect.Status == this._HelperService.StatusSuccess) {
                            this.MobileNumber1Message = "Validating mobile number...";
                            var pDataGetBalance =
                            {
                                MerchantId: this._HelperService.UserAccount.AccountCode,
                                ReferenceNumber: this.MobileNumber1 + " " + this._HelperService.UserAccount.AccountCode,
                                MobileNumber: this.MobileNumber1,
                            };
                            let _OResponseGetBalance: Observable<OResponse>;
                            _OResponseGetBalance = this.GetBalance(pDataGetBalance);
                            _OResponseGetBalance.subscribe(
                                _ResponseGetBalance => {
                                    if (_ResponseGetBalance.Status == this._HelperService.StatusSuccess) {
                                        console.log(_ResponseGetBalance);
                                        if (_ResponseGetBalance.Result != undefined && _ResponseGetBalance.Result != null && _ResponseGetBalance.Result.Balance != undefined) {
                                            this.Form_AddUser_Processing = false;
                                            this.MobileNumber1Message = "Mobile number already registered with ThankUCash. Please use other number ";
                                            this._HelperService.NotifyError("Mobile number already registered with ThankUCash. Please use other number");
                                        }
                                        else {
                                            this.MobileNumber1Message = "Rewarding mobile number ...";
                                            var pData1 =
                                            {
                                                MerchantId: this._HelperService.UserAccount.AccountCode,
                                                ReferenceNumber: this.MobileNumber1 + "_" + this._HelperService.UserAccount.AccountCode,
                                                MobileNumber: this.MobileNumber1,
                                                InvoiceAmount: 100000,
                                                TransactionMode: "cash",
                                            };
                                            let _OResponse: Observable<OResponse>;
                                            _OResponse = this.PostReward(pData1);
                                            _OResponse.subscribe(
                                                _Response => {
                                                    if (_Response.Status == this._HelperService.StatusSuccess) {
                                                        console.log(_Response);
                                                        var smsC = this._HelperService.GetStorage(this._HelperService.UserAccount.AccountCode);
                                                        if (smsC != null) {
                                                            this.Attempts = smsC + 1;
                                                            this._HelperService.SaveStorage(this._HelperService.UserAccount.AccountCode, smsC + 1);
                                                            if (this.Attempts == 3) {
                                                                window.location.href = this._HelperService.AppConfig.Pages.System.MerchantSetupComplete;
                                                            }
                                                        }
                                                        else {
                                                            this.Attempts = 1;
                                                            this._HelperService.SaveStorage(this._HelperService.UserAccount.AccountCode, 1);
                                                        }
                                                        this.MobileNumber1 = "";
                                                        this.Form_AddUser_Processing = false;
                                                        this.MobileNumber1Message = "Customer rewarded with N" + _Response.Result.RewardAmount / 100 + " for the purchase of N" + _Response.Result.InvoiceAmount / 100 + " successfully";
                                                        this._HelperService.NotifySuccess("Customer rewarded with N" + _Response.Result.RewardAmount / 100 + " for the purchase of N" + _Response.Result.InvoiceAmount / 100 + " successfully");
                                                    }
                                                    else {
                                                        this.Form_AddUser_Processing = false;
                                                        this.MobileNumber1Message = _Response.Message;
                                                        this._HelperService.NotifyError(_Response.Message);
                                                    }
                                                },
                                                _Error => {
                                                    this.Form_AddUser_Processing = false;
                                                    console.log(_Error);
                                                    //this._HelperService.HandleException(_Error);
                                                });
                                        }
                                    }
                                    else {
                                        this.Form_AddUser_Processing = false;
                                        this._HelperService.NotifyError(_ResponseGetBalance.Message);
                                    }
                                },
                                _Error => {
                                    this.Form_AddUser_Processing = false;
                                    console.log(_Error);
                                });
                        }
                        else {
                            this.Form_AddUser_Processing = false;
                            this.MobileNumber1Message = "Unable to validate your account";
                            this._HelperService.NotifyError(_ResponseConnect.Message);
                        }
                    },
                    _ErrorConnect => {
                        console.log(_ErrorConnect);
                        this.Form_AddUser_Processing = false;
                        //this._HelperService.HandleException(_Error);
                    });
            }
        }
        else {
            this._HelperService.NotifyError("You have used all test rewards.")
        }
    }

    Skip() {
        window.location.href = this._HelperService.AppConfig.Pages.System.MerchantSetupComplete;
    }
    ConnectMerchant(Data): Observable<OResponse> {
        var Url = "https://testapi.thankucash.com/api/v1/thankuconnect/connectaccount";
        var Headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "NTBlYWE2ZDY3YzUzMjUzYWM0ZjZjNzNkM2QzOGQ2Yjc2ZTM2N2Q2ZQ=="
        };
        let _Headers = new HttpHeaders(Headers);
        return this._Http
            .post<any>(Url, Data, {
                headers: _Headers
            })
            .pipe(map(_Response => JSON.parse(_Response)))
            .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
    }
    GetBalance(Data): Observable<OResponse> {
        var Url = "https://testapi.thankucash.com/api/v1/thankuconnect/getbalance";
        var Headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "NTBlYWE2ZDY3YzUzMjUzYWM0ZjZjNzNkM2QzOGQ2Yjc2ZTM2N2Q2ZQ=="
        };
        let _Headers = new HttpHeaders(Headers);
        return this._Http
            .post<any>(Url, Data, {
                headers: _Headers
            })
            .pipe(map(_Response => JSON.parse(_Response)))
            .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
    }
    PostReward(Data): Observable<OResponse> {
        var Url = "https://testapi.thankucash.com/api/v1/thankuconnect/reward";
        var Headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "NTBlYWE2ZDY3YzUzMjUzYWM0ZjZjNzNkM2QzOGQ2Yjc2ZTM2N2Q2ZQ=="
        };
        let _Headers = new HttpHeaders(Headers);
        return this._Http
            .post<any>(Url, Data, {
                headers: _Headers
            })
            .pipe(map(_Response => JSON.parse(_Response)))
            .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
    }
}
