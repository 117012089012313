import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { HelperService } from './service/service';
import { AccessComponent } from './auth/access/access.component';
import { ForgotPasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './auth/resetpassword/resetpassword.component';
import { RegisterComponent } from './auth/onboarding/register/register.component';
import { RegisterStoreComponent } from './auth/onboarding/registerstore/registerstore.component';
import { RegisterRewardComponent } from './auth/onboarding/registerreward/registerreward.component';
import { RegisterCompleteComponent } from './auth/onboarding/registercomplete/registercomplete.component';

const _Routes: Routes = [
  { path: '', redirectTo: 'account/login', pathMatch: 'full' },
  { path: 'login', redirectTo: 'account/login', pathMatch: 'full' },
  { path: 'forgotpassword', redirectTo: 'account/forgotpassword', pathMatch: 'full' },
  { path: 'resetpassword/:referencekey', redirectTo: 'account/resetpassword', pathMatch: 'full' },
  { path: 'resetpassword/:referenceid/:referencekey/:code', redirectTo: 'account/resetpassword/:referenceid/:referencekey/:code', pathMatch: 'full' },
  { path: 'access/:referencekey/:username', component: AccessComponent },
  { path: 'login', component: LoginComponent },
  { path: 'account/login', component: LoginComponent },
  { path: 'account/register', component: RegisterComponent },
  { path: 'account/setup', component: RegisterStoreComponent },
  { path: 'account/setupcomplete', component: RegisterCompleteComponent },
  { path: 'account/reward', component: RegisterRewardComponent },
  { path: 'account/forgotpassword', component: ForgotPasswordComponent },
  { path: 'account/resetpassword/:referencekey', component: ResetPasswordComponent },
  { path: 'account/resetpassword/:referenceid/:referencekey/:code', component: ResetPasswordComponent },
  { path: 'resetpassword/:referencekey', component: ResetPasswordComponent },
  { canActivate: [HelperService], path: '', loadChildren: './panels/acquirer/acquirer.module#TUAcquirerModule' },
  { canActivate: [HelperService], path: '', loadChildren: './panels/rm/rm.module#TURmModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(_Routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
