export class OResponse {
  public Status: string;
  public Message: string;
  public ResponseCode: string;
  public Result: any;
}

export class OListResponse {
  Data: [any];
  Offset: number = 0;
  Limit: number = 0;
  TotalRecords: number = 0;
}
export class OStorageContent {
  public Name: string;
  public Content: string;
  public Extension: string;
  public TypeCode: string;
  public Width: number = 0;
  public Height: number = 0;
}

export class OSelect {
  public Task: string;
  public Location: string;
  public SearchCondition?: string;
  public SortCondition?: string[];
  public Fields: OSelectField[];
  public ReferenceId?: number;
  public ReferenceKey?: string;
}

export class OSelectField {
  public SystemName: string;
  public Type: string;
  public Id?: boolean;
  public Text?: boolean;
  public SearchCondition?: string;
  public SearchValue?: string;
}

export class OAccessUser {
  public Name: string;
  public FirstName: string;
  public LastName: string;
  public UserName: string;
  public EmailAddress: string;
  public ContactNumber: string;
  public MobileNumber: string;

  public Gender: string;
  public GenderCode: string;

  public DateOfBirth: Date;

  public Address: string;
  public AddressLatitude: number = 0;
  public AddressLongitude: number = 0;

  public EmailVerificationStatus: number = 0;
  public ContactNumberVerificationStatus: number = 0;
}
export class OAccessUserAccount {
  public AccountId: number = 0;
  public DisplayName: string;
  public AccountKey: string;
  public IsTucPlusEnabled: boolean;
  public AccountType: string;
  public AccountTypeCode: string;
  public AccountCode: string;
  public EmailAddress: string;
  public ReferralUrl: string;
  public ReferralCode: string;
  public RoleKey: string;
  public RoleName: string;
  public IconUrl: string;
  public PosterUrl: string;
  public MerchantIconUrl: string;
  public IsAccessPinSet: number = 0;


  public CreateDate: string;
  public CreateDateS: string;
}
export class OAccessUserAccountOwner {
  public AccountTypeCode: number = 0;
  public AccountId: number = 0;
  public AccountKey: string;
  public DisplayName: string;
  public Name: string;
  public AccountCode: string;
  public IconUrl: string;
  public IsTucPlusEnabled: boolean;
}
export class OAccessUserCountry {
  // 
  public CountryId: number;
  // 

  public CountryKey: string;
  public CurrencyCode: string;
  public CountryName: string;
  public CountryIso: string;
  public CountryIsd: string;
  public CurrencyName: string;
  public CurrencyNotation: string;
  public CurrencySymbol: string;
}
export class OAccessUserAccountRole {
  public Name: string;
  public SystemName: string;
  public RolePermissions: Array<OAccessUserAccountRolePermission>;
}
export class OAccessUserAccountRolePermission {
  public Name: string;
  public SystemName: string;
  public IsDefault: number = 0;
  public IsAccessPinRequired: number = 0;
  public IsPasswordRequired: number = 0;
}

export class OAccessUserLocation {
  public City: string;

  public CountryCode: string;
  public CountryName: string;

  public RegionCode: string;
  public RegionName: string;

  public ZipCode: string;
  public TimeZone: string;
  public Latitude: string;
  public Longitude: string;

  public UpdateTime: string;
}
export class OAccessUserDevice {
  public SerialNumber: string;
}

export class OList {
  // 
  // public MobileNumber: string;
  // 
  public Id: string;
  public Filters?: any[];
  public Sort: OListSort;
  public ListType?: number = 0;
  public Task: string;
  public Location: string;
  public Title: string = "List";
  public TableFields: OListField[];
  public ReferenceId?: number = null;
  public SubReferenceId?: number = null;
  public SubReferenceKey?: string = null;
  public ReferenceKey?: string = null;
  public Type?: string = null;
  public RefreshCount?: boolean = true;
  public TitleResourceId?: string = null;
  public RefreshData?: boolean = false;
  public IsDownload?: boolean = false;
  public ToggleFilter?: boolean = false;
  public ActivePage?: number = 1;
  public PageRecordLimit?: number = 10;
  public TotalRecords?: number = 0;
  public ShowingStart?: number = 0;
  public ShowingEnd?: number = 0;
  public StartTime?: any = null;
  public EndTime?: any = null;
  public StartDate?: any = null;
  public EndDate?: any = null;
  public SearchParameter?: string;
  public SearchCondition?: string;
  public SearchBaseCondition?: string;
  public SearchBaseConditions?: any[];
  public ColumnSearchParameter?: string;
  public AvailableSearchColumns?: any[];
  public SearchColumns?: any[];
  public Data?: any[];
  public StatusType?: string = "default";
  public StatusName?: string = "default";

  public Status?: number = 0;
  public StatusOptions?: any[];
  public VisibleHeaders?: any[];
  public Visiblity?: any[];
  public VisiblityOptions?: any[];
  public SortExpression?: string;
  public SortExpressionOption?: any[];
  public DefaultSortExpression?: string;
  public OriginalResponse?: any;
  public AccountId?: number = null;
  public AccountKey?: string = null;
  public SalesRange?: any = null;
}

export class OListSort {
  public SortDefaultName: string;
  public SortDefaultColumn: string;
  public SortDefaultOrder?: string = "desc";
  public SortName?: string;
  public SortOrder?: string = "desc";
  public SortColumn?: string;
  public SortOptions?: any[];
}

export class OListField {
  public DefaultValue?: string = "--";
  public DisplayName: string;
  public DownloadDisplayName?: string;
  public SystemName: string;
  public Content?: string;
  public DataType: string = "text";
  public Class?: string = "";
  public ResourceId?: string = "";
  public Sort?: boolean = true;
  public Show?: boolean = true;
  public Search?: boolean = true;
  public NavigateLink?: string = "";
  public NavigateField?: string = "";
  public IsDateSearchField?: boolean = false;
  public TextCenter?: string = "";



}

export class OCoreHelper {
  public Reference: string;
  public ReferenceKey: string;
  public SystemName: string;
  public ParentCode: string;
  public ParentName: string;
  public SubParentCode: string;
  public SubParentName: string;
  public Name: string;
  public Value: string;
  public TypeName: string;
  public Description: string;
  public Sequence: number = 0;
  public IconUrl: string;
  public PosterUrl: string;
  public CreateDate: Date;
  public CreatedByKey: string;
  public CreatedByDisplayName: string;
  public CreatedByIconUrl: string;
  public ModifyDate: Date;
  public ModifyByKey: string;
  public ModifyByDisplayName: string;
  public ModifyByIconUrl: string;
  public StatusId: number = 0;
  public StatusCode: string;
  public StatusName: string;
  public StatusI: string;
  public CreateDateS: string;
  public ModifyDateS: string;
}

export class OCoreCommon {
  public Reference: string;
  public ReferenceKey: string;
  public SystemName: string;
  public UserAccountKey: string;
  public UserAccountDisplayName: string;
  public UserAccountIconUrl: string;
  public TypeCode: string;
  public TypeName: string;
  public HelperCode: string;
  public HelperName: string;
  public ParentKey: string;
  public ParentCode: string;
  public ParentName: string;
  public SubParentKey: string;
  public SubParentCode: string;
  public SubParentName: string;
  public Name: string;
  public Value: string;
  public SubValue: string;
  public Description: string;
  public Data: string;
  public Sequence: number = 0;
  public Count: number = 0;
  public SubItemsCount: number = 0;
  public IconUrl: string;
  public PosterUrl: string;
  public CreateDate: Date;
  public CreatedByKey: string;
  public CreatedByDisplayName: string;
  public CreatedByIconUrl: string;
  public ModifyDate: Date;
  public ModifyByKey: string;
  public ModifyByDisplayName: string;
  public ModifyByIconUrl: string;
  public StatusId: number = 0;
  public StatusCode: string;
  public StatusName: string;
  public StatusI: string;
  public CreateDateS: string;
  public ModifyDateS: string;
}

export class OCoreParameter {
  public ReferenceId?: number;
  public Reference: string;
  public ReferenceKey: string;
  public SystemName: string;
  public TypeCode: string;
  public TypeName: string;
  public ParentKey: string;
  public ParentCode: string;
  public ParentName: string;
  public SubParentKey: string;
  public SubParentCode: string;
  public SubParentName: string;
  public SubItemsCount: null;

  public CommonParentKey: string;
  public CommonParentCode: string;
  public CommonParentName: string;
  public SubCommonParentKey: string;
  public SubCommonParentCode: string;
  public SubCommonParentName: string;
  public UserAccountKey: string;
  public UserAccountDisplayName: string;
  public UserAccountIconUrl: string;
  public HelperCode: string;
  public HelperName: string;
  public Name: string;
  public Value: string;
  public SubValue: string;
  public Description: string;
  public Data: string;
  public Sequence: number = 0;
  public Count: number = 0;
  public IconUrl: string;
  public PosterUrl: string;
  public CreateDate: Date;
  public CreatedByKey: string;
  public CreatedByDisplayName: string;
  public CreatedByIconUrl: string;
  public ModifyDate: Date;
  public ModifyByKey: string;
  public ModifyByDisplayName: string;
  public ModifyByIconUrl: string;
  public StatusId: number = 0;
  public StatusCode: string;
  public StatusName: string;
  public StatusI: string;
  public CreateDateS: string;
  public ModifyDateS: string;
}

export class OUserDetails {
  // 
  public AddressComponent?: string;
  // 
  public SecondaryEmailAddress?: string;
  public SubAccounts?: number = 0;
  public ReferenceId?: number = 0;
  public Reference: string;
  public ReferenceKey: string;
  public AccountTypeCode: string;
  public AccountTypeName: string;
  public AccountOperationTypeCode: string;
  public AccountOperationTypeName: string;
  public OwnerKey: string;
  public OwnerDisplayName: string;
  public OwnerIconUrl?: string;

  public SubOwnerLatitude: string;
  public SubOwnerLongitude: string;
  public SubOwnerKey: string;
  public SubOwnerDisplayName: string;
  public SubOwnerAddress: string;

  public BankKey: string;
  public BankDisplayName: string;

  public DisplayName: string;
  public AccessPin: string;
  public AccountCode: string;
  public IconUrl: string;
  public PosterUrl: string;
  public ReferralCode: string;
  public ReferralUrl: string;
  public Description: string;
  public RegistrationSourceCode: string;
  public RegistrationSourceName: string;
  public RoleKey: string;
  public RoleName: string;
  public AppKey: string;
  public AppName: string;
  public AppVersionKey: string;
  public AppVersionName: string;
  public ApplicationStatusCode: string;
  public ApplicationStatusName: string;
  public LastLoginDate: Date;
  public LastLoginDateS: string;
  public RequestKey: string;
  public CountValue: string;
  public AverageValue: string;
  public UserName: string;
  public Password: string;
  public SecondaryPassword: string;
  public SystemPassword: string;
  public Name: string;
  public FirstName: string;
  public LastName: string;
  public MobileNumber: string;
  public ContactNumber: string;
  public EmailAddress: string;
  public GenderCode: string;
  public GenderName: string;
  public DateOfBirth: Date;
  public Address: string;
  public Latitude: number = 0;
  public Longitude: number = 0;
  public CountryKey: string;
  public CountryName: string;
  public RegionKey: string;
  public RegionName: string;
  public RegionAreaKey: string;
  public RegionAreaName: string;
  public CityKey: string;
  public CityName: string;
  public CityAreaKey: string;
  public CityAreaName: string;
  public WebsiteUrl: string;
  public EmailVerificationStatus: number = 0;
  public EmailVerificationStatusDate: Date;
  public NumberVerificationStatus: number = 0;
  public NumberVerificationStatusDate: Date;
  public CreateDate: Date;
  public CreatedByKey: string;
  public CreatedByDisplayName: string;
  public CreatedByDisplayNameShort?: string;
  public CreatedByIconUrl: string;
  public ModifyDate: Date;
  public ModifyByKey: string;
  public ModifyByDisplayName: string;
  public ModifyByDisplayNameShort?: string;
  public ModifyByIconUrl: string;
  public StatusId: number = 0;
  public StatusCode: string;
  public StatusName: string;
  public StatusI: string;
  public StatusB?: string;
  public StatusC?: string;
  public CreateDateS: string;
  public ModifyDateS: string;

  public MerchantDisplayName?: string;
  public MerchantKey?: string;


}

export class OOverview {
  public Merchants: number = 0;
  public Stores: number = 0;
  public Acquirers: number = 0;
  public Terminals: number = 0;
  public ActiveTerminals: number = 0;
  public DeadTerminals: number = 0;
  public IdleTerminals: number = 0;
  public Ptsp: number = 0;
  public Pssp: number = 0;
  public Cashiers: number = 0;
  public RewardCards: number = 0;
  public RewardCardsUsed: number = 0;
  public ThankUCashPlus: number = 0;
  public ThankUCashPlusForMerchant: number = 0;
  public ThankUCashPlusBalanceValidity: number = 0;
  public ThankUCashPlusMinRedeemAmount: number = 0;
  public ThankUCashPlusMinTransferAmount: number = 0;
  public RewardPercentage: number = 0;
  public CommissionPercentage: number = 0;
  public Balance: number = 0;
  public ClaimedReward: number = 0;
  public ClaimedRewardTransations: number = 0;
  public Charge: number = 0;
  public CashRewardAmount: number = 0;
  public CashRewardPurchaseAmount: number = 0;
  public CashRewardTransactions: number = 0;
  public CardRewardAmount: number = 0;
  public CardRewardPurchaseAmount: number = 0;
  public CardRewardPurchaseAmountOther: number = 0;
  public CardRewardTransactions: number = 0;
  public CardRewardTransactionsOther: number = 0;
  public OtherRewardAmount: number = 0;
  public OtherRewardPurchaseAmount: number = 0;
  public OtherRewardTransactions: number = 0;
  public RewardTransactions: number = 0;
  public RewardAmount: number = 0;
  public RewardUserAmount: number = 0;
  public RewardChargeAmount: number = 0;
  public RewardPurchaseAmount: number = 0;
  public RewardLastTransaction: Date;
  public RedeemTransactions: number = 0;
  public RedeemAmount: number = 0;
  public RedeemPurchaseAmount: number = 0;
  public RedeemLastTransaction: Date;
  public Credit: number = 0;
  public Debit: number = 0;
  public Transactions: number = 0;
  public TransactionsPercentage: number = 0;
  public NewTransactions: number = 0;
  public NewTransactionsPercentage: number = 0;
  public RepeatingTransactions: number = 0;
  public RepeatingTransactionsPercentage: number = 0;
  public ReferralTransactions: number = 0;
  public ReferralTransactionsPercentage: number = 0;
  public PurchaseAmount: number = 0;
  public PurchaseAmountPercentage: number = 0;
  public NewPurchaseAmount: number = 0;
  public NewPurchaseAmountPercentage: number = 0;
  public RepeatingPurchaseAmount: number = 0;
  public RepeatingPurchaseAmountPercentage: number = 0;
  public ReferralPurchaseAmount: number = 0;
  public ReferralPurchaseAmountPercentage: number = 0;
  public TUCPlusRewardTransactions: number = 0;
  public TUCPlusBalance: number = 0;
  public TUCPlusReward: number = 0;
  public TUCPlusRewardAmount: number = 0;
  public TUCPlusUserRewardAmount: number = 0;
  public TUCPlusRewardChargeAmount: number = 0;
  public TUCPlusRewardPurchaseAmount: number = 0;
  public TUCPlusRewardClaimedAmount: number = 0;
  public TUCPlusRewardClaimedTransactions: number = 0;
  public TUCPlusPurchaseAmount: number = 0;
  public Commission: number = 0;
  public LastCommissionDate: Date;
  public IssuerCommissionAmount: number = 0;
  public LastIssuerCommissionDate: Date;
  public CommissionAmount: number = 0;
  public SettlementCredit: number = 0;
  public SettlementDebit: number = 0;
  public SettlementPending: number = 0;

  public UserAmount: number = 0;
  public ThankUAmount: number = 0;
  public AppUsers: number = 0;
  public AppUsersPercentage: number = 0;
  public OwnAppUsers: number = 0;
  public OwnAppUsersPercentage: number = 0;
  public UniqueAppUsers: number = 0;
  public RepeatingAppUsers: number = 0;
  public RepeatingAppUsersPercentage: number = 0;
  public ReferralAppUsers: number = 0;
  public ReferralAppUsersPercentage: number = 0;
  public AppUsersMale: number = 0;
  public AppUsersFemale: number = 0;
  public AppUsersOther: number = 0;
  public LastAppUserDate: Date;
  public LastTransaction?: LastTransactionDetails;
  public LastTransactionDate: Date;
  public TransactionIssuerAmountCredit: number = 0;
  public TransactionIssuerAmountDebit: number = 0;
  public TransactionIssuerChargeCredit: number = 0;
  public TransactionIssuerChargeDebit: number = 0;
  public TransactionIssuerTotalCreditAmount: number = 0;
  public TransactionIssuerTotalDebitAmount: number = 0;

  public AppUsersPurchaseByAge: any[];
  public AppUsersByAge: any[];
  public PosOverview: any[];
  public MerchantOverview: any[];
  public StoresOverview: any[];
  public TerminalsOverview: any[];
  public AcquirerAmountDistribution: any[];
  public FrequentBuyers: any[];

  public GenderLabel: any[];
  public GenderData: any[];
  public RewardTypeLabel: any[];
  public RewardTypeData: any[];
  public VisitTrendLabel: any[];
  public VisitTrendData: any[];
}

export class OUserCounts {
  public TotalMerchant?: number = 0;
  public TotalStore?: number = 0;
  public TotalPtsp?: number = 0;
  public TotalPssp?: number = 0;
  public TotalAcquirer?: number = 0;
  public TotalTerminal?: number = 0;
  public TotalCustomer?: number = 0;
  public TotalCustomerNew?: number = 0;
  public TotalCashier?: number = 0;
  public TotalRm?: number = 0;
  public TotalManager?: number = 0;

  public TotalCustomerUnique?: number = 0;
  public TotalCustomerRepeating?: number = 0;

  public ThankUCashPlus?: number = 0;
  public RewardPercentage?: number = 0;
  public ThankUCashPlusBalanceValidity?: number = 0;
  public ThankUCashPlusMinRedeemAmount?: number = 0;
  public ThankUCashPlusMinTransferAmount?: number = 0;

}
export class OCardTypeSalesSummary {
  public CardTypeLabels?: any[];
  public CardTypeData?: any[];
  public CardTypes?: any[];
}
export class OSalesSummary {
  public LastTransactionDateD?: string;
  public FirstTransactionDate?: Date;
  public LastTransactionDate?: Date;
  public TotalTransaction?: number = 0;
  public TotalTransactionCustomer?: number = 0;
  public TotalTransactionInvoiceAmount?: number = 0;
  public TotalSuccessfulTransaction?: number = 0;
  public TotalSuccessfulTransactionCustomer?: number = 0;
  public TotalSuccessfulTransactionInvoiceAmount?: number = 0;
  public TotalFailedTransaction?: number = 0;
  public TotalFailedTransactionCustomer?: number = 0;
  public TotalFailedTransactionInvoiceAmount?: number = 0;
  public TotalCardTransaction?: number = 0;
  public TotalCardTransactionCustomer?: number = 0;
  public TotalCardTransactionInvoiceAmount?: number = 0;
  public TotalCashTransaction?: number = 0;
  public TotalCashTransactionCustomer?: number = 0;
  public TotalCashTransactionInvoiceAmount?: number = 0;

  public TransactionStatusLabels?: any[];
  public TransactionStatusData?: any[];

  public TransactionTypeLabels?: any[];
  public TransactionTypeData?: any[];

  public TransactionTypeUsersLabels?: any[];
  public TransactionTypeUsersData?: any[];

  public TransactionTypeSalesLabels?: any[];
  public TransactionTypeSalesData?: any[];
}
export class ORewardsSummary {
  public LastTransactionDateD?: string;
  public FirstTransactionDate?: Date;
  public FirstTransactionDateD?: string;
  public LastTransactionDate?: Date;

  public UserBalance?: any[];
  public TucBalance?: number = 0;
  public TucPlusBalance?: number = 0;

  public TucBalanceCredit?: number = 0;
  public TucBalanceDebit?: number = 0;
  public TucBalanceTransaction?: number = 0;
  public TucPlusBalanceCredit?: number = 0;
  public TucPlusBalanceDebit?: number = 0;
  public TucPlusBalanceTransaction?: number = 0;


  public TotalTransaction?: number = 0;
  public TotalTransactionCustomer?: number = 0;
  public TotalTransactionInvoiceAmount?: number = 0;

  public TotalRewardTransaction?: number = 0;
  public TotalRewardTransactionCustomer?: number = 0;
  public TotalRewardTransactionAmount?: number = 0;
  public TotalRewardTransactionInvoiceAmount?: number = 0;

  public TotalTucRewardTransaction?: number = 0;
  public TotalTucRewardTransactionCustomer?: number = 0;
  public TotalTucRewardTransactionAmount?: number = 0;
  public TotalTucRewardTransactionInvoiceAmount?: number = 0;

  public TotalTucPlusRewardTransaction?: number = 0;
  public TotalTucPlusRewardTransactionCustomer?: number = 0;
  public TotalTucPlusRewardTransactionAmount?: number = 0;
  public TotalTucPlusRewardTransactionInvoiceAmount?: number = 0;

  public TotalTucPlusRewardClaimTransaction?: number = 0;
  public TotalTucPlusRewardClaimTransactionCustomer?: number = 0;
  public TotalTucPlusRewardClaimTransactionAmount?: number = 0;
  public TotalTucPlusRewardClaimTransactionInvoiceAmount?: number = 0;


  public TotalRedeemTransaction?: number = 0;
  public TotalRedeemTransactionCustomer?: number = 0;
  public TotalRedeemTransactionAmount?: number = 0;
  public TotalRedeemTransactionInvoiceAmount?: number = 0;
}


export class OSalesOverview {
  public LastTransactionDateD?: string;
  public FirstTransactionDate?: Date;
  public LastTransactionDate?: Date;
  public TotalTransaction?: number = 0;
  public TotalTransactionCustomer?: number = 0;
  public TotalTransactionInvoiceAmount?: number = 0;
  public TotalSuccessfulTransaction?: number = 0;
  public TotalSuccessfulTransactionCustomer?: number = 0;
  public TotalSuccessfulTransactionInvoiceAmount?: number = 0;
  public TotalFailedTransaction?: number = 0;
  public TotalFailedTransactionCustomer?: number = 0;
  public TotalFailedTransactionInvoiceAmount?: number = 0;
  public TotalCardTransaction?: number = 0;
  public TotalCardTransactionCustomer?: number = 0;
  public TotalCardTransactionInvoiceAmount?: number = 0;
  public TotalCashTransaction?: number = 0;
  public TotalCashTransactionCustomer?: number = 0;
  public TotalCashTransactionInvoiceAmount?: number = 0;

  public TransactionStatusLabels?: any[];
  public TransactionStatusData?: any[];

  public TransactionTypeLabels?: any[];
  public TransactionTypeData?: any[];

  public TransactionTypeUsersLabels?: any[];
  public TransactionTypeUsersData?: any[];

  public TransactionTypeSalesLabels?: any[];
  public TransactionTypeSalesData?: any[];
}

export class OAccountOverview {
  public TotalTransactions?: number = 0;
  public TotalSale?: number = 0;

  public UnusedTerminals?: number = 0;
  public TransactionSuccess?: number = 0;
  public TransactionFailed?: number = 0;
  public ReferredAppUsers: number = 0;
  public ReferredMerchants: number = 0;
  public ReferredReferredStores: number = 0;
  public ReferredAppUsersVisit: number = 0;
  public ReferredAppUsersPurchase: number = 0;
  public ReferredMerchantVisits: number = 0;
  public ReferredMerchantSale: number = 0;

  public Merchants: number = 0;
  public Stores: number = 0;
  public Acquirers: number = 0;
  public Terminals: number = 0;
  public ActiveTerminals: number = 0;
  public DeadTerminals: number = 0;
  public IdleTerminals: number = 0;
  public Ptsp: number = 0;
  public Pssp: number = 0;
  public Cashiers: number = 0;
  public RewardCards: number = 0;
  public RewardCardsUsed: number = 0;
  public ThankUCashPlus: number = 0;
  public ThankUCashPlusForMerchant: number = 0;
  public ThankUCashPlusBalanceValidity: number = 0;
  public ThankUCashPlusMinRedeemAmount: number = 0;
  public ThankUCashPlusMinTransferAmount: number = 0;
  public RewardPercentage: number = 0;
  public CommissionPercentage: number = 0;
  public Balance: number = 0;
  public ClaimedReward: number = 0;
  public ClaimedRewardTransations: number = 0;
  public Charge: number = 0;
  public CashRewardAmount: number = 0;
  public CashRewardPurchaseAmount: number = 0;
  public CashRewardTransactions: number = 0;
  public CardRewardAmount: number = 0;
  public CardRewardPurchaseAmount: number = 0;
  public CardRewardPurchaseAmountOther: number = 0;
  public CardRewardTransactions: number = 0;
  public CardRewardTransactionsOther: number = 0;
  public OtherRewardAmount: number = 0;
  public OtherRewardPurchaseAmount: number = 0;
  public OtherRewardTransactions: number = 0;
  public RewardTransactions: number = 0;
  public RewardAmount: number = 0;
  public RewardUserAmount: number = 0;
  public RewardChargeAmount: number = 0;
  public RewardPurchaseAmount: number = 0;
  public RewardLastTransaction: Date;
  public RedeemTransactions: number = 0;
  public RedeemAmount: number = 0;
  public RedeemPurchaseAmount: number = 0;
  public RedeemLastTransaction: Date;
  public Credit: number = 0;
  public Debit: number = 0;
  public Transactions: number = 0;
  public TransactionsPercentage: number = 0;
  public NewTransactions: number = 0;
  public NewTransactionsPercentage: number = 0;
  public RepeatingTransactions: number = 0;
  public RepeatingTransactionsPercentage: number = 0;
  public ReferralTransactions: number = 0;
  public ReferralTransactionsPercentage: number = 0;
  public PurchaseAmount: number = 0;
  public PurchaseAmountPercentage: number = 0;
  public NewPurchaseAmount: number = 0;
  public NewPurchaseAmountPercentage: number = 0;
  public RepeatingPurchaseAmount: number = 0;
  public RepeatingPurchaseAmountPercentage: number = 0;
  public ReferralPurchaseAmount: number = 0;
  public ReferralPurchaseAmountPercentage: number = 0;
  public TUCPlusRewardTransactions: number = 0;
  public TUCPlusBalance: number = 0;
  public TUCPlusReward: number = 0;
  public TUCPlusRewardAmount: number = 0;
  public TUCPlusUserRewardAmount: number = 0;
  public TUCPlusRewardChargeAmount: number = 0;
  public TUCPlusRewardPurchaseAmount: number = 0;
  public TUCPlusRewardClaimedAmount: number = 0;
  public TUCPlusRewardClaimedTransactions: number = 0;
  public TUCPlusPurchaseAmount: number = 0;
  public Commission: number = 0;
  public LastCommissionDate: Date;
  public IssuerCommissionAmount: number = 0;
  public LastIssuerCommissionDate: Date;
  public CommissionAmount: number = 0;
  public SettlementCredit: number = 0;
  public SettlementDebit: number = 0;
  public SettlementPending: number = 0;

  public UserAmount: number = 0;
  public ThankUAmount: number = 0;
  public AppUsers: number = 0;
  public AppUsersPercentage: number = 0;
  public OwnAppUsers: number = 0;
  public OwnAppUsersPercentage: number = 0;
  public UniqueAppUsers: number = 0;
  public RepeatingAppUsers: number = 0;
  public RepeatingAppUsersPercentage: number = 0;
  public ReferralAppUsers: number = 0;
  public ReferralAppUsersPercentage: number = 0;
  public AppUsersMale: number = 0;
  public AppUsersFemale: number = 0;
  public AppUsersOther: number = 0;
  public LastAppUserDate: Date;
  public LastTransaction?: LastTransactionDetails;
  public LastTransactionDate: Date;
  public TransactionIssuerAmountCredit: number = 0;
  public TransactionIssuerAmountDebit: number = 0;
  public TransactionIssuerChargeCredit: number = 0;
  public TransactionIssuerChargeDebit: number = 0;
  public TransactionIssuerTotalCreditAmount: number = 0;
  public TransactionIssuerTotalDebitAmount: number = 0;

  public AppUsersPurchaseByAge: any[];
  public AppUsersByAge: any[];
  public PosOverview: any[];
  public MerchantOverview: any[];
  public StoresOverview: any[];
  public TerminalsOverview: any[];
  public AcquirerAmountDistribution: any[];
  public FrequentBuyers: any[];

  public GenderLabel: any[];
  public GenderData: any[];
  public RewardTypeLabel: any[];
  public RewardTypeData: any[];
  public VisitTrendLabel: any[];
  public VisitTrendData: any[];
}

export class LastTransactionDetails {
  public ReferenceId?: number = 0;
  public TypeName?: string;
  public InvoiceAmount?: number = 0;
  public MerchantName?: string;
  public RewardAmount?: number = 0;
  public Amount?: number = 0;
  public TransactionDate?: Date;
}

export class ORewardOverview {
  public RewardAmount: number = 0;
  public RewardUserAmount: number = 0;
  public RewardChargeAmount: number = 0;
  public RewardPurchaseAmount: number = 0;
  public RewardTransactions: number = 0;
  public TUCPlusRewardAmount: number = 0;
  public TUCPlusRewardChargeAmount: number = 0;
  public TUCPlusUserRewardAmount: number = 0;
  public TUCPlusRewardPurchaseAmount: number = 0;
  public TUCPlusRewardTransactions: number = 0;
  public TUCPlusRewardClaimedAmount: number = 0;
  public TUCPlusRewardClaimedTransactions: number = 0;
}
export class ORewardTypeOverview {
  public CashRewardTransactions: number = 0;
  public CashRewardAmount: number = 0;
  public CashRewardPurchaseAmount: number = 0;
  public CardRewardTransactions: number = 0;
  public CardRewardAmount: number = 0;
  public CardRewardPurchaseAmount: number = 0;
  public OtherRewardAmount: number = 0;
  public OtherRewardPurchaseAmount: number = 0;
  public OtherRewardTransactions: number = 0;
  public Transactions: number = 0;

  public CardRewardTransactionsPerc: number = 0;
  public CashRewardTransactionsPerc: number = 0;

  public AppUsersCardType: any[];
  public AppUsersCardTypeLabel: any[];
  public AppUsersCardTypeDataUsers: any[];
  public AppUsersCardTypeDataTransactions: any[];
  public AppUsersCardTypeDataPurchase: any[];

  public AppUsersBank: any[];
  public AppUsersBankLabel: any[];
  public AppUsersBankDataUsers: any[];
  public AppUsersBankDataTransactions: any[];
  public AppUsersBankDataPurchase: any[];

  public BankCompare: any[];
  public BankCompareLabel: any[];
  public BankCompareDataUsers: any[];
  public BankCompareDataTransactions: any[];
  public BankCompareDataPurchase: any[];

  public RewardTypeLabels: any[];
  public RewardTypeData: any[];
}
export class ORedeemOverview {
  public RedeemAmount: number = 0;
  public RedeemPurchaseAmount: number = 0;
  public RedeemTransactions: number = 0;
}

export class OAppUsersOverview {
  public AppUsers: number = 0;
  public AppUsersMale: number = 0;
  public AppUsersFemale: number = 0;
  public AppUsersOther: number = 0;
  public OwnAppUsers: number = 0;
  public ReferralAppUsers: number = 0;
  public RepeatingAppUsers: number = 0;
  public UniqueAppUsers: number = 0;
  public AppUsersByAgeGroup: any[];

  public AppUsersLabelsByGender: any[];
  public AppUsersDataByGender: any[];

  public AppUsersLabelsByAgeGroup: any[];
  public AppUsersCountByAgeGroup: any[];
  public AppUsersPurchaseByAgeGroup: any[];
  public AppUsersVisitByAgeGroup: any[];
}

export class OInvoiceDetails {

  public StatusBadge?: string | null;
  public PaymentDateS?: string | null;

  public ReferenceId: number | null;
  public ReferenceKey: string | null;

  public TypeName: string | null;
  public TypeCode: string | null;

  public ParentKey: string | null;
  public ParentName: string | null;

  public UserAccountId: number | null;
  public UserAccountKey: string | null;
  public UserAccountDisplayName: string | null;
  public UserAccountIconUrl: string | null;
  public UserAccountTypeCode: string | null;
  public UserAccountTypeName: string | null;

  public PaymentDate: Date | null;
  public PaymentReference: string | null;
  public PaymentModeCode: string | null;
  public PaymentModeName: string | null;
  public PaymentApproverKey: string | null;
  public PaymentApproverDisplayName: string | null;
  public PaymentProofUrl: string | null;


  public InoviceNumber: string | null;
  public Name: string | null;
  public Description: string | null;
  public FromName: string | null;
  public FromAddress: string | null;
  public FromContactNumber: string | null;
  public FromEmailAddress: string | null;
  public FromFax: string | null;
  public ToName: string | null;
  public ToAddress: string | null;
  public ToContactNumber: string | null;
  public ToEmailAddress: string | null;
  public ToFax: string | null;

  public TotalItem: number | null;
  public UnitCost: number | null;

  public Amount: number | null;
  public ChargePercentage: number | null;
  public Charge: number | null;

  public DiscountPercentage: number | null;
  public DiscountAmount: number | null;
  public ComissionPercentage: number | null;
  public ComissionAmount: number | null;
  public TotalAmount: number | null;

  public InvoiceDate: Date | null;
  public InvoiceDateS: string | null;
  public StartDate: string | null;
  public EndDate: string | null;
  public CreateDate: string | null;
  public CreatedByKey: string | null;
  public CreatedByDisplayName: string | null;
  public ModifyDate: string | null;
  public ModifyDateS: string | null;
  public ModifyByKey: string | null;
  public ModifyByDisplayName: string | null;
  public Status: string | null;
  public StatusCode: string | null;
  public StatusName: string | null;
  public StatusI: string | null;
  public Comment: string | null;
  public Items: OInvoiceItemDetails[] | null;
}

export class OInvoiceItemDetails {
  public ReferenceId: number | null;
  public ReferenceKey: string | null;

  public TypeName: string | null;
  public TypeCode: string | null;


  public Name: string | null;
  public Description: string | null;

  public TotalItem: number | null;
  public UnitCost: number | null;

  public Amount: number | null;
  public ChargePercentage: number | null;
  public Charge: number | null;

  public DiscountPercentage: number | null;
  public DiscountAmount: number | null;
  public ComissionPercentage: number | null;
  public ComissionAmount: number | null;
  public TotalAmount: number | null;

  public InvoiceDate: Date | null;
  public InvoiceDateS: string | null;
  public StartDate: string | null;
  public EndDate: string | null;
  public CreateDate: string | null;
  public CreatedByKey: string | null;
  public CreatedByDisplayName: string | null;
  public ModifyDate: string | null;
  public ModifyDateS: string | null;
  public ModifyByKey: string | null;
  public ModifyByDisplayName: string | null;
  public Status: string | null;
  public StatusCode: string | null;
  public StatusName: string | null;
  public StatusI: string | null;
  public Comment: string | null;
}

export class OTerminalStatusCount {
  public Total: number | null;
  public Unused: number | null;
  public Active: number | null;
  public Idle: number | null;
  public Dead: number | null;

}



//new code---------------------------------------------------------------------
// export class OResponse {
//   public Status: string;
//   public Message: string;
//   public ResponseCode: string;
//   public Result: any;
// }

// export class OListResponse {
//   Data: [any];
//   Offset: number = 0;
//   Limit: number = 0;
//   TotalRecords: number = 0;
// }
// export class OStorageContent {
//   public Name: string;
//   public Content: string;
//   public Extension: string;
//   public TypeCode: string;
//   public Width: number = 0;
//   public Height: number = 0;
// }

// export class OSelect {
//   public Task: string;
//   public Location: string;
//   public SearchCondition?: string;
//   public SortCondition?: string[];
//   public Fields: OSelectField[];
//   public ReferenceId?: number;
//   public ReferenceKey?: string;
// }

// export class OSelectField {
//   public SystemName: string;
//   public Type: string;
//   public Id?: boolean;
//   public Text?: boolean;
//   public SearchCondition?: string;
//   public SearchValue?: string;
// }

// export class OAccessUser {
//   public Name: string;
//   public FirstName: string;
//   public LastName: string;
//   public UserName: string;
//   public EmailAddress: string;
//   public ContactNumber: string;
//   public MobileNumber: string;

//   public Gender: string;
//   public GenderCode: string;

//   public DateOfBirth: Date;

//   public Address: string;
//   public AddressLatitude: number = 0;
//   public AddressLongitude: number = 0;

//   public EmailVerificationStatus: number = 0;
//   public ContactNumberVerificationStatus: number = 0;
// }
// export class OAccessUserAccount {
//   public AccountId: number = 0;
//   public DisplayName: string;
//   public AccountKey: string;
//   public IsTucPlusEnabled: boolean;
//   public AccountType: string;
//   public AccountTypeCode: string;
//   public AccountCode: string;
//   public EmailAddress: string;
//   public ReferralUrl: string;
//   public ReferralCode: string;
//   public RoleKey: string;
//   public RoleName: string;
//   public IconUrl: string;
//   public PosterUrl: string;
//   public MerchantIconUrl: string;
//   public IsAccessPinSet: number = 0;

//   public CreateDate: string;
//   public CreateDateS: string;
// }
// export class OAccessUserAccountOwner {
//   public AccountTypeCode: number = 0;
//   public AccountId: number = 0;
//   public AccountKey: string;
//   public DisplayName: string;
//   public Name: string;
//   public AccountCode: string;
//   public IconUrl: string;
//   public IsTucPlusEnabled: boolean;
// }
// export class OAccessUserCountry {
//   public CountryKey: string;
//   public CountryName: string;
//   public CountryIso: string;
//   public CountryIsd: string;
//   public CurrencyName: string;
//   public CurrencyNotation: string;
//   public CurrencySymbol: string;
// }
// export class OAccessUserAccountRole {
//   public Name: string;
//   public SystemName: string;
//   public RolePermissions: Array<OAccessUserAccountRolePermission>;
// }
// export class OAccessUserAccountRolePermission {
//   public Name: string;
//   public SystemName: string;
//   public IsDefault: number = 0;
//   public IsAccessPinRequired: number = 0;
//   public IsPasswordRequired: number = 0;
// }

// export class OAccessUserLocation {
//   public City: string;

//   public CountryCode: string;
//   public CountryName: string;

//   public RegionCode: string;
//   public RegionName: string;

//   public ZipCode: string;
//   public TimeZone: string;
//   public Latitude: string;
//   public Longitude: string;

//   public UpdateTime: string;
// }
// export class OAccessUserDevice {
//   public SerialNumber: string;
// }

// export class OList {
//   public Id: string;
//   public Filters?: any[];
//   public Sort: OListSort;
//   public ListType?: number = 0;
//   public Task: string;
//   public Location: string;
//   public Title: string = "List";
//   public TableFields: OListField[];
//   public ReferenceId?: number = null;
//   public SubReferenceId?: number = null;
//   public SubReferenceKey?: string = null;
//   public AccountId?: number = null;
//   public AccountKey?: string = null;
//   public ReferenceKey?: string = null;
//   public Type?: string = null;
//   public RefreshCount?: boolean = true;
//   public TitleResourceId?: string = null;
//   public RefreshData?: boolean = false;
//   public IsDownload?: boolean = false;
//   public ToggleFilter?: boolean = false;
//   public ActivePage?: number = 1;
//   public PageRecordLimit?: number = 10;
//   public TotalRecords?: number = 0;
//   public ShowingStart?: number = 0;
//   public ShowingEnd?: number = 0;
//   public StartTime?: any = null;
//   public EndTime?: any = null;
//   public StartDate?: any = null;
//   public EndDate?: any = null;
//   public SearchParameter?: string;
//   public SearchCondition?: string;
//   public SearchBaseCondition?: string;
//   public SearchBaseConditions?: any[];
//   public ColumnSearchParameter?: string;
//   public AvailableSearchColumns?: any[];
//   public SearchColumns?: any[];
//   public Data?: any[];
//   public StatusType?: string = "default";
//   public StatusName?: string = "default";

//   public Status?: number = 0;
//   public StatusOptions?: any[];
//   public VisibleHeaders?: any[];
//   public Visiblity?: any[];
//   public VisiblityOptions?: any[];
//   public SortExpression?: string;
//   public SortExpressionOption?: any[];
//   public DefaultSortExpression?: string;
//   public OriginalResponse?: any;
//   public SalesRange?: any = null;
//   public LastRequestDateTime?:any=null;
// }

// export class OListSort {
//   public SortDefaultName: string;
//   public SortDefaultColumn: string;
//   public SortDefaultOrder?: string = "desc";
//   public SortName?: string;
//   public SortOrder?: string = "desc";
//   public SortColumn?: string;
//   public SortOptions?: any[];
// }

// export class OListField {
//   public DefaultValue?: string = "--";
//   public DisplayName: string;
//   public DownloadDisplayName?: string;
//   public SystemName: string;
//   public Content?: string;
//   public DataType: string = "text";
//   public Class?: string = "";
//   public ResourceId?: string = "";
//   public Sort?: boolean = true;
//   public Show?: boolean = true;
//   public Search?: boolean = true;
//   public NavigateLink?: string = "";
//   public NavigateField?: string = "";
//   public IsDateSearchField?: boolean = false;
//   public TextCenter?: string = "";



// }

// export class OCoreHelper {
//   public Reference: string;
//   public ReferenceKey: string;
//   public SystemName: string;
//   public ParentCode: string;
//   public ParentName: string;
//   public SubParentCode: string;
//   public SubParentName: string;
//   public Name: string;
//   public Value: string;
//   public TypeName: string;
//   public Description: string;
//   public Sequence: number = 0;
//   public IconUrl: string;
//   public PosterUrl: string;
//   public CreateDate: Date;
//   public CreatedByKey: string;
//   public CreatedByDisplayName: string;
//   public CreatedByIconUrl: string;
//   public ModifyDate: Date;
//   public ModifyByKey: string;
//   public ModifyByDisplayName: string;
//   public ModifyByIconUrl: string;
//   public StatusId: number = 0;
//   public StatusCode: string;
//   public StatusName: string;
//   public StatusI: string;
//   public CreateDateS: string;
//   public ModifyDateS: string;
// }

// export class OCoreCommon {
//   public StatusB:string;
//   public StatusI:string;
//   public StatusC:string;
//   public LastRequestDateTime:Date;
//   public AccountDisplayName :string;
//   public Reference: string;
//   public ReferenceKey: string;
//   public SystemName: string;
//   public UserAccountKey: string;
//   public UserAccountDisplayName: string;
//   public UserAccountIconUrl: string;
//   public TypeCode: string;
//   public TypeName: string;
//   public HelperCode: string;
//   public HelperName: string;
//   public ParentKey: string;
//   public ParentCode: string;
//   public ParentName: string;
//   public SubParentKey: string;
//   public SubParentCode: string;
//   public SubParentName: string;
//   public Name: string;
//   public Value: string;
//   public SubValue: string;
//   public Description: string;
//   public Data: string;
//   public Sequence: number = 0;
//   public Count: number = 0;
//   public SubItemsCount: number = 0;
//   public IconUrl: string;
//   public PosterUrl: string;
//   public CreateDate: Date;
//   public CreatedByKey: string;
//   public CreatedByDisplayName: string;
//   public CreatedByIconUrl: string;
//   public ModifyDate: Date;
//   public ModifyByKey: string;
//   public ModifyByDisplayName: string;
//   public ModifyByIconUrl: string;
//   public StatusId: number = 0;
//   public StatusCode: string;
//   public StatusName: string;
//   public CreateDateS: string;
//   public ModifyDateS: string;
//   public ImageUrl: string;
//   public IpAddress?: any;
//   public LogRequest?: any;
//   public ActiveAppKey: string;
//   public ActiveAppVersionKey: string;
// }

// export class OCoreParameter {
//   public ReferenceId?: number;
//   public Reference: string;
//   public ReferenceKey: string;
//   public SystemName: string;
//   public TypeCode: string;
//   public TypeName: string;
//   public ParentKey: string;
//   public ParentCode: string;
//   public ParentName: string;
//   public SubParentKey: string;
//   public SubParentCode: string;
//   public SubParentName: string;
//   public SubItemsCount: null;

//   public CommonParentKey: string;
//   public CommonParentCode: string;
//   public CommonParentName: string;
//   public SubCommonParentKey: string;
//   public SubCommonParentCode: string;
//   public SubCommonParentName: string;
//   public UserAccountKey: string;
//   public UserAccountDisplayName: string;
//   public UserAccountIconUrl: string;
//   public HelperCode: string;
//   public HelperName: string;
//   public Name: string;
//   public Value: string;
//   public SubValue: string;
//   public Description: string;
//   public Data: string;
//   public Sequence: number = 0;
//   public Count: number = 0;
//   public IconUrl: string;
//   public PosterUrl: string;
//   public CreateDate: Date;
//   public CreatedByKey: string;
//   public CreatedByDisplayName: string;
//   public CreatedByIconUrl: string;
//   public ModifyDate: Date;
//   public ModifyByKey: string;
//   public ModifyByDisplayName: string;
//   public ModifyByIconUrl: string;
//   public StatusId: number = 0;
//   public StatusCode: string;
//   public StatusName: string;
//   public StatusI: string;
//   public CreateDateS: string;
//   public ModifyDateS: string;
// }

// export class OUserDetails {
//   public SecondaryEmailAddress?: string;
//   public SubAccounts?: number = 0;
//   public ReferenceId?: number = 0;
//   public Reference: string;
//   public ReferenceKey: string;
//   public AccountTypeCode: string;
//   public AccountTypeName: string;
//   public AccountOperationTypeCode: string;
//   public AccountOperationTypeName: string;
//   public OwnerKey: string;
//   public OwnerDisplayName: string;
//   public OwnerIconUrl?: string;

//   public SubOwnerLatitude: string;
//   public SubOwnerLongitude: string;
//   public SubOwnerKey: string;
//   public SubOwnerDisplayName: string;
//   public SubOwnerAddress: string;

//   public BankKey: string;
//   public BankDisplayName: string;

//   public DisplayName: string;
//   public AccessPin: string;
//   public AccountCode: string;
//   public IconUrl: string;
//   public PosterUrl: string;
//   public ReferralCode: string;
//   public ReferralUrl: string;
//   public Description: string;
//   public RegistrationSourceCode: string;
//   public RegistrationSourceName: string;
//   public RoleKey: string;
//   public RoleName: string;
//   public AppKey: string;
//   public AppName: string;
//   public AppVersionKey: string;
//   public AppVersionName: string;
//   public ApplicationStatusCode: string;
//   public ApplicationStatusName: string;
//   public LastLoginDate: Date;
//   public LastLoginDateS: string;
//   public RequestKey: string;
//   public CountValue: string;
//   public AverageValue: string;
//   public UserName: string;
//   public Password: string;
//   public SecondaryPassword: string;
//   public SystemPassword: string;
//   public Name: string;
//   public FirstName: string;
//   public LastName: string;
//   public MobileNumber: string;
//   public ContactNumber: string;
//   public EmailAddress: string;
//   public GenderCode: string;
//   public GenderName: string;
//   public DateOfBirth: Date;
//   public Address: string;
//   public Latitude: number = 0;
//   public Longitude: number = 0;
//   public CountryKey: string;
//   public CountryName: string;
//   public RegionKey: string;
//   public RegionName: string;
//   public RegionAreaKey: string;
//   public RegionAreaName: string;
//   public CityKey: string;
//   public CityName: string;
//   public CityAreaKey: string;
//   public CityAreaName: string;
//   public WebsiteUrl: string;
//   public EmailVerificationStatus: number = 0;
//   public EmailVerificationStatusDate: Date;
//   public NumberVerificationStatus: number = 0;
//   public NumberVerificationStatusDate: Date;
//   public CreateDate: Date;
//   public CreatedByKey: string;
//   public CreatedByDisplayName: string;
//   public CreatedByDisplayNameShort?: string;
//   public CreatedByIconUrl: string;
//   public ModifyDate: Date;
//   public ModifyByKey: string;
//   public ModifyByDisplayName: string;
//   public ModifyByDisplayNameShort?: string;
//   public ModifyByIconUrl: string;
//   public StatusId: number = 0;
//   public StatusCode: string;
//   public StatusName: string;
//   public StatusI: string;
//   public StatusB?: string;
//   public StatusC?: string;
//   public CreateDateS: string;
//   public ModifyDateS: string;

//   public MerchantDisplayName?: string;
//   public MerchantKey?: string;
// }

// export class OOverview {
//   public Merchants: number = 0;
//   public Stores: number = 0;
//   public Acquirers: number = 0;
//   public Terminals: number = 0;
//   public ActiveTerminals: number = 0;
//   public DeadTerminals: number = 0;
//   public IdleTerminals: number = 0;
//   public Ptsp: number = 0;
//   public Pssp: number = 0;
//   public Cashiers: number = 0;
//   public RewardCards: number = 0;
//   public RewardCardsUsed: number = 0;
//   public ThankUCashPlus: number = 0;
//   public ThankUCashPlusForMerchant: number = 0;
//   public ThankUCashPlusBalanceValidity: number = 0;
//   public ThankUCashPlusMinRedeemAmount: number = 0;
//   public ThankUCashPlusMinTransferAmount: number = 0;
//   public RewardPercentage: number = 0;
//   public CommissionPercentage: number = 0;
//   public Balance: number = 0;
//   public ClaimedReward: number = 0;
//   public ClaimedRewardTransations: number = 0;
//   public Charge: number = 0;
//   public CashRewardAmount: number = 0;
//   public CashRewardPurchaseAmount: number = 0;
//   public CashRewardTransactions: number = 0;
//   public CardRewardAmount: number = 0;
//   public CardRewardPurchaseAmount: number = 0;
//   public CardRewardPurchaseAmountOther: number = 0;
//   public CardRewardTransactions: number = 0;
//   public CardRewardTransactionsOther: number = 0;
//   public OtherRewardAmount: number = 0;
//   public OtherRewardPurchaseAmount: number = 0;
//   public OtherRewardTransactions: number = 0;
//   public RewardTransactions: number = 0;
//   public RewardAmount: number = 0;
//   public RewardUserAmount: number = 0;
//   public RewardChargeAmount: number = 0;
//   public RewardPurchaseAmount: number = 0;
//   public RewardLastTransaction: Date;
//   public RedeemTransactions: number = 0;
//   public RedeemAmount: number = 0;
//   public RedeemPurchaseAmount: number = 0;
//   public RedeemLastTransaction: Date;
//   public Credit: number = 0;
//   public Debit: number = 0;
//   public Transactions: number = 0;
//   public TransactionsPercentage: number = 0;
//   public NewTransactions: number = 0;
//   public NewTransactionsPercentage: number = 0;
//   public RepeatingTransactions: number = 0;
//   public RepeatingTransactionsPercentage: number = 0;
//   public ReferralTransactions: number = 0;
//   public ReferralTransactionsPercentage: number = 0;
//   public PurchaseAmount: number = 0;
//   public PurchaseAmountPercentage: number = 0;
//   public NewPurchaseAmount: number = 0;
//   public NewPurchaseAmountPercentage: number = 0;
//   public RepeatingPurchaseAmount: number = 0;
//   public RepeatingPurchaseAmountPercentage: number = 0;
//   public ReferralPurchaseAmount: number = 0;
//   public ReferralPurchaseAmountPercentage: number = 0;
//   public TUCPlusRewardTransactions: number = 0;
//   public TUCPlusBalance: number = 0;
//   public TUCPlusReward: number = 0;
//   public TUCPlusRewardAmount: number = 0;
//   public TUCPlusUserRewardAmount: number = 0;
//   public TUCPlusRewardChargeAmount: number = 0;
//   public TUCPlusRewardPurchaseAmount: number = 0;
//   public TUCPlusRewardClaimedAmount: number = 0;
//   public TUCPlusRewardClaimedTransactions: number = 0;
//   public TUCPlusPurchaseAmount: number = 0;
//   public Commission: number = 0;
//   public LastCommissionDate: Date;
//   public IssuerCommissionAmount: number = 0;
//   public LastIssuerCommissionDate: Date;
//   public CommissionAmount: number = 0;
//   public SettlementCredit: number = 0;
//   public SettlementDebit: number = 0;
//   public SettlementPending: number = 0;

//   public UserAmount: number = 0;
//   public ThankUAmount: number = 0;
//   public AppUsers: number = 0;
//   public AppUsersPercentage: number = 0;
//   public OwnAppUsers: number = 0;
//   public OwnAppUsersPercentage: number = 0;
//   public UniqueAppUsers: number = 0;
//   public RepeatingAppUsers: number = 0;
//   public RepeatingAppUsersPercentage: number = 0;
//   public ReferralAppUsers: number = 0;
//   public ReferralAppUsersPercentage: number = 0;
//   public AppUsersMale: number = 0;
//   public AppUsersFemale: number = 0;
//   public AppUsersOther: number = 0;
//   public LastAppUserDate: Date;
//   public LastTransaction?: LastTransactionDetails;
//   public LastTransactionDate: Date;
//   public TransactionIssuerAmountCredit: number = 0;
//   public TransactionIssuerAmountDebit: number = 0;
//   public TransactionIssuerChargeCredit: number = 0;
//   public TransactionIssuerChargeDebit: number = 0;
//   public TransactionIssuerTotalCreditAmount: number = 0;
//   public TransactionIssuerTotalDebitAmount: number = 0;

//   public AppUsersPurchaseByAge: any[];
//   public AppUsersByAge: any[];
//   public PosOverview: any[];
//   public MerchantOverview: any[];
//   public StoresOverview: any[];
//   public TerminalsOverview: any[];
//   public AcquirerAmountDistribution: any[];
//   public FrequentBuyers: any[];

//   public GenderLabel: any[];
//   public GenderData: any[];
//   public RewardTypeLabel: any[];
//   public RewardTypeData: any[];
//   public VisitTrendLabel: any[];
//   public VisitTrendData: any[];
// }

// export class OUserCounts {
//   public TotalMerchant?: number = 0;
//   public TotalStore?: number = 0;
//   public TotalPtsp?: number = 0;
//   public TotalPssp?: number = 0;
//   public TotalAcquirer?: number = 0;
//   public TotalTerminal?: number = 0;
//   public TotalCustomer?: number = 0;
//   public TotalCustomerNew?: number = 0;
//   public TotalCashier?: number = 0;
//   public TotalRm?: number = 0;
//   public TotalManager?: number = 0;

//   public TotalCustomerUnique?: number = 0;
//   public TotalCustomerRepeating?: number = 0;

//   public ThankUCashPlus?: number = 0;
//   public RewardPercentage?: number = 0;
//   public ThankUCashPlusBalanceValidity?: number = 0;
//   public ThankUCashPlusMinRedeemAmount?: number = 0;
//   public ThankUCashPlusMinTransferAmount?: number = 0;

// }
// export class OCardTypeSalesSummary {
//   public CardTypeLabels?: any[];
//   public CardTypeData?: any[];
//   public CardTypes?: any[];
// }
// export class OSalesSummary {
//   public LastTransactionDateD?: string;
//   public FirstTransactionDate?: Date;
//   public LastTransactionDate?: Date;
//   public TotalTransaction?: number = 0;
//   public TotalTransactionCustomer?: number = 0;
//   public TotalTransactionInvoiceAmount?: number = 0;
//   public TotalSuccessfulTransaction?: number = 0;
//   public TotalSuccessfulTransactionCustomer?: number = 0;
//   public TotalSuccessfulTransactionInvoiceAmount?: number = 0;
//   public TotalFailedTransaction?: number = 0;
//   public TotalFailedTransactionCustomer?: number = 0;
//   public TotalFailedTransactionInvoiceAmount?: number = 0;
//   public TotalCardTransaction?: number = 0;
//   public TotalCardTransactionCustomer?: number = 0;
//   public TotalCardTransactionInvoiceAmount?: number = 0;
//   public TotalCashTransaction?: number = 0;
//   public TotalCashTransactionCustomer?: number = 0;
//   public TotalCashTransactionInvoiceAmount?: number = 0;

//   public TransactionStatusLabels?: any[];
//   public TransactionStatusData?: any[];

//   public TransactionTypeLabels?: any[];
//   public TransactionTypeData?: any[];

//   public TransactionTypeUsersLabels?: any[];
//   public TransactionTypeUsersData?: any[];

//   public TransactionTypeSalesLabels?: any[];
//   public TransactionTypeSalesData?: any[];
// }
// export class ORewardsSummary {
//   public LastTransactionDateD?: string;
//   public FirstTransactionDate?: Date;
//   public FirstTransactionDateD?: string;
//   public LastTransactionDate?: Date;

//   public UserBalance?: any[];
//   public TucBalance?: number = 0;
//   public TucPlusBalance?: number = 0;

//   public TucBalanceCredit?: number = 0;
//   public TucBalanceDebit?: number = 0;
//   public TucBalanceTransaction?: number = 0;
//   public TucPlusBalanceCredit?: number = 0;
//   public TucPlusBalanceDebit?: number = 0;
//   public TucPlusBalanceTransaction?: number = 0;


//   public TotalTransaction?: number = 0;
//   public TotalTransactionCustomer?: number = 0;
//   public TotalTransactionInvoiceAmount?: number = 0;

//   public TotalRewardTransaction?: number = 0;
//   public TotalRewardTransactionCustomer?: number = 0;
//   public TotalRewardTransactionAmount?: number = 0;
//   public TotalRewardTransactionInvoiceAmount?: number = 0;

//   public TotalTucRewardTransaction?: number = 0;
//   public TotalTucRewardTransactionCustomer?: number = 0;
//   public TotalTucRewardTransactionAmount?: number = 0;
//   public TotalTucRewardTransactionInvoiceAmount?: number = 0;

//   public TotalTucPlusRewardTransaction?: number = 0;
//   public TotalTucPlusRewardTransactionCustomer?: number = 0;
//   public TotalTucPlusRewardTransactionAmount?: number = 0;
//   public TotalTucPlusRewardTransactionInvoiceAmount?: number = 0;

//   public TotalTucPlusRewardClaimTransaction?: number = 0;
//   public TotalTucPlusRewardClaimTransactionCustomer?: number = 0;
//   public TotalTucPlusRewardClaimTransactionAmount?: number = 0;
//   public TotalTucPlusRewardClaimTransactionInvoiceAmount?: number = 0;


//   public TotalRedeemTransaction?: number = 0;
//   public TotalRedeemTransactionCustomer?: number = 0;
//   public TotalRedeemTransactionAmount?: number = 0;
//   public TotalRedeemTransactionInvoiceAmount?: number = 0;
// }


// export class OSalesOverview {
//   public LastTransactionDateD?: string;
//   public FirstTransactionDate?: Date;
//   public LastTransactionDate?: Date;
//   public TotalTransaction?: number = 0;
//   public TotalTransactionCustomer?: number = 0;
//   public TotalTransactionInvoiceAmount?: number = 0;
//   public TotalSuccessfulTransaction?: number = 0;
//   public TotalSuccessfulTransactionCustomer?: number = 0;
//   public TotalSuccessfulTransactionInvoiceAmount?: number = 0;
//   public TotalFailedTransaction?: number = 0;
//   public TotalFailedTransactionCustomer?: number = 0;
//   public TotalFailedTransactionInvoiceAmount?: number = 0;
//   public TotalCardTransaction?: number = 0;
//   public TotalCardTransactionCustomer?: number = 0;
//   public TotalCardTransactionInvoiceAmount?: number = 0;
//   public TotalCashTransaction?: number = 0;
//   public TotalCashTransactionCustomer?: number = 0;
//   public TotalCashTransactionInvoiceAmount?: number = 0;

//   public TransactionStatusLabels?: any[];
//   public TransactionStatusData?: any[];

//   public TransactionTypeLabels?: any[];
//   public TransactionTypeData?: any[];

//   public TransactionTypeUsersLabels?: any[];
//   public TransactionTypeUsersData?: any[];

//   public TransactionTypeSalesLabels?: any[];
//   public TransactionTypeSalesData?: any[];
// }

// export class OAccountOverview {
//   public TotalTransactions?: number = 0;
//   public TotalSale?: number = 0;

//   public UnusedTerminals?: number = 0;
//   public TransactionSuccess?: number = 0;
//   public TransactionFailed?: number = 0;
//   public ReferredAppUsers: number = 0;
//   public ReferredMerchants: number = 0;
//   public ReferredReferredStores: number = 0;
//   public ReferredAppUsersVisit: number = 0;
//   public ReferredAppUsersPurchase: number = 0;
//   public ReferredMerchantVisits: number = 0;
//   public ReferredMerchantSale: number = 0;

//   public Merchants: number = 0;
//   public Stores: number = 0;
//   public Acquirers: number = 0;
//   public Terminals: number = 0;
//   public ActiveTerminals: number = 0;
//   public DeadTerminals: number = 0;
//   public IdleTerminals: number = 0;
//   public Ptsp: number = 0;
//   public Pssp: number = 0;
//   public Cashiers: number = 0;
//   public RewardCards: number = 0;
//   public RewardCardsUsed: number = 0;
//   public ThankUCashPlus: number = 0;
//   public ThankUCashPlusForMerchant: number = 0;
//   public ThankUCashPlusBalanceValidity: number = 0;
//   public ThankUCashPlusMinRedeemAmount: number = 0;
//   public ThankUCashPlusMinTransferAmount: number = 0;
//   public RewardPercentage: number = 0;
//   public CommissionPercentage: number = 0;
//   public Balance: number = 0;
//   public ClaimedReward: number = 0;
//   public ClaimedRewardTransations: number = 0;
//   public Charge: number = 0;
//   public CashRewardAmount: number = 0;
//   public CashRewardPurchaseAmount: number = 0;
//   public CashRewardTransactions: number = 0;
//   public CardRewardAmount: number = 0;
//   public CardRewardPurchaseAmount: number = 0;
//   public CardRewardPurchaseAmountOther: number = 0;
//   public CardRewardTransactions: number = 0;
//   public CardRewardTransactionsOther: number = 0;
//   public OtherRewardAmount: number = 0;
//   public OtherRewardPurchaseAmount: number = 0;
//   public OtherRewardTransactions: number = 0;
//   public RewardTransactions: number = 0;
//   public RewardAmount: number = 0;
//   public RewardUserAmount: number = 0;
//   public RewardChargeAmount: number = 0;
//   public RewardPurchaseAmount: number = 0;
//   public RewardLastTransaction: Date;
//   public RedeemTransactions: number = 0;
//   public RedeemAmount: number = 0;
//   public RedeemPurchaseAmount: number = 0;
//   public RedeemLastTransaction: Date;
//   public Credit: number = 0;
//   public Debit: number = 0;
//   public Transactions: number = 0;
//   public TransactionsPercentage: number = 0;
//   public NewTransactions: number = 0;
//   public NewTransactionsPercentage: number = 0;
//   public RepeatingTransactions: number = 0;
//   public RepeatingTransactionsPercentage: number = 0;
//   public ReferralTransactions: number = 0;
//   public ReferralTransactionsPercentage: number = 0;
//   public PurchaseAmount: number = 0;
//   public PurchaseAmountPercentage: number = 0;
//   public NewPurchaseAmount: number = 0;
//   public NewPurchaseAmountPercentage: number = 0;
//   public RepeatingPurchaseAmount: number = 0;
//   public RepeatingPurchaseAmountPercentage: number = 0;
//   public ReferralPurchaseAmount: number = 0;
//   public ReferralPurchaseAmountPercentage: number = 0;
//   public TUCPlusRewardTransactions: number = 0;
//   public TUCPlusBalance: number = 0;
//   public TUCPlusReward: number = 0;
//   public TUCPlusRewardAmount: number = 0;
//   public TUCPlusUserRewardAmount: number = 0;
//   public TUCPlusRewardChargeAmount: number = 0;
//   public TUCPlusRewardPurchaseAmount: number = 0;
//   public TUCPlusRewardClaimedAmount: number = 0;
//   public TUCPlusRewardClaimedTransactions: number = 0;
//   public TUCPlusPurchaseAmount: number = 0;
//   public Commission: number = 0;
//   public LastCommissionDate: Date;
//   public IssuerCommissionAmount: number = 0;
//   public LastIssuerCommissionDate: Date;
//   public CommissionAmount: number = 0;
//   public SettlementCredit: number = 0;
//   public SettlementDebit: number = 0;
//   public SettlementPending: number = 0;

//   public UserAmount: number = 0;
//   public ThankUAmount: number = 0;
//   public AppUsers: number = 0;
//   public AppUsersPercentage: number = 0;
//   public OwnAppUsers: number = 0;
//   public OwnAppUsersPercentage: number = 0;
//   public UniqueAppUsers: number = 0;
//   public RepeatingAppUsers: number = 0;
//   public RepeatingAppUsersPercentage: number = 0;
//   public ReferralAppUsers: number = 0;
//   public ReferralAppUsersPercentage: number = 0;
//   public AppUsersMale: number = 0;
//   public AppUsersFemale: number = 0;
//   public AppUsersOther: number = 0;
//   public LastAppUserDate: Date;
//   public LastTransaction?: LastTransactionDetails;
//   public LastTransactionDate: Date;
//   public TransactionIssuerAmountCredit: number = 0;
//   public TransactionIssuerAmountDebit: number = 0;
//   public TransactionIssuerChargeCredit: number = 0;
//   public TransactionIssuerChargeDebit: number = 0;
//   public TransactionIssuerTotalCreditAmount: number = 0;
//   public TransactionIssuerTotalDebitAmount: number = 0;

//   public AppUsersPurchaseByAge: any[];
//   public AppUsersByAge: any[];
//   public PosOverview: any[];
//   public MerchantOverview: any[];
//   public StoresOverview: any[];
//   public TerminalsOverview: any[];
//   public AcquirerAmountDistribution: any[];
//   public FrequentBuyers: any[];

//   public GenderLabel: any[];
//   public GenderData: any[];
//   public RewardTypeLabel: any[];
//   public RewardTypeData: any[];
//   public VisitTrendLabel: any[];
//   public VisitTrendData: any[];
// }

// export class LastTransactionDetails {
//   public ReferenceId?: number = 0;
//   public TypeName?: string;
//   public InvoiceAmount?: number = 0;
//   public MerchantName?: string;
//   public RewardAmount?: number = 0;
//   public Amount?: number = 0;
//   public TransactionDate?: Date;
// }

// export class ORewardOverview {
//   public RewardAmount: number = 0;
//   public RewardUserAmount: number = 0;
//   public RewardChargeAmount: number = 0;
//   public RewardPurchaseAmount: number = 0;
//   public RewardTransactions: number = 0;
//   public TUCPlusRewardAmount: number = 0;
//   public TUCPlusRewardChargeAmount: number = 0;
//   public TUCPlusUserRewardAmount: number = 0;
//   public TUCPlusRewardPurchaseAmount: number = 0;
//   public TUCPlusRewardTransactions: number = 0;
//   public TUCPlusRewardClaimedAmount: number = 0;
//   public TUCPlusRewardClaimedTransactions: number = 0;
// }
// export class ORewardTypeOverview {
//   public CashRewardTransactions: number = 0;
//   public CashRewardAmount: number = 0;
//   public CashRewardPurchaseAmount: number = 0;
//   public CardRewardTransactions: number = 0;
//   public CardRewardAmount: number = 0;
//   public CardRewardPurchaseAmount: number = 0;
//   public OtherRewardAmount: number = 0;
//   public OtherRewardPurchaseAmount: number = 0;
//   public OtherRewardTransactions: number = 0;
//   public Transactions: number = 0;

//   public CardRewardTransactionsPerc: number = 0;
//   public CashRewardTransactionsPerc: number = 0;

//   public AppUsersCardType: any[];
//   public AppUsersCardTypeLabel: any[];
//   public AppUsersCardTypeDataUsers: any[];
//   public AppUsersCardTypeDataTransactions: any[];
//   public AppUsersCardTypeDataPurchase: any[];

//   public AppUsersBank: any[];
//   public AppUsersBankLabel: any[];
//   public AppUsersBankDataUsers: any[];
//   public AppUsersBankDataTransactions: any[];
//   public AppUsersBankDataPurchase: any[];

//   public BankCompare: any[];
//   public BankCompareLabel: any[];
//   public BankCompareDataUsers: any[];
//   public BankCompareDataTransactions: any[];
//   public BankCompareDataPurchase: any[];

//   public RewardTypeLabels: any[];
//   public RewardTypeData: any[];
// }
// export class ORedeemOverview {
//   public RedeemAmount: number = 0;
//   public RedeemPurchaseAmount: number = 0;
//   public RedeemTransactions: number = 0;
// }

// export class OAppUsersOverview {
//   public AppUsers: number = 0;
//   public AppUsersMale: number = 0;
//   public AppUsersFemale: number = 0;
//   public AppUsersOther: number = 0;
//   public OwnAppUsers: number = 0;
//   public ReferralAppUsers: number = 0;
//   public RepeatingAppUsers: number = 0;
//   public UniqueAppUsers: number = 0;
//   public AppUsersByAgeGroup: any[];

//   public AppUsersLabelsByGender: any[];
//   public AppUsersDataByGender: any[];

//   public AppUsersLabelsByAgeGroup: any[];
//   public AppUsersCountByAgeGroup: any[];
//   public AppUsersPurchaseByAgeGroup: any[];
//   public AppUsersVisitByAgeGroup: any[];
// }

// export class OInvoiceDetails {

//   public StatusBadge?: string | null;
//   public PaymentDateS?: string | null;

//   public ReferenceId: number | null;
//   public ReferenceKey: string | null;

//   public TypeName: string | null;
//   public TypeCode: string | null;

//   public ParentKey: string | null;
//   public ParentName: string | null;

//   public UserAccountId: number | null;
//   public UserAccountKey: string | null;
//   public UserAccountDisplayName: string | null;
//   public UserAccountIconUrl: string | null;
//   public UserAccountTypeCode: string | null;
//   public UserAccountTypeName: string | null;

//   public PaymentDate: Date | null;
//   public PaymentReference: string | null;
//   public PaymentModeCode: string | null;
//   public PaymentModeName: string | null;
//   public PaymentApproverKey: string | null;
//   public PaymentApproverDisplayName: string | null;
//   public PaymentProofUrl: string | null;


//   public InoviceNumber: string | null;
//   public Name: string | null;
//   public Description: string | null;
//   public FromName: string | null;
//   public FromAddress: string | null;
//   public FromContactNumber: string | null;
//   public FromEmailAddress: string | null;
//   public FromFax: string | null;
//   public ToName: string | null;
//   public ToAddress: string | null;
//   public ToContactNumber: string | null;
//   public ToEmailAddress: string | null;
//   public ToFax: string | null;

//   public TotalItem: number | null;
//   public UnitCost: number | null;

//   public Amount: number | null;
//   public ChargePercentage: number | null;
//   public Charge: number | null;

//   public DiscountPercentage: number | null;
//   public DiscountAmount: number | null;
//   public ComissionPercentage: number | null;
//   public ComissionAmount: number | null;
//   public TotalAmount: number | null;

//   public InvoiceDate: Date | null;
//   public InvoiceDateS: string | null;
//   public StartDate: string | null;
//   public EndDate: string | null;
//   public CreateDate: string | null;
//   public CreatedByKey: string | null;
//   public CreatedByDisplayName: string | null;
//   public ModifyDate: string | null;
//   public ModifyDateS: string | null;
//   public ModifyByKey: string | null;
//   public ModifyByDisplayName: string | null;
//   public Status: string | null;
//   public StatusCode: string | null;
//   public StatusName: string | null;
//   public StatusI: string | null;
//   public Comment: string | null;
//   public Items: OInvoiceItemDetails[] | null;
// }

// export class OInvoiceItemDetails {
//   public ReferenceId: number | null;
//   public ReferenceKey: string | null;

//   public TypeName: string | null;
//   public TypeCode: string | null;


//   public Name: string | null;
//   public Description: string | null;

//   public TotalItem: number | null;
//   public UnitCost: number | null;

//   public Amount: number | null;
//   public ChargePercentage: number | null;
//   public Charge: number | null;

//   public DiscountPercentage: number | null;
//   public DiscountAmount: number | null;
//   public ComissionPercentage: number | null;
//   public ComissionAmount: number | null;
//   public TotalAmount: number | null;

//   public InvoiceDate: Date | null;
//   public InvoiceDateS: string | null;
//   public StartDate: string | null;
//   public EndDate: string | null;
//   public CreateDate: string | null;
//   public CreatedByKey: string | null;
//   public CreatedByDisplayName: string | null;
//   public ModifyDate: string | null;
//   public ModifyDateS: string | null;
//   public ModifyByKey: string | null;
//   public ModifyByDisplayName: string | null;
//   public Status: string | null;
//   public StatusCode: string | null;
//   public StatusName: string | null;
//   public StatusI: string | null;
//   public Comment: string | null;
// }

// export class OTerminalStatusCount {
//   public Total: number | null;
//   public Unused: number | null;
//   public Active: number | null;
//   public Idle: number | null;
//   public Dead: number | null;

// }






